import React, { useEffect, useRef, useState } from 'react'
import {
  Row,
  Col,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import '../form.css'
import {
  createLogin,
  deleteLogin,
  updateLogin,
} from '../../actions/webstoreAction'
import { rights } from '../../utils/apiGateway'
import { logout } from '../../actions/userActions'
import { getReportById } from '../../actions/formAction'
import { REPORT_GET_RESET } from '../../constants/formConstants'
import CsvDownload from 'react-json-to-csv'
import PPPTable from '../../components/PPPTable'
import passwordComplexity from 'joi-password-complexity'
import {
  PPPTABLE_EDITMODE_RESET,
  PPPTABLE_EDITMODE_SET,
} from '../../constants/webstoreConstants'
import ErrorButton from '../../components/ErrorButton'
import { getDepartmentList } from '../../actions/accountAction'
import { DEPARTMENT_LIST_RESET } from '../../constants/accountConstants'
import { v4 as uuidv4 } from 'uuid'

const UserScreen = ({ history }) => {
  const dispatch = useDispatch()
  const LoginPassword = useRef(null)
  const LoginPassword2 = useRef(null)
  const LoginName = useRef(null)
  const FullName = useRef(null)
  const EditFullName = useRef(null)
  const Email = useRef(null)
  const EditEmail = useRef(null)
  const AdminRight = useRef(null)
  const EditAdminRight = useRef(null)
  const IPCheck = useRef(null)
  const EditIPCheck = useRef(null)
  const DepartmentID = useRef(null)
  const [errCount, setErrCount] = useState(1)
  const [result, setResult] = useState()
  const [id, setId] = useState()
  const [data, setData] = useState()
  const [show, setShow] = useState()
  const [dept, setDept] = useState()
  const [complexityOptions, setComplexityOptions] = useState({
    min: 8,
    max: 30,
    lowerCase: 1,
    upperCase: 1,
    numeric: 1,
    symbol: 1,
    requirementCount: 2,
  })
  const [errorLocal, setErrorLocal] = useState()
  const reportById = useSelector((state) => state.reportById)
  const { loading, error, report, success: successReport } = reportById
  const departmentList = useSelector((state) => state.departmentList)
  const {
    loading: loadingDept,
    error: errorDept,
    departments,
    passwordPolicy,
    success: successDept,
  } = departmentList
  const loginUpdate = useSelector((state) => state.loginUpdate)
  const { success, loading: loadingUpdate, error: errorUpdate } = loginUpdate
  const loginCreate = useSelector((state) => state.loginCreate)
  const {
    success: successCreate,
    loading: loadingCreate,
    newLogin,
  } = loginCreate
  const loginDelete = useSelector((state) => state.loginDelete)
  const {
    success: successDelete,
    loading: loadingDelete,
    _id: _idDeleted,
  } = loginDelete
  const pppTableState = useSelector((state) => state.pppTableState)
  if (!rights('60C30F27-C34C-44BA-BEB8-FC3F4E3C34B4')) {
    dispatch(logout())
  }
  useEffect(() => {
    if (id && successCreate) {
      setResult({
        ...result,
        rows: result.rows.filter((r) => r._id !== id),
      })
      setId()
      //dispatch({ type: ORDER_ROUTING_DELETE_RESET })
    }
    if (!dept && !loadingDept && !successDept) {
      dispatch(getDepartmentList())
    }
    if (successDept) {
      setDept(departments)
      if (passwordPolicy) {
        setComplexityOptions({
          min: passwordPolicy.MinChar,
          max: passwordPolicy.MaxChar,
          lowerCase: passwordPolicy.LowerCase,
          upperCase: passwordPolicy.UpperCase,
          numeric: passwordPolicy.Number,
          symbol: passwordPolicy.Symbol,
          requirementCount:
            (complexityOptions.lowerCase ? 1 : 0) +
            (complexityOptions.upperCase ? 1 : 0) +
            (complexityOptions.numeric ? 1 : 0) +
            (complexityOptions.symbol ? 1 : 0),
          rejectPrevious: passwordPolicy.rejectPrevious,
        })
      }
      dispatch({ type: DEPARTMENT_LIST_RESET })
    }
    if (!result && !successReport && !loading) {
      dispatch(getReportById('AEF7DF5D-2D55-4C22-AC2B-4A6165958057', ''))
      // if (!loadingRow) {
      //   dispatch(getOrderRoutingRowByAccount(sAccount))
      // }
      //dispatch(getParentCategories())
    }
    if (success) {
      setData()
    }
    if (successReport) {
      setResult(report)
      dispatch({ type: REPORT_GET_RESET })
    }
    if (error || errorDept) {
      setErrCount(errCount + 1)
      if (errCount > 30) {
        history.push('/error/1009')
      }
    }
  }, [
    loading,
    dispatch,
    history,
    error,
    errCount,
    success,
    loadingUpdate,
    successCreate,
    successDelete,
    loadingCreate,
    loadingDelete,
    result,
    _idDeleted,
    newLogin,
    id,
    report,
    successReport,
    departments,
    dept,
    errorDept,
    loadingDept,
    successDept,
    complexityOptions,
    passwordPolicy,
  ])

  const onDelete = (r) => {
    setId(r._id)
    dispatch(deleteLogin(r._id))
  }

  const passwordClick = (r) => {
    setData(r)
    setShow(true)
  }
  const handleClose = () => setShow(false)
  const resetPassword = (e, LoginName, _id) => {
    if (LoginPassword.current.value === LoginPassword2.current.value) {
      const login = {
        _id,
        LoginName,
        LoginPassword: LoginPassword.current.value,
        ...data,
      }
      const valid = passwordComplexity(complexityOptions).validate(
        LoginPassword.current.value
      )
      if (valid.error) {
        let msg = ''
        if (valid.error.details) {
          valid.error.details.map(
            (m) =>
              (msg +=
                msg === ''
                  ? m.message.replace('"value"', LoginPassword.current.value)
                  : '. ' +
                    m.message.replace('"value"', LoginPassword.current.value))
          )
        }

        setErrorLocal(msg)
      } else {
        // set password

        dispatch(updateLogin(login))
      }
    } else {
      setErrorLocal('Password does not match')
    }
  }

  const AddNewRow = (row) => {
    const addLogin = () => {
      // add new
      const data = {
        LoginName: LoginName.current.value,
        Fullname: FullName.current.value,
        email: Email.current.value,
        AdminRight: AdminRight.current.checked,
        IPCheck: IPCheck.current.checked,
        DepartmentID: DepartmentID.current.value,
        DepartmentDesc:
          DepartmentID.current.options[DepartmentID.current.selectedIndex].text,
        _id: uuidv4(),
      }
      dispatch(createLogin(data))
    }
    return (
      <tr className='newrow'>
        <td>
          <input
            type='text'
            id='LoginName'
            ref={LoginName}
            maxLength={50}
            className='form-control form-control-sm'
          />
        </td>
        <td>
          <input
            type='text'
            id='FullName'
            ref={FullName}
            maxLength={50}
            className='form-control form-control-sm'
          />
        </td>
        <td>
          <input
            type='text'
            id='Email'
            ref={Email}
            maxLength={100}
            className='form-control form-control-sm'
          />
        </td>
        <td>
          <select
            className='form-control form-control-sm'
            defaultValue={1}
            ref={DepartmentID}
          >
            {dept &&
              Array.isArray(dept) &&
              dept.map((d) => (
                <option key={d.DepartmentID} value={d.DepartmentID}>
                  {d.DepartmentDesc}
                </option>
              ))}
          </select>
        </td>
        <td>
          <input
            type='checkbox'
            className='form-control form-control-sm'
            id='AdminRight'
            ref={AdminRight}
          ></input>
        </td>
        <td>
          <input
            type='checkbox'
            className='form-control form-control-sm'
            id='IPCheck'
            ref={IPCheck}
          ></input>
        </td>
        <td>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-save`}>
                <strong>Save</strong>
              </Tooltip>
            }
          >
            <Button variant='success' size='sm' onClick={addLogin}>
              <i className='fa fa-save'></i>
            </Button>
          </OverlayTrigger>
        </td>
      </tr>
    )
  }
  const EditRow = ({ row }) => {
    const saveLogin = (r) => {
      if (EditEmail.current.value === '') {
        dispatch({ type: PPPTABLE_EDITMODE_RESET })
      } else {
        const login = {
          LoginName: r.LoginName,
          Fullname: EditFullName.current.value,
          email: EditEmail.current.value,
          AdminRight: EditAdminRight.current.checked,
          IPCheck: EditIPCheck.current.checked,
          DepartmentID: DepartmentID.current.value,
          DepartmentDesc:
            DepartmentID.current.options[DepartmentID.current.selectedIndex]
              .text,
          _id: row._id,
        }
        if (
          login.FullName === r.FullName &&
          login.Email === r.Email &&
          login.IPCheck === r.IPCheck &&
          login.AdminRight === r.AdminRight &&
          login.DepartmentID === r.DepartmentID
        ) {
        } else {
          dispatch(updateLogin(login))
        }
        dispatch({ type: PPPTABLE_EDITMODE_SET })
      }
    }
    return (
      <tr className='newrow'>
        <td>{row.LoginName}</td>
        <td>
          <input
            type='text'
            id='FullName'
            ref={EditFullName}
            maxLength={50}
            className='form-control form-control-sm'
            defaultValue={row.FullName}
          />
        </td>
        <td>
          <input
            type='text'
            id='Email'
            ref={EditEmail}
            maxLength={100}
            className='form-control form-control-sm'
            defaultValue={row.Email}
          />
        </td>
        <td>
          <select
            className='form-control form-control-sm'
            defaultValue={row.DepartmentID}
            ref={DepartmentID}
          >
            {dept &&
              dept.map((d) => (
                <option key={d.DepartmentID} value={d.DepartmentID}>
                  {d.DepartmentDesc}
                </option>
              ))}
          </select>
        </td>
        <td>
          <input
            type='checkbox'
            className='form-control form-control-sm'
            id='AdminRight'
            ref={EditAdminRight}
            defaultChecked={row.AdminRight}
          ></input>
        </td>
        <td>
          <input
            type='checkbox'
            className='form-control form-control-sm'
            id='IPCheck'
            ref={EditIPCheck}
            defaultChecked={row.IPCheck}
          ></input>
        </td>
        <td>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-save`}>
                <strong>Save</strong>
              </Tooltip>
            }
          >
            <Button variant='success' size='sm' onClick={() => saveLogin(row)}>
              <i className='fa fa-save'></i>
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip id={`tooltip-undo`}>
                <strong>Undo</strong>
              </Tooltip>
            }
          >
            <Button
              variant='warning'
              size='sm'
              onClick={() => dispatch({ type: PPPTABLE_EDITMODE_RESET })}
            >
              <i className='fas fa-undo'></i>
            </Button>
          </OverlayTrigger>
          <ErrorButton
            loading={loadingUpdate}
            error={errorUpdate}
            type='LOGIN_UPDATE_RESET'
          ></ErrorButton>
        </td>
      </tr>
    )
  }

  return (
    <>
      <h2>User Maintenance</h2>
      {error && (
        <Message variant='danger' dismissible={true}>
          {error}
        </Message>
      )}
      {loading ? (
        <Loader />
      ) : (
        result && (
          <>
            <hr size='1' />
            <Row>
              <Col sm={2} md={4} lg={6} style={{ textAlign: 'left' }}>
                {result && <CsvDownload data={result.rows} />}
              </Col>
            </Row>
            <div className='App'>
              <PPPTable
                report={result}
                history={history}
                sumByGroup={false}
                onDelete={onDelete}
                onDeleteState={loginDelete}
                onDeleteReset='LOGIN_DELETE_RESET'
                onUpdateReset='LOGIN_UPDATE_RESET'
                onUpdateState={loginUpdate}
                onSave={(e) => console.log(e.target)}
                EditRow={EditRow}
                onEditModeState={pppTableState}
                AddNewRow={AddNewRow}
                onCreateState={loginCreate}
                onCreateReset='LOGIN_CREATE_RESET'
                onPassword={passwordClick}
              ></PPPTable>
            </div>
          </>
        )
      )}
      {data && (
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Reset Password for {data.LoginName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <table>
                <tbody>
                  <tr>
                    <th>New Password:</th>
                    <td style={{ width: '250px' }}>
                      <input
                        type='password'
                        ref={LoginPassword}
                        className='form-control form-control-sm'
                        id='LoginPassword'
                        maxLength={20}
                      ></input>
                    </td>
                  </tr>
                  <tr>
                    <th>Password Again:</th>
                    <td>
                      <input
                        type='password'
                        ref={LoginPassword2}
                        className='form-control form-control-sm'
                        id='LoginPassword2'
                        maxLength={20}
                      ></input>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </Modal.Body>
          <Modal.Footer>
            {loadingUpdate && <Loader size='30px'></Loader>}
            {errorLocal && (
              <Message
                variant='danger'
                onclose={(e) => {
                  setErrorLocal('')
                }}
              >
                {errorLocal}
              </Message>
            )}
            <Button variant='secondary' onClick={handleClose}>
              Close
            </Button>
            <Button
              variant='primary'
              onClick={(e) => resetPassword(e, data.LoginName, data._id)}
            >
              Save Password
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}

export default UserScreen
