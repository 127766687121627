export const GET_PICK_TICKET_REQUEST = 'GET_PICK_TICKET_REQUEST'
export const GET_PICK_TICKET_SUCCESS = 'GET_PICK_TICKET_SUCCESS'
export const GET_PICK_TICKET_FAIL = 'GET_PICK_TICKET_FAIL'
export const GET_PICK_TICKET_RESET = 'GET_PICK_TICKET_RESET'

export const GET_ORDER_BYID_REQUEST = 'GET_ORDER_BYID_REQUEST'
export const GET_ORDER_BYID_SUCCESS = 'GET_ORDER_BYID_SUCCESS'
export const GET_ORDER_BYID_FAIL = 'GET_ORDER_BYID_FAIL'
export const GET_ORDER_BYID_RESET = 'GET_ORDER_BYID_RESET'
// order routing
export const ORDER_ROUTING_LIST_REQUEST = 'ORDER_ROUTING_LIST_REQUEST'
export const ORDER_ROUTING_LIST_SUCCESS = 'ORDER_ROUTING_LIST_SUCCESS'
export const ORDER_ROUTING_LIST_FAIL = 'ORDER_ROUTING_LIST_FAIL'
export const ORDER_ROUTING_LIST_RESET = 'ORDER_ROUTING_LIST_RESET'
// order routing
export const ORDER_ROUTING_DELETE_REQUEST = 'ORDER_ROUTING_DELETE_REQUEST'
export const ORDER_ROUTING_DELETE_SUCCESS = 'ORDER_ROUTING_DELETE_SUCCESS'
export const ORDER_ROUTING_DELETE_FAIL = 'ORDER_ROUTING_DELETE_FAIL'
export const ORDER_ROUTING_DELETE_RESET = 'ORDER_ROUTING_DELETE_RESET'
// order routing row for add new row
export const ORDER_ROUTING_ROW_REQUEST = 'ORDER_ROUTING_ROW_REQUEST'
export const ORDER_ROUTING_ROW_SUCCESS = 'ORDER_ROUTING_ROW_SUCCESS'
export const ORDER_ROUTING_ROW_FAIL = 'ORDER_ROUTING_ROW_FAIL'
export const ORDER_ROUTING_ROW_RESET = 'ORDER_ROUTING_ROW_RESET'
// create order routing
export const ORDER_ROUTING_CREATE_REQUEST = 'ORDER_ROUTING_CREATE_REQUEST'
export const ORDER_ROUTING_CREATE_SUCCESS = 'ORDER_ROUTING_CREATE_SUCCESS'
export const ORDER_ROUTING_CREATE_FAIL = 'ORDER_ROUTING_CREATE_FAIL'
export const ORDER_ROUTING_CREATE_RESET = 'ORDER_ROUTING_CREATE_RESET'
//contract by Account
export const GET_CONTRACT_BYACC_REQUEST = 'GET_CONTRACT_BYACC_REQUEST'
export const GET_CONTRACT_BYACC_SUCCESS = 'GET_CONTRACT_BYACC_SUCCESS'
export const GET_CONTRACT_BYACC_FAIL = 'GET_CONTRACT_BYACC_FAIL'
export const GET_CONTRACT_BYACC_RESET = 'GET_CONTRACT_BYACC_RESET'
//contract by ID
export const GET_CONTRACT_BYID_REQUEST = 'GET_CONTRACT_BYID_REQUEST'
export const GET_CONTRACT_BYID_SUCCESS = 'GET_CONTRACT_BYID_SUCCESS'
export const GET_CONTRACT_BYID_FAIL = 'GET_CONTRACT_BYID_FAIL'
export const GET_CONTRACT_BYID_RESET = 'GET_CONTRACT_BYID_RESET'
//update Contract
export const UPDATE_CONTRACT_REQUEST = 'UPDATE_CONTRACT_REQUEST'
export const UPDATE_CONTRACT_SUCCESS = 'UPDATE_CONTRACT_SUCCESS'
export const UPDATE_CONTRACT_FAIL = 'UPDATE_CONTRACT_FAIL'
export const UPDATE_CONTRACT_RESET = 'UPDATE_CONTRACT_RESET'
//delete Contract
export const DEL_CONTRACT_REQUEST = 'DEL_CONTRACT_REQUEST'
export const DEL_CONTRACT_SUCCESS = 'DEL_CONTRACT_SUCCESS'
export const DEL_CONTRACT_FAIL = 'DEL_CONTRACT_FAIL'
export const DEL_CONTRACT_RESET = 'DEL_CONTRACT_RESET'
//create Contract
export const CREATE_CONTRACT_REQUEST = 'CREATE_CONTRACT_REQUEST'
export const CREATE_CONTRACT_SUCCESS = 'CREATE_CONTRACT_SUCCESS'
export const CREATE_CONTRACT_FAIL = 'CREATE_CONTRACT_FAIL'
export const CREATE_CONTRACT_RESET = 'CREATE_CONTRACT_RESET'
//create Contract OpCode
export const CREATE_CON_OPCODE_REQUEST = 'CREATE_CON_OPCODE_REQUEST'
export const CREATE_CON_OPCODE_SUCCESS = 'CREATE_CON_OPCODE_SUCCESS'
export const CREATE_CON_OPCODE_FAIL = 'CREATE_CON_OPCODE_FAIL'
export const CREATE_CON_OPCODE_RESET = 'CREATE_CON_OPCODE_RESET'
//delete Contract OpCode
export const DEL_CON_OPCODE_REQUEST = 'DEL_CON_OPCODE_REQUEST'
export const DEL_CON_OPCODE_SUCCESS = 'DEL_CON_OPCODE_SUCCESS'
export const DEL_CON_OPCODE_FAIL = 'DEL_CON_OPCODE_FAIL'
export const DEL_CON_OPCODE_RESET = 'DEL_CON_OPCODE_RESET'
// custom letter row for add new row
export const CUSTOM_LETTER_ROW_REQUEST = 'CUSTOM_LETTER_ROW_REQUEST'
export const CUSTOM_LETTER_ROW_SUCCESS = 'CUSTOM_LETTER_ROW_SUCCESS'
export const CUSTOM_LETTER_ROW_FAIL = 'CUSTOM_LETTER_ROW_FAIL'
export const CUSTOM_LETTER_ROW_RESET = 'CUSTOM_LETTER_ROW_RESET'
// create custom letter
export const CUSTOM_LETTER_CREATE_REQUEST = 'CUSTOM_LETTER_CREATE_REQUEST'
export const CUSTOM_LETTER_CREATE_SUCCESS = 'CUSTOM_LETTER_CREATE_SUCCESS'
export const CUSTOM_LETTER_CREATE_FAIL = 'CUSTOM_LETTER_CREATE_FAIL'
export const CUSTOM_LETTER_CREATE_RESET = 'CUSTOM_LETTER_CREATE_RESET'
export const CUSTOM_LETTER_CREATE_ERROR = 'CUSTOM_LETTER_CREATE_ERROR'
// delete custom letter
export const CUSTOM_LETTER_DELETE_REQUEST = 'CUSTOM_LETTER_DELETE_REQUEST'
export const CUSTOM_LETTER_DELETE_SUCCESS = 'CUSTOM_LETTER_DELETE_SUCCESS'
export const CUSTOM_LETTER_DELETE_FAIL = 'CUSTOM_LETTER_DELETE_FAIL'
export const CUSTOM_LETTER_DELETE_RESET = 'CUSTOM_LETTER_DELETE_RESET'
// get custom letter
export const CUSTOM_LETTER_GET_REQUEST = 'CUSTOM_LETTER_GET_REQUEST'
export const CUSTOM_LETTER_GET_SUCCESS = 'CUSTOM_LETTER_GET_SUCCESS'
export const CUSTOM_LETTER_GET_FAIL = 'CUSTOM_LETTER_GET_FAIL'
export const CUSTOM_LETTER_GET_RESET = 'CUSTOM_LETTER_GET_RESET'
// get import filter
export const IMPORT_FILTER_GET_REQUEST = 'IMPORT_FILTER_GET_REQUEST'
export const IMPORT_FILTER_GET_SUCCESS = 'IMPORT_FILTER_GET_SUCCESS'
export const IMPORT_FILTER_GET_FAIL = 'IMPORT_FILTER_GET_FAIL'
export const IMPORT_FILTER_GET_RESET = 'IMPORT_FILTER_GET_RESET'
//update import filter
export const UPDATE_IMP_FILTER_REQUEST = 'UPDATE_IMP_FILTER_REQUEST'
export const UPDATE_IMP_FILTER_SUCCESS = 'UPDATE_IMP_FILTER_SUCCESS'
export const UPDATE_IMP_FILTER_FAIL = 'UPDATE_IMP_FILTER_FAIL'
export const UPDATE_IMP_FILTER_RESET = 'UPDATE_IMP_FILTER_RESET'
//create import filter
export const CREATE_IMP_FILTER_REQUEST = 'CREATE_IMP_FILTER_REQUEST'
export const CREATE_IMP_FILTER_SUCCESS = 'CREATE_IMP_FILTER_SUCCESS'
export const CREATE_IMP_FILTER_FAIL = 'CREATE_IMP_FILTER_FAIL'
export const CREATE_IMP_FILTER_RESET = 'CREATE_IMP_FILTER_RESET'
//delete import filter
export const DEL_IMP_FILTER_REQUEST = 'DEL_IMP_FILTER_REQUEST'
export const DEL_IMP_FILTER_SUCCESS = 'DEL_IMP_FILTER_SUCCESS'
export const DEL_IMP_FILTER_FAIL = 'DEL_IMP_FILTER_FAIL'
export const DEL_IMP_FILTER_RESET = 'DEL_IMP_FILTER_RESET'
//orderform by Account
export const GET_ORDFORM_BYACC_REQUEST = 'GET_ORDFORM_BYACC_REQUEST'
export const GET_ORDFORM_BYACC_SUCCESS = 'GET_ORDFORM_BYACC_SUCCESS'
export const GET_ORDFORM_BYACC_FAIL = 'GET_ORDFORM_BYACC_FAIL'
export const GET_ORDFORM_BYACC_RESET = 'GET_ORDFORM_BYACC_RESET'
//orderform by ID
export const GET_ORDFORM_BYID_REQUEST = 'GET_ORDFORM_BYID_REQUEST'
export const GET_ORDFORM_BYID_SUCCESS = 'GET_ORDFORM_BYID_SUCCESS'
export const GET_ORDFORM_BYID_FAIL = 'GET_ORDFORM_BYID_FAIL'
export const GET_ORDFORM_BYID_RESET = 'GET_ORDFORM_BYID_RESET'
//update order form
export const UPDATE_ORDERFORM_REQUEST = 'UPDATE_ORDERFORM_REQUEST'
export const UPDATE_ORDERFORM_SUCCESS = 'UPDATE_ORDERFORM_SUCCESS'
export const UPDATE_ORDERFORM_FAIL = 'UPDATE_ORDERFORM_FAIL'
export const UPDATE_ORDERFORM_RESET = 'UPDATE_ORDERFORM_RESET'
//create order from
export const CREATE_ORDERFORM_REQUEST = 'CREATE_ORDERFORM_REQUEST'
export const CREATE_ORDERFORM_SUCCESS = 'CREATE_ORDERFORM_SUCCESS'
export const CREATE_ORDERFORM_FAIL = 'CREATE_ORDERFORM_FAIL'
export const CREATE_ORDERFORM_RESET = 'CREATE_ORDERFORM_RESET'
//delete order from
export const DEL_ORDERFORM_REQUEST = 'DEL_ORDERFORM_REQUEST'
export const DEL_ORDERFORM_SUCCESS = 'DEL_ORDERFORM_SUCCESS'
export const DEL_ORDERFORM_FAIL = 'DEL_ORDERFORM_FAIL'
export const DEL_ORDERFORM_RESET = 'DEL_ORDERFORM_RESET'
//update order form field
export const UPDATE_ORDFORM_FIELD_REQUEST = 'UPDATE_ORDFORM_FIELD_REQUEST'
export const UPDATE_ORDFORM_FIELD_SUCCESS = 'UPDATE_ORDFORM_FIELD_SUCCESS'
export const UPDATE_ORDFORM_FIELD_FAIL = 'UPDATE_ORDFORM_FIELD_FAIL'
export const UPDATE_ORDFORM_FIELD_RESET = 'UPDATE_ORDFORM_FIELD_RESET'
//create order form field
export const CREATE_ORDFORM_FIELD_REQUEST = 'CREATE_ORDFORM_FIELD_REQUEST'
export const CREATE_ORDFORM_FIELD_SUCCESS = 'CREATE_ORDFORM_FIELD_SUCCESS'
export const CREATE_ORDFORM_FIELD_FAIL = 'CREATE_ORDFORM_FIELD_FAIL'
export const CREATE_ORDFORM_FIELD_RESET = 'CREATE_ORDFORM_FIELD_RESET'
//delete order form field
export const DEL_ORDFORM_FIELD_REQUEST = 'DEL_ORDFORM_FIELD_REQUEST'
export const DEL_ORDFORM_FIELD_SUCCESS = 'DEL_ORDFORM_FIELD_SUCCESS'
export const DEL_ORDFORM_FIELD_FAIL = 'DEL_ORDFORM_FIELD_FAIL'
export const DEL_ORDFORM_FIELD_RESET = 'DEL_ORDFORM_FIELD_RESET'
//create order form product
export const CREATE_ORDFORM_PROD_REQUEST = 'CREATE_ORDFORM_PROD_REQUEST'
export const CREATE_ORDFORM_PROD_SUCCESS = 'CREATE_ORDFORM_PROD_SUCCESS'
export const CREATE_ORDFORM_PROD_FAIL = 'CREATE_ORDFORM_PROD_FAIL'
export const CREATE_ORDFORM_PROD_RESET = 'CREATE_ORDFORM_PROD_RESET'
//delete order form product
export const DEL_ORDFORM_PROD_REQUEST = 'DEL_ORDFORM_PROD_REQUEST'
export const DEL_ORDFORM_PROD_SUCCESS = 'DEL_ORDFORM_PROD_SUCCESS'
export const DEL_ORDFORM_PROD_FAIL = 'DEL_ORDFORM_PROD_FAIL'
export const DEL_ORDFORM_PROD_RESET = 'DEL_ORDFORM_PROD_RESET'
//create order form login
export const CREATE_ORDFORM_LOGIN_REQUEST = 'CREATE_ORDFORM_LOGIN_REQUEST'
export const CREATE_ORDFORM_LOGIN_SUCCESS = 'CREATE_ORDFORM_LOGIN_SUCCESS'
export const CREATE_ORDFORM_LOGIN_FAIL = 'CREATE_ORDFORM_LOGIN_FAIL'
export const CREATE_ORDFORM_LOGIN_RESET = 'CREATE_ORDFORM_LOGIN_RESET'
//delete order form login
export const DEL_ORDFORM_LOGIN_REQUEST = 'DEL_ORDFORM_LOGIN_REQUEST'
export const DEL_ORDFORM_LOGIN_SUCCESS = 'DEL_ORDFORM_LOGIN_SUCCESS'
export const DEL_ORDFORM_LOGIN_FAIL = 'DEL_ORDFORM_LOGIN_FAIL'
export const DEL_ORDFORM_LOGIN_RESET = 'DEL_ORDFORM_LOGIN_RESET'
// set order back ordered to shipped
export const BCKORD_TO_SHIPPED_SUCCESS = 'BCKORD_TO_SHIPPED_SUCCESS'
export const BCKORD_TO_SHIPPED_RESET = 'BCKORD_TO_SHIPPED_RESET'
export const BCKORD_TO_SHIPPED_FAIL = 'BCKORD_TO_SHIPPED_FAIL'
export const BCKORD_TO_SHIPPED_REQUEST = 'BCKORD_TO_SHIPPED_REQUEST'
// change order contract
export const ORDER_CONTRACT_UPDATE_SUCCESS = 'ORDER_CONTRACT_UPDATE_SUCCESS'
export const ORDER_CONTRACT_UPDATE_RESET = 'ORDER_CONTRACT_UPDATE_RESET'
export const ORDER_CONTRACT_UPDATE_FAIL = 'ORDER_CONTRACT_UPDATE_FAIL'
export const ORDER_CONTRACT_UPDATE_REQUEST = 'ORDER_CONTRACT_UPDATE_REQUEST'
// get carrier by via CARRIER_VIA_GET
export const CARRIER_VIA_GET_SUCCESS = 'CARRIER_VIA_GET_SUCCESS'
export const CARRIER_VIA_GET_RESET = 'CARRIER_VIA_GET_RESET'
export const CARRIER_VIA_GET_FAIL = 'CARRIER_VIA_GET_FAIL'
export const CARRIER_VIA_GET_REQUEST = 'CARRIER_VIA_GET_REQUEST'
// update carrier in order batch
export const BATCH_CARRIER_UPDATE_SUCCESS = 'BATCH_CARRIER_UPDATE_SUCCESS'
export const BATCH_CARRIER_UPDATE_RESET = 'BATCH_CARRIER_UPDATE_RESET'
export const BATCH_CARRIER_UPDATE_FAIL = 'BATCH_CARRIER_UPDATE_FAIL'
export const BATCH_CARRIER_UPDATE_REQUEST = 'BATCH_CARRIER_UPDATE_REQUEST'
// update pick verify in order batch
export const BATCH_VERIFY_UPDATE_SUCCESS = 'BATCH_VERIFY_UPDATE_SUCCESS'
export const BATCH_VERIFY_UPDATE_RESET = 'BATCH_VERIFY_UPDATE_RESET'
export const BATCH_VERIFY_UPDATE_FAIL = 'BATCH_VERIFY_UPDATE_FAIL'
export const BATCH_VERIFY_UPDATE_REQUEST = 'BATCH_VERIFY_UPDATE_REQUEST'
// set order back ordered to new
export const BCKORD_TO_NEW_SUCCESS = 'BCKORD_TO_NEW_SUCCESS'
export const BCKORD_TO_NEW_RESET = 'BCKORD_TO_NEW_RESET'
export const BCKORD_TO_NEW_FAIL = 'BCKORD_TO_NEW_FAIL'
export const BCKORD_TO_NEW_REQUEST = 'BCKORD_TO_NEW_REQUEST'
//get address by customer
export const GET_ADDR_BYCUS_REQUEST = 'GET_ADDR_BYCUS_REQUEST'
export const GET_ADDR_BYCUS_SUCCESS = 'GET_ADDR_BYCUS_SUCCESS'
export const GET_ADDR_BYCUS_FAIL = 'GET_ADDR_BYCUS_FAIL'
export const GET_ADDR_BYCUS_RESET = 'GET_ADDR_BYCUS_RESET'
//update address
export const UPDATE_ADDR_REQUEST = 'UPDATE_ADDR_REQUEST'
export const UPDATE_ADDR_SUCCESS = 'UPDATE_ADDR_SUCCESS'
export const UPDATE_ADDR_FAIL = 'UPDATE_ADDR_FAIL'
export const UPDATE_ADDR_RESET = 'UPDATE_ADDR_RESET'
//create address
export const CREATE_ADDR_REQUEST = 'CREATE_ADDR_REQUEST'
export const CREATE_ADDR_SUCCESS = 'CREATE_ADDR_SUCCESS'
export const CREATE_ADDR_FAIL = 'CREATE_ADDR_FAIL'
export const CREATE_ADDR_RESET = 'CREATE_ADDR_RESET'
//delete customer
export const DEL_ADDR_REQUEST = 'DEL_ADDR_REQUEST'
export const DEL_ADDR_SUCCESS = 'DEL_ADDR_SUCCESS'
export const DEL_ADDR_FAIL = 'DEL_ADDR_FAIL'
export const DEL_ADDR_RESET = 'DEL_ADDR_RESET'
//get customer by account
export const GET_CUST_BYACC_REQUEST = 'GET_CUST_BYACC_REQUEST'
export const GET_CUST_BYACC_SUCCESS = 'GET_CUST_BYACC_SUCCESS'
export const GET_CUST_BYACC_FAIL = 'GET_CUST_BYACC_FAIL'
export const GET_CUST_BYACC_RESET = 'GET_CUST_BYACC_RESET'
//get customer by id
export const GET_CUST_BYID_REQUEST = 'GET_CUST_BYID_REQUEST'
export const GET_CUST_BYID_SUCCESS = 'GET_CUST_BYID_SUCCESS'
export const GET_CUST_BYID_FAIL = 'GET_CUST_BYID_FAIL'
export const GET_CUST_BYID_RESET = 'GET_CUST_BYID_RESET'
//create customer
export const CREATE_CUST_REQUEST = 'CREATE_CUST_REQUEST'
export const CREATE_CUST_SUCCESS = 'CREATE_CUST_SUCCESS'
export const CREATE_CUST_FAIL = 'CREATE_CUST_FAIL'
export const CREATE_CUST_RESET = 'CREATE_CUST_RESET'
//update customer
export const UPDATE_CUST_REQUEST = 'UPDATE_CUST_REQUEST'
export const UPDATE_CUST_SUCCESS = 'UPDATE_CUST_SUCCESS'
export const UPDATE_CUST_FAIL = 'UPDATE_CUST_FAIL'
export const UPDATE_CUST_RESET = 'UPDATE_CUST_RESET'
//delete customer
export const DEL_CUST_REQUEST = 'DEL_CUST_REQUEST'
export const DEL_CUST_SUCCESS = 'DEL_CUST_SUCCESS'
export const DEL_CUST_FAIL = 'DEL_CUST_FAIL'
export const DEL_CUST_RESET = 'DEL_CUST_RESET'
//get widget
export const GET_WIDGET_REQUEST = 'GET_WIDGET_REQUEST'
export const GET_WIDGET_SUCCESS = 'GET_WIDGET_SUCCESS'
export const GET_WIDGET_FAIL = 'GET_WIDGET_FAIL'
export const GET_WIDGET_RESET = 'GET_WIDGET_RESET'
// get roadscholar badge
export const BADGE_GET_REQUEST = 'BADGE_GET_REQUEST'
export const BADGE_GET_SUCCESS = 'BADGE_GET_SUCCESS'
export const BADGE_GET_RESET = 'BADGE_GET_RESET'
export const BADGE_GET_FAIL = 'BADGE_GET_FAIL'
// delete roadscholar badge
export const BADGE_DELETE_REQUEST = 'BADGE_DELETE_REQUEST'
export const BADGE_DELETE_SUCCESS = 'BADGE_DELETE_SUCCESS'
export const BADGE_DELETE_RESET = 'BADGE_DELETE_RESET'
export const BADGE_DELETE_FAIL = 'BADGE_DELETE_FAIL'
// create roadscholar badge
export const BADGE_CREATE_REQUEST = 'BADGE_CREATE_REQUEST'
export const BADGE_CREATE_SUCCESS = 'BADGE_CREATE_SUCCESS'
export const BADGE_CREATE_RESET = 'BADGE_CREATE_RESET'
export const BADGE_CREATE_FAIL = 'BADGE_CREATE_FAIL'
// create roadscholar badge file
export const BADGE_FILE_CREATE_REQUEST = 'BADGE_FILE_CREATE_REQUEST'
export const BADGE_FILE_CREATE_SUCCESS = 'BADGE_FILE_CREATE_SUCCESS'
export const BADGE_FILE_CREATE_RESET = 'BADGE_FILE_CREATE_RESET'
export const BADGE_FILE_CREATE_FAIL = 'BADGE_FILE_CREATE_FAIL'

export const LOGIN_LIST_REQUEST = 'LOGIN_LIST_REQUEST'
export const LOGIN_LIST_SUCCESS = 'LOGIN_LIST_SUCCESS'
export const LOGIN_LIST_FAIL = 'LOGIN_LIST_FAIL'
export const LOGIN_LIST_RESET = 'LOGIN_LIST_RESET'

export const LOGIN_CREATE_REQUEST = 'LOGIN_CREATE_REQUEST'
export const LOGIN_CREATE_SUCCESS = 'LOGIN_CREATE_SUCCESS'
export const LOGIN_CREATE_FAIL = 'LOGIN_CREATE_FAIL'
export const LOGIN_CREATE_RESET = 'LOGIN_CREATE_RESET'

export const LOGIN_UPDATE_REQUEST = 'LOGIN_UPDATE_REQUEST'
export const LOGIN_UPDATE_SUCCESS = 'LOGIN_UPDATE_SUCCESS'
export const LOGIN_UPDATE_FAIL = 'LOGIN_UPDATE_FAIL'
export const LOGIN_UPDATE_RESET = 'LOGIN_UPDATE_RESET'

export const LOGIN_DELETE_REQUEST = 'LOGIN_DELETE_REQUEST'
export const LOGIN_DELETE_SUCCESS = 'LOGIN_DELETE_SUCCESS'
export const LOGIN_DELETE_FAIL = 'LOGIN_DELETE_FAIL'
export const LOGIN_DELETE_RESET = 'LOGIN_DELETE_RESET'

export const MENU_GET_REQUEST = 'MENU_GET_REQUEST'
export const MENU_GET_SUCCESS = 'MENU_GET_SUCCESS'
export const MENU_GET_FAIL = 'MENU_GET_FAIL'
export const MENU_GET_RESET = 'MENU_GET_RESET'

export const MENU_SET_REQUEST = 'MENU_SET_REQUEST'
export const MENU_SET_SUCCESS = 'MENU_SET_SUCCESS'
export const MENU_SET_FAIL = 'MENU_SET_FAIL'
export const MENU_SET_RESET = 'MENU_SET_RESET'

export const PRODUCTLIST_REPORT_REQUEST = 'PRODUCTLIST_REPORT_REQUEST'
export const PRODUCTLIST_REPORT_SUCCESS = 'PRODUCTLIST_REPORT_SUCCESS'
export const PRODUCTLIST_REPORT_RESET = 'PRODUCTLIST_REPORT_RESET'
export const PRODUCTLIST_REPORT_FAIL = 'PRODUCTLIST_REPORT_FAIL'

export const ECO_GET_REQUEST = 'ECO_GET_REQUEST'
export const ECO_GET_SUCCESS = 'ECO_GET_SUCCESS'
export const ECO_GET_RESET = 'ECO_GET_RESET'
export const ECO_GET_FAIL = 'ECO_GET_FAIL'

export const ECO_CREATE_REQUEST = 'ECO_CREATE_REQUEST'
export const ECO_CREATE_SUCCESS = 'ECO_CREATE_SUCCESS'
export const ECO_CREATE_RESET = 'ECO_CREATE_RESET'
export const ECO_CREATE_FAIL = 'ECO_CREATE_FAIL'

export const CATEGORY_GETLIST_REQUEST = 'CATEGORY_GETLIST_REQUEST'
export const CATEGORY_GETLIST_SUCCESS = 'CATEGORY_GETLIST_SUCCESS'
export const CATEGORY_GETLIST_RESET = 'CATEGORY_GETLIST_RESET'
export const CATEGORY_GETLIST_FAIL = 'CATEGORY_GETLIST_FAIL'

export const CATE_BYID_REQUEST = 'CATE_BYID_REQUEST'
export const CATE_BYID_SUCCESS = 'CATE_BYID_SUCCESS'
export const CATE_BYID_RESET = 'CATE_BYID_RESET'
export const CATE_BYID_FAIL = 'CATE_BYID_FAIL'

export const CATEGORY_CREATE_REQUEST = 'CATEGORY_CREATE_REQUEST'
export const CATEGORY_CREATE_SUCCESS = 'CATEGORY_CREATE_SUCCESS'
export const CATEGORY_CREATE_RESET = 'CATEGORY_CREATE_RESET'
export const CATEGORY_CREATE_FAIL = 'CATEGORY_CREATE_FAIL'
export const CATEGORY_CREATE_ERROR = 'CATEGORY_CREATE_ERROR'

export const CATEGORY_UPDATE_REQUEST = 'CATEGORY_UPDATE_REQUEST'
export const CATEGORY_UPDATE_SUCCESS = 'CATEGORY_UPDATE_SUCCESS'
export const CATEGORY_UPDATE_RESET = 'CATEGORY_UPDATE_RESET'
export const CATEGORY_UPDATE_FAIL = 'CATEGORY_UPDATE_FAIL'

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_RESET = 'CATEGORY_DELETE_RESET'
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL'

export const PPPTABLE_EDITMODE_RESET = 'PPPTABLE_EDITMODE_RESET'
export const PPPTABLE_EDITMODE_SET = 'PPPTABLE_EDITMODE_SET'

export const PARENT_CATEGORY_REQUEST = 'PARENT_CATEGORY_REQUEST'
export const PARENT_CATEGORY_SUCCESS = 'PARENT_CATEGORY_SUCCESS'
export const PARENT_CATEGORY_RESET = 'PARENT_CATEGORY_RESET'
export const PARENT_CATEGORY_FAIL = 'PARENT_CATEGORY_FAIL'

export const PROD_BYID_REQUEST = 'PROD_BYID_REQUEST'
export const PROD_BYID_SUCCESS = 'PROD_BYID_SUCCESS'
export const PROD_BYID_RESET = 'PROD_BYID_RESET'
export const PROD_BYID_FAIL = 'PROD_BYID_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
// product update
export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_ERROR = 'PRODUCT_UPDATE_ERROR'

export const PRODGROUP_BYID_REQUEST = 'PRODGROUP_BYID_REQUEST'
export const PRODGROUP_BYID_SUCCESS = 'PRODGROUP_BYID_SUCCESS'
export const PRODGROUP_BYID_RESET = 'PRODGROUP_BYID_RESET'
export const PRODGROUP_BYID_FAIL = 'PRODGROUP_BYID_FAIL'

export const PRODGROUP_UPDATE_REQUEST = 'PRODGROUP_UPDATE_REQUEST'
export const PRODGROUP_UPDATE_SUCCESS = 'PRODGROUP_UPDATE_SUCCESS'
export const PRODGROUP_UPDATE_RESET = 'PRODGROUP_UPDATE_RESET'
export const PRODGROUP_UPDATE_ERROR = 'PRODGROUP_UPDATE_ERROR'
export const PRODGROUP_UPDATE_FAIL = 'PRODGROUP_UPDATE_FAIL'

export const PRODGROUP_CREATE_REQUEST = 'PRODGROUP_CREATE_REQUEST'
export const PRODGROUP_CREATE_SUCCESS = 'PRODGROUP_CREATE_SUCCESS'
export const PRODGROUP_CREATE_RESET = 'PRODGROUP_CREATE_RESET'
export const PRODGROUP_CREATE_ERROR = 'PRODGROUP_CREATE_ERROR'
export const PRODGROUP_CREATE_FAIL = 'PRODGROUP_CREATE_FAIL'

export const PGLOGIN_ADD_REQUEST = 'PGLOGIN_ADD_REQUEST'
export const PGLOGIN_ADD_SUCCESS = 'PGLOGIN_ADD_SUCCESS'
export const PGLOGIN_ADD_RESET = 'PGLOGIN_ADD_RESET'
export const PGLOGIN_ADD_FAIL = 'PGLOGIN_ADD_FAIL'

export const PGLOGIN_REMOVE_REQUEST = 'PGLOGIN_REMOVE_REQUEST'
export const PGLOGIN_REMOVE_SUCCESS = 'PGLOGIN_REMOVE_SUCCESS'
export const PGLOGIN_REMOVE_RESET = 'PGLOGIN_REMOVE_RESET'
export const PGLOGIN_REMOVE_FAIL = 'PGLOGIN_REMOVE_FAIL'

export const PGCATEGORY_ADD_REQUEST = 'PGCATEGORY_ADD_REQUEST'
export const PGCATEGORY_ADD_SUCCESS = 'PGCATEGORY_ADD_SUCCESS'
export const PGCATEGORY_ADD_RESET = 'PGCATEGORY_ADD_RESET'
export const PGCATEGORY_ADD_FAIL = 'PGCATEGORY_ADD_FAIL'

export const PGCATEGORY_REMOVE_REQUEST = 'PGCATEGORY_REMOVE_REQUEST'
export const PGCATEGORY_REMOVE_SUCCESS = 'PGCATEGORY_REMOVE_SUCCESS'
export const PGCATEGORY_REMOVE_RESET = 'PGCATEGORY_REMOVE_RESET'
export const PGCATEGORY_REMOVE_FAIL = 'PGCATEGORY_REMOVE_FAIL'

export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_REQUEST = 'FILE_UPLOAD_REQUEST'
export const FILE_UPLOAD_RESET = 'FILE_UPLOAD_RESET'
export const FILE_UPLOAD_FAIL = 'FILE_UPLOAD_FAIL'
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR'

//create quick order
export const QUICK_ORDER_CREATE_REQUEST = 'QUICK_ORDER_CREATE_REQUEST'
export const QUICK_ORDER_CREATE_SUCCESS = 'QUICK_ORDER_CREATE_SUCCESS'
export const QUICK_ORDER_CREATE_FAIL = 'QUICK_ORDER_CREATE_FAIL'
export const QUICK_ORDER_CREATE_RESET = 'QUICK_ORDER_CREATE_RESET'
export const QUICK_ORDER_CREATE_ERROR_REQUIRE =
  'QUICK_ORDER_CREATE_ERROR_REQUIRE'
export const QUICK_ORDER_CREATE_ERROR_QUANTITY =
  'QUICK_ORDER_CREATE_ERROR_QUANTITY'

//cancel order
export const ORDER_CANCEL_REQUEST = 'ORDER_CANCEL_REQUEST'
export const ORDER_CANCEL_SUCCESS = 'ORDER_CANCEL_SUCCESS'
export const ORDER_CANCEL_FAIL = 'ORDER_CANCEL_FAIL'
export const ORDER_CANCEL_RESET = 'ORDER_CANCEL_RESET'

export const ADDRESS_BOOK_GET_REQUEST = 'ADDRESS_BOOK_GET_REQUEST'
export const ADDRESS_BOOK_GET_SUCCESS = 'ADDRESS_BOOK_GET_SUCCESS'
export const ADDRESS_BOOK_GET_RESET = 'ADDRESS_BOOK_GET_RESET'
export const ADDRESS_BOOK_GET_FAIL = 'ADDRESS_BOOK_GET_FAIL'

export const ADDRESS_BOOK_CREATE_REQUEST = 'ADDRESS_BOOK_CREATE_REQUEST'
export const ADDRESS_BOOK_CREATE_SUCCESS = 'ADDRESS_BOOK_CREATE_SUCCESS'
export const ADDRESS_BOOK_CREATE_RESET = 'ADDRESS_BOOK_CREATE_RESET'
export const ADDRESS_BOOK_CREATE_FAIL = 'ADDRESS_BOOK_CREATE_FAIL'

export const ADDRESS_BOOK_DELETE_REQUEST = 'ADDRESS_BOOK_DELETE_REQUEST'
export const ADDRESS_BOOK_DELETE_SUCCESS = 'ADDRESS_BOOK_DELETE_SUCCESS'
export const ADDRESS_BOOK_DELETE_RESET = 'ADDRESS_BOOK_DELETE_RESET'
export const ADDRESS_BOOK_DELETE_FAIL = 'ADDRESS_BOOK_DELETE_FAIL'

export const ADDRESS_BOOK_UPDATE_REQUEST = 'ADDRESS_BOOK_UPDATE_REQUEST'
export const ADDRESS_BOOK_UPDATE_SUCCESS = 'ADDRESS_BOOK_UPDATE_SUCCESS'
export const ADDRESS_BOOK_UPDATE_RESET = 'ADDRESS_BOOK_UPDATE_RESET'
export const ADDRESS_BOOK_UPDATE_FAIL = 'ADDRESS_BOOK_UPDATE_FAIL'

export const ADDRESS_BOOK_BYID_REQUEST = 'ADDRESS_BOOK_BYID_REQUEST'
export const ADDRESS_BOOK_BYID_SUCCESS = 'ADDRESS_BOOK_BYID_SUCCESS'
export const ADDRESS_BOOK_BYID_RESET = 'ADDRESS_BOOK_BYID_RESET'
export const ADDRESS_BOOK_BYID_FAIL = 'ADDRESS_BOOK_BYID_FAIL'

export const ORDER_UPDATE_REQUEST = 'ORDER_UPDATE_REQUEST'
export const ORDER_UPDATE_SUCCESS = 'ORDER_UPDATE_SUCCESS'
export const ORDER_UPDATE_RESET = 'ORDER_UPDATE_RESET'
export const ORDER_UPDATE_FAIL = 'ORDER_UPDATE_FAIL'

export const MAILING_LIST_DELETE_REQUEST = 'MAILING_LIST_DELETE_REQUEST'
export const MAILING_LIST_DELETE_SUCCESS = 'MAILING_LIST_DELETE_SUCCESS'
export const MAILING_LIST_DELETE_RESET = 'MAILING_LIST_DELETE_RESET'
export const MAILING_LIST_DELETE_FAIL = 'MAILING_LIST_DELETE_FAIL'

export const MAILING_LIST_BYID_REQUEST = 'MAILING_LIST_BYID_REQUEST'
export const MAILING_LIST_BYID_SUCCESS = 'MAILING_LIST_BYID_SUCCESS'
export const MAILING_LIST_BYID_RESET = 'MAILING_LIST_BYID_RESET'
export const MAILING_LIST_BYID_FAIL = 'MAILING_LIST_BYID_FAIL'

export const MANAGER_MEMBER_BYID_REQUEST = 'MANANGER_MEMBER_BYID_REQUEST'
export const MANAGER_MEMBER_BYID_SUCCESS = 'MANANGER_MEMBER_BYID_SUCCESS'
export const MANAGER_MEMBER_BYID_RESET = 'MANANGER_MEMBER_BYID_RESET'
export const MANAGER_MEMBER_BYID_FAIL = 'MANANGER_MEMBER_BYID_RESET'

export const MANAGER_MEMLOGIN_ADD_REQUEST = 'MANAGER_MEMLOGIN_ADD_REQUEST'
export const MANAGER_MEMLOGIN_ADD_SUCCESS = 'MANAGER_MEMLOGIN_ADD_SUCCESS'
export const MANAGER_MEMLOGIN_ADD_RESET = 'MANAGER_MEMLOGIN_ADD_RESET'
export const MANAGER_MEMLOGIN_ADD_FAIL = 'MANAGER_MEMLOGIN_ADD_FAIL'

export const MANAGER_MEMLOGIN_REMOVE_REQUEST = 'MANAGER_MEMLOGIN_REMOVE_REQUEST'
export const MANAGER_MEMLOGIN_REMOVE_SUCCESS = 'MANAGER_MEMLOGIN_REMOVE_SUCCESS'
export const MANAGER_MEMLOGIN_REMOVE_RESET = 'MANAGER_MEMLOGIN_REMOVE_RESET'
export const MANAGER_MEMLOGIN_REMOVE_FAIL = 'MANAGER_MEMLOGIN_REMOVE_FAIL'

export const MAILINGLIST_CSV_UPLOAD_REQUEST = 'MAILINGLIST_CSV_UPLOAD_REQUEST'
export const MAILINGLIST_CSV_UPLOAD_SUCCESS = 'MAILINGLIST_CSV_UPLOAD_SUCCESS'
export const MAILINGLIST_CSV_UPLOAD_RESET = 'MAILINGLIST_CSV_UPLOAD_RESET'
export const MAILINGLIST_CSV_UPLOAD_FAIL = 'MAILINGLIST_CSV_UPLOAD_FAIL'

export const ORDERS_CSV_UPLOAD_REQUEST = 'ORDERS_CSV_UPLOAD_REQUEST'
export const ORDERS_CSV_UPLOAD_SUCCESS = 'ORDERS_CSV_UPLOAD_SUCCESS'
export const ORDERS_CSV_UPLOAD_RESET = 'ORDERS_CSV_UPLOAD_RESET'
export const ORDERS_CSV_UPLOAD_FAIL = 'ORDERS_CSV_UPLOAD_FAIL'

export const PRICEGROUP_MEMBER_BYID_REQUEST = 'PRICEGROUP_MEMBER_BYID_REQUEST'
export const PRICEGROUP_MEMBER_BYID_SUCCESS = 'PRICEGROUP_MEMBER_BYID_SUCCESS'
export const PRICEGROUP_MEMBER_BYID_RESET = 'PRICEGROUP_MEMBER_BYID_RESET'
export const PRICEGROUP_MEMBER_BYID_FAIL = 'PRICEGROUP_MEMBER_BYID_FAIL'

export const PRICEGROUP_MEMLOGIN_ADD_REQUEST = 'PRICEGROUP_MEMLOGIN_ADD_REQUEST'
export const PRICEGROUP_MEMLOGIN_ADD_SUCCESS = 'PRICEGROUP_MEMLOGIN_ADD_SUCCESS'
export const PRICEGROUP_MEMLOGIN_ADD_RESET = 'PRICEGROUP_MEMLOGIN_ADD_RESET'
export const PRICEGROUP_MEMLOGIN_ADD_FAIL = 'PRICEGROUP_MEMLOGIN_ADD_FAIL'

export const PRICEGROUP_MEMLOGIN_REMOVE_REQUEST =
  'PRICEGROUP_MEMLOGIN_REMOVE_REQUEST'
export const PRICEGROUP_MEMLOGIN_REMOVE_SUCCESS =
  'PRICEGROUP_MEMLOGIN_REMOVE_SUCCESS'
export const PRICEGROUP_MEMLOGIN_REMOVE_RESET =
  'PRICEGROUP_MEMLOGIN_REMOVE_RESET'
export const PRICEGROUP_MEMLOGIN_REMOVE_FAIL = 'PRICEGROUP_MEMLOGIN_REMOVE_FAIL'

export const PRICEGROUP_UPDATE_REQUEST = 'PRICEGROUP_UPDATE_REQUEST'
export const PRICEGROUP_UPDATE_SUCCESS = 'PRICEGROUP_UPDATE_SUCCESS'
export const PRICEGROUP_UPDATE_RESET = 'PRICEGROUP_UPDATE_RESET'
export const PRICEGROUP_UPDATE_ERROR = 'PRICEGROUP_UPDATE_ERROR'
export const PRICEGROUP_UPDATE_FAIL = 'PRICEGROUP_UPDATE_FAIL'

export const PRICEGROUP_CREATE_REQUEST = 'PRICEGROUP_CREATE_REQUEST'
export const PRICEGROUP_CREATE_SUCCESS = 'PRICEGROUP_CREATE_SUCCESS'
export const PRICEGROUP_CREATE_RESET = 'PRICEGROUP_CREATE_RESET'
export const PRICEGROUP_CREATE_ERROR = 'PRICEGROUP_CREATE_ERROR'
export const PRICEGROUP_CREATE_FAIL = 'PRICEGROUP_CREATE_FAIL'

export const USERGROUP_BYID_REQUEST = 'USERGROUP_BYID_REQUEST'
export const USERGROUP_BYID_SUCCESS = 'USERGROUP_BYID_SUCCESS'
export const USERGROUP_BYID_RESET = 'USERGROUP_BYID_RESET'
export const USERGROUP_BYID_FAIL = 'USERGROUP_BYID_FAIL'

export const USERGROUP_USER_ADD_REQUEST = 'USERGROUP_USER_ADD_REQUEST'
export const USERGROUP_USER_ADD_SUCCESS = 'USERGROUP_USER_ADD_SUCCESS'
export const USERGROUP_USER_ADD_RESET = 'USERGROUP_USER_ADD_RESET'
export const USERGROUP_USER_ADD_FAIL = 'USERGROUP_USER_ADD_FAIL'

export const USERGROUP_USER_REMOVE_REQUEST = 'USERGROUP_USER_REMOVE_REQUEST'
export const USERGROUP_USER_REMOVE_SUCCESS = 'USERGROUP_USER_REMOVE_SUCCESS'
export const USERGROUP_USER_REMOVE_RESET = 'USERGROUP_USER_REMOVE_RESET'
export const USERGROUP_USER_REMOVE_FAIL = 'USERGROUP_USER_REMOVE_FAIL'

export const USERGROUP_UPDATE_REQUEST = 'USERGROUP_UPDATE_REQUEST'
export const USERGROUP_UPDATE_SUCCESS = 'USERGROUP_UPDATE_SUCCESS'
export const USERGROUP_UPDATE_RESET = 'USERGROUP_UPDATE_RESET'
export const USERGROUP_UPDATE_ERROR = 'USERGROUP_UPDATE_ERROR'
export const USERGROUP_UPDATE_FAIL = 'USERGROUP_UPDATE_FAIL'

export const USERGROUP_CREATE_REQUEST = 'USERGROUP_CREATE_REQUEST'
export const USERGROUP_CREATE_SUCCESS = 'USERGROUP_CREATE_SUCCESS'
export const USERGROUP_CREATE_RESET = 'USERGROUP_CREATE_RESET'
export const USERGROUP_CREATE_ERROR = 'USERGROUP_CREATE_ERROR'
export const USERGROUP_CREATE_FAIL = 'USERGROUP_CREATE_FAIL'

export const IMAGE_DELETE_REQUEST = 'IMAGE_DELETE_REQUEST'
export const IMAGE_DELETE_SUCCESS = 'IMAGE_DELETE_SUCCESS'
export const IMAGE_DELETE_RESET = 'IMAGE_DELETE_RESET'
export const IMAGE_DELETE_FAIL = 'IMAGE_DELETE_FAIL'

export const IMAGE_USER_ADD_REQUEST = 'IMAGE_USER_ADD_REQUEST'
export const IMAGE_USER_ADD_SUCCESS = 'IMAGE_USER_ADD_SUCCESS'
export const IMAGE_USER_ADD_ERROR = 'IMAGE_USER_ADD_ERROR'
export const IMAGE_USER_ADD_RESET = 'IMAGE_USER_ADD_RESET'
export const IMAGE_USER_ADD_FAIL = 'IMAGE_USER_ADD_FAIL'

export const CATEGORY_TREE_GET_REQUEST = 'CATEGORY_TREE_GET_REQUEST'
export const CATEGORY_TREE_GET_SUCCESS = 'CATEGORY_TREE_GET_SUCCESS'
export const CATEGORY_TREE_GET_RESET = 'CATEGORY_TREE_GET_RESET'
export const CATEGORY_TREE_GET_FAIL = 'CATEGORY_TREE_GET_FAIL'

export const ILINK_CATEGORY_TREE_GET_REQUEST = 'ILINK_CATEGORY_TREE_GET_REQUEST'
export const ILINK_CATEGORY_TREE_GET_SUCCESS = 'ILINK_CATEGORY_TREE_GET_SUCCESS'
export const ILINK_CATEGORY_TREE_GET_RESET = 'ILINK_CATEGORY_TREE_GET_RESET'
export const ILINK_CATEGORY_TREE_GET_FAIL = 'ILINK_CATEGORY_TREE_GET_FAIL'

export const PRODUCT_PRICING_BYID_REQUEST = 'PRODUCT_PRICING_BYID_REQUEST'
export const PRODUCT_PRICING_BYID_SUCCESS = 'PRODUCT_PRICING_BYID_SUCCESS'
export const PRODUCT_PRICING_BYID_RESET = 'PRODUCT_PRICING_BYID_RESET'
export const PRODUCT_PRICING_BYID_FAIL = 'PRODUCT_PRICING_BYID_FAIL'

export const PRODUCT_PRICING_UPDATE_REQUEST = 'PRODUCT_PRICING_UPDATE_REQUEST'
export const PRODUCT_PRICING_UPDATE_SUCCESS = 'PRODUCT_PRICING_UPDATE_SUCCESS'
export const PRODUCT_PRICING_UPDATE_RESET = 'PRODUCT_PRICING_UPDATE_RESET'
export const PRODUCT_PRICING_UPDATE_FAIL = 'PRODUCT_PRICING_UPDATE_FAIL'

export const CART_GET_REQUEST = 'CART_GET_REQUEST'
export const CART_GET_SUCCESS = 'CART_GET_SUCCESS'
//export const CART_GET_LOCK = "CART_GET_LOCK"
export const CART_GET_RESET = 'CART_GET_RESET'
export const CART_GET_FAIL = 'CART_GET_FAIL'

export const CART_PRODUCT_ADD_REQUEST = 'CART_PRODUCT_ADD_REQUEST'
export const CART_PRODUCT_ADD_SUCCESS = 'CART_PRODUCT_ADD_SUCCESS'
export const CART_PRODUCT_ADD_RESET = 'CART_PRODUCT_ADD_RESET'
export const CART_PRODUCT_ADD_FAIL = 'CART_PRODUCT_ADD_FAIL'

export const CART_PRODUCT_REMOVE_REQUEST = 'CART_PRODUCT_REMOVE_REQUEST'
export const CART_PRODUCT_REMOVE_SUCCESS = 'CART_PRODUCT_REMOVE_SUCCESS'
export const CART_PRODUCT_REMOVE_RESET = 'CART_PRODUCT_REMOVE_RESET'
export const CART_PRODUCT_REMOVE_FAIL = 'CART_PRODUCT_REMOVE_FAIL'

export const ORDER_FORM_GET_REQUEST = 'ORDER_FORM_GET_REQUEST'
export const ORDER_FORM_GET_SUCCESS = 'ORDER_FORM_GET_SUCCESS'
export const ORDER_FORM_GET_RESET = 'ORDER_FORM_GET_RESET'
export const ORDER_FORM_GET_FAIL = 'ORDER_FORM_GET_FAIL'

export const CHECKOUT_STEP_INIT = 'CHECKOUT_STEP_INIT'
export const CHECKOUT_STEP_SHIPTO = 'CHECKOUT_STEP_SHIPTO'
export const CHECKOUT_STEP_BILLTO = 'CHECKOUT_STEP_BILLTO'
export const CHECKOUT_STEP_PAYMENT = 'CHECKOUT_STEP_PAYMENT'
export const CHECKOUT_STEP_PLACE_ORDER = 'CHECKOUT_STEP_PLACE_ORDER'
export const CHECKOUT_STEP_ORDER_COMPLETE = 'CHECKOUT_STEP_ORDER_COMPLETE'

export const STEP_INIT = 1
export const STEP_SHIPTO = 2
export const STEP_BILLTO = 3
export const STEP_PAYMENT = 4
export const STEP_PLACE_ORDER = 5
export const STEP_ORDER_COMPLETE = 6

export const CHECKOUT_ORDER_CREATE_REQUEST = 'CHECKOUT_ORDER_CREATE_REQUEST'
export const CHECKOUT_ORDER_CREATE_SUCCESS = 'CHECKOUT_ORDER_CREATE_SUCCESS'
export const CHECKOUT_ORDER_CREATE_RESET = 'CHECKOUT_ORDER_CREATE_RESET'
export const CHECKOUT_ORDER_CREATE_ERROR = 'CHECKOUT_ORDER_CREATE_ERROR'
export const CHECKOUT_ORDER_CREATE_FAIL = 'CHECKOUT_ORDER_CREATE_FAIL'

export const CART_PRODUCT_UPDATE_REQUEST = 'CART_PRODUCT_UPDATE_REQUEST'
export const CART_PRODUCT_UPDATE_SUCCESS = 'CART_PRODUCT_UPDATE_SUCCESS'
export const CART_PRODUCT_UPDATE_RESET = 'CART_PRODUCT_UPDATE_RESET'
export const CART_PRODUCT_UPDATE_FAIL = 'CART_PRODUCT_UPDATE_FAIL'

export const CART_PRODLIST_ADD_REQUEST = 'CART_PRODLIST_ADD_REQUEST'
export const CART_PRODLIST_ADD_SUCCESS = 'CART_PRODLIST_ADD_SUCCESS'
export const CART_PRODLIST_ADD_RESET = 'CART_PRODLIST_ADD_RESET'
export const CART_PRODLIST_ADD_FAIL = 'CART_PRODLIST_ADD_FAIL'

export const CURRENT_SESSION_SET_REQUEST = 'CURRENT_SESSION_SET_REQUEST'
export const CURRENT_SESSION_SET_SUCCESS = 'CURRENT_SESSION_SET_SUCCESS'
export const CURRENT_SESSION_SET_RESET = 'CURRENT_SESSION_SET_RESET'
export const CURRENT_SESSION_SET_FAIL = 'CURRENT_SESSION_SET_FAIL'

//update WSVariant updateProductVariant
export const PRODUCT_VARIANT_UPDATE_REQUEST = 'PRODUCT_VARIANT_UPDATE_REQUEST'
export const PRODUCT_VARIANT_UPDATE_SUCCESS = 'PRODUCT_VARIANT_UPDATE_SUCCESS'
export const PRODUCT_VARIANT_UPDATE_FAIL = 'PRODUCT_VARIANT_UPDATE_FAIL'
export const PRODUCT_VARIANT_UPDATE_RESET = 'PRODUCT_VARIANT_UPDATE_RESET'
//deleteProductVariant
export const PRODUCT_VARIANT_DELETE_REQUEST = 'PRODUCT_VARIANT_DELETE_REQUEST'
export const PRODUCT_VARIANT_DELETE_SUCCESS = 'PRODUCT_VARIANT_DELETE_SUCCESS'
export const PRODUCT_VARIANT_DELETE_FAIL = 'PRODUCT_VARIANT_DELETE_FAIL'
export const PRODUCT_VARIANT_DELETE_RESET = 'PRODUCT_VARIANT_DELETE_RESET'
//createMasterVariant
export const MASTER_VARIANT_CREATE_REQUEST = 'MASTER_VARIANT_CREATE_REQUEST'
export const MASTER_VARIANT_CREATE_SUCCESS = 'MASTER_VARIANT_CREATE_SUCCESS'
export const MASTER_VARIANT_CREATE_RESET = 'MASTER_VARIANT_CREATE_RESET'
export const MASTER_VARIANT_CREATE_FAIL = 'MASTER_VARIANT_CREATE_FAIL'
//AddUpdateVariance
export const UPDATE_VARIANCE_CREATE_REQUEST = 'UPDATE_VARIANCE_CREATE_REQUEST'
export const UPDATE_VARIANCE_CREATE_SUCCESS = 'UPDATE_VARIANCE_CREATE_SUCCESS'
export const UPDATE_VARIANCE_CREATE_RESET = 'UPDATE_VARIANCE_CREATE_RESET'
export const UPDATE_VARIANCE_CREATE_FAIL = 'UPDATE_VARIANCE_CREATE_FAIL'
//updateVariance
export const VARIANCE_UPDATE_REQUEST = 'VARIANCE_UPDATE_REQUEST'
export const VARIANCE_UPDATE_SUCCESS = 'VARIANCE_UPDATE_SUCCESS'
export const VARIANCE_UPDATE_RESET = 'VARIANCE_UPDATE_RESET'
export const VARIANCE_UPDATE_FAIL = 'VARIANCE_UPDATE_FAIL'
//deleteVariance
export const VARIANCE_DELETE_REQUEST = 'VARIANCE_DELETE_REQUEST'
export const VARIANCE_DELETE_SUCCESS = 'VARIANCE_DELETE_SUCCESS'
export const VARIANCE_DELETE_RESET = 'VARIANCE_DELETE_RESET'
export const VARIANCE_DELETE_FAIL = 'VARIANCE_DELETE_FAIL'

export const PRICEGROUP_PROD_ADD_REQUEST = 'PRICEGROUP_PROD_ADD_REQUEST'
export const PRICEGROUP_PROD_ADD_SUCCESS = 'PRICEGROUP_PROD_ADD_SUCCESS'
export const PRICEGROUP_PROD_ADD_RESET = 'PRICEGROUP_PROD_ADD_RESET'
export const PRICEGROUP_PROD_ADD_FAIL = 'PRICEGROUP_PROD_ADD_FAIL'

export const PRICEGROUP_PROD_UPDATE_REQUEST = 'PRICEGROUP_PROD_UPDATE_REQUEST'
export const PRICEGROUP_PROD_UPDATE_SUCCESS = 'PRICEGROUP_PROD_UPDATE_SUCCESS'
export const PRICEGROUP_PROD_UPDATE_RESET = 'PRICEGROUP_PROD_UPDATE_RESET'
export const PRICEGROUP_PROD_UPDATE_FAIL = 'PRICEGROUP_PROD_UPDATE_FAIL'

export const PRICEGROUP_PROD_DELETE_REQUEST = 'PRICEGROUP_PROD_DELETE_REQUEST'
export const PRICEGROUP_PROD_DELETE_SUCCESS = 'PRICEGROUP_PROD_DELETE_SUCCESS'
export const PRICEGROUP_PROD_DELETE_RESET = 'PRICEGROUP_PROD_DELETE_RESET'
export const PRICEGROUP_PROD_DELETE_FAIL = 'PRICEGROUP_PROD_DELETE_FAIL'

export const FORMS_BYPRODUCTIDS_GET_REQUEST = 'FORMS_BYPRODUCTIDS_GET_REQUEST'
export const FORMS_BYPRODUCTIDS_GET_FAIL = 'FORMS_BYPRODUCTIDS_GET_FAIL'
export const FORMS_BYPRODUCTIDS_GET_SUCCESS = 'FORMS_BYPRODUCTIDS_GET_SUCCESS'
export const FORMS_BYPRODUCTIDS_GET_RESET = 'FORMS_BYPRODUCTIDS_GET_RESET'

export const PRODUCT_QUOTA_UPDATE_REQUEST = 'PRODUCT_QUOTA_UPDATE_REQUEST'
export const PRODUCT_QUOTA_UPDATE_FAIL = 'PRODUCT_QUOTA_UPDATE_FAIL'
export const PRODUCT_QUOTA_UPDATE_SUCCESS = 'PRODUCT_QUOTA_UPDATE_SUCCESS'
export const PRODUCT_QUOTA_UPDATE_RESET = 'PRODUCT_QUOTA_UPDATE_RESET'

export const PRODUCT_QUOTA_GET_REQUEST = 'PRODUCT_QUOTA_GET_REQUEST'
export const PRODUCT_QUOTA_GET_FAIL = 'PRODUCT_QUOTA_GET_FAIL'
export const PRODUCT_QUOTA_GET_SUCCESS = 'PRODUCT_QUOTA_GET_SUCCESS'
export const PRODUCT_QUOTA_GET_RESET = 'PRODUCT_QUOTA_GET_RESET'

export const CAPTURE_CUSTJSON_GET_REQUEST = 'CAPTURE_CUSTJSON_GET_REQUEST'
export const CAPTURE_CUSTJSON_GET_SUCCESS = 'CAPTURE_CUSTJSON_GET_SUCCESS'
export const CAPTURE_CUSTJSON_GET_RESET = 'CAPTURE_CUSTJSON_GET_RESET'
export const CAPTURE_CUSTJSON_GET_FAIL = 'CAPTURE_CUSTJSON_GET_FAIL'

export const CHECK_ADDRESS_USPS_REQUEST = 'CHECK_ADDRESS_USPS_REQUEST'
export const CHECK_ADDRESS_USPS_SUCCESS = 'CHECK_ADDRESS_USPS_SUCCESS'
export const CHECK_ADDRESS_USPS_RESET = 'CHECK_ADDRESS_USPS_RESET'
export const CHECK_ADDRESS_USPS_FAIL = 'CHECK_ADDRESS_USPS_FAIL'

export const MULTI_ORDERS_CREATE_REQUEST = 'MULTI_ORDERS_CREATE_REQUEST'
export const MULTI_ORDERS_CREATE_SUCCESS = 'MULTI_ORDERS_CREATE_SUCCESS'
export const MULTI_ORDERS_CREATE_RESET = 'MULTI_ORDERS_CREATE_RESET'
export const MULTI_ORDERS_CREATE_FAIL = 'MULTI_ORDERS_CREATE_FAIL'

export const OFFORM_DETAIL_GET_REQUEST = 'OFFORM_DETAIL_GET_REQUEST'
export const OFFORM_DETAIL_GET_SUCCESS = 'OFFORM_DETAIL_GET_SUCCESS'
export const OFFORM_DETAIL_GET_RESETT = 'OFFORM_DETAIL_GET_RESETT'
export const OFFORM_DETAIL_GET_FAIL = 'OFFORM_DETAIL_GET_FAILT'

export const OFFORM_DETAIL_UPDATE_REQUEST = 'OFFORM_DETAIL_UPDATE_REQUEST'
export const OFFORM_DETAIL_UPDATE_SUCCESS = 'OFFORM_DETAIL_UPDATE_SUCCESS'
export const OFFORM_DETAIL_UPDATE_RESET = 'OFFORM_DETAIL_UPDATE_RESET'
export const OFFORM_DETAIL_UPDATE_FAIL = 'OFFORM_DETAIL_UPDATE_FAILT'

export const MMBOOK_COVER_SIZE_ERROR = 'MMBOOK_COVER_SIZE_ERROR'
export const MMBOOK_TEXT_SIZE_ERROR = 'MMBOOK_TEXT_SIZE_ERROR'
export const MMBOOK_COVER_RESET = 'MMBOOK_COVER_RESET'
export const MMBOOK_TEXT_RESET = 'MMBOOK_TEXT_RESET'
export const MMBOOK_ISBN_EXIST_ERROR = 'MMBOOK_ISBN_EXIST_ERROR'
export const MMBOOK_ISBN_VALIDATE_ERROR = 'MMBOOK_ISBN_VALIDATE_ERROR'
export const MMBOOK_ERROR_RESET = 'MMBOOK_ERROR_RESET'
export const MMBOOK_RESET_REQUEST = 'MMBOOK_RESET_REQUEST'

export const MMBOOK_COVER_CREATE_REQUEST = 'MMBOOK_COVER_CREATE_REQUEST'
export const MMBOOK_COVER_CREATE_SUCCESS = 'MMBOOK_COVER_CREATE_SUCCESS'
export const MMBOOK_COVER_CREATE_RESET = 'MMBOOK_COVER_CREATE_RESET'
export const MMBOOK_COVER_CREATE_FAIL = 'MMBOOK_COVER_CREATE_FAIL'

export const MMBOOK_CREATE_REQUEST = 'MMBOOK_CREATE_REQUEST'
export const MMBOOK_CREATE_SUCCESS = 'MMBOOK_CREATE_SUCCESS'
export const MMBOOK_CREATE_RESET = 'MMBOOK_CREATE_RESET'
export const MMBOOK_CREATE_FAIL = 'MMBOOK_CREATE_FAIL'

export const CUSTOM_BOOK_UPDATE_REQUEST = 'CUSTOM_BOOK_UPDATE_REQUEST'
export const CUSTOM_BOOK_UPDATE_SUCCESS = 'CUSTOM_BOOK_UPDATE_SUCCESS'
export const CUSTOM_BOOK_UPDATE_RESET = 'CUSTOM_BOOK_UPDATE_RESET'
export const CUSTOM_BOOK_UPDATE_FAIL = 'CUSTOM_BOOK_UPDATE_FAIL'

export const MMBOOK_UPLOAD_REQUEST = 'MMBOOK_UPLOAD_REQUEST'
export const MMBOOK_UPLOAD_SUCCESS = 'MMBOOK_UPLOAD_SUCCESS'
export const MMBOOK_UPLOAD_FAIL = 'MMBOOK_UPLOAD_FAIL'

export const TOGGLE_FAVORITE_REQUEST = 'TOGGLE_FAVORITE_REQUEST'
export const TOGGLE_FAVORITE_SUCCESS = 'TOGGLE_FAVORITE_SUCCESS'
export const TOGGLE_FAVORITE_FAIL = 'TOGGLE_FAVORITE_FAIL'
export const TOGGLE_FAVORITE_RESET = 'TOGGLE_FAVORITE_RESET'

export const ORDER_DETAIL_REQUEST = 'ORDER_DETAIL_REQUEST'
export const ORDER_DETAIL_SUCCESS = 'ORDER_DETAIL_SUCCESS'
export const ORDER_DETAIL_FAIL = 'ORDER_DETAIL_FAIL'
export const ORDER_DETAIL_RESET = 'ORDER_DETAIL_RESET'

export const PASSWORD_POLICY_GET_REQUEST = 'PASSWORD_POLICY_GET_REQUEST'
export const PASSWORD_POLICY_GET_SUCCESS = 'PASSWORD_POLICY_GET_SUCCESS'
export const PASSWORD_POLICY_GET_FAILURE = 'PASSWORD_POLICY_GET_FAILURE'
export const PASSWORD_POLICY_GET_RESET = 'PASSWORD_POLICY_GET_RESET'

export const PASSWORD_POLICY_UPDATE_REQUEST = 'PASSWORD_POLICY_UPDATE_REQUEST'
export const PASSWORD_POLICY_UPDATE_SUCCESS = 'PASSWORD_POLICY_UPDATE_SUCCESS'
export const PASSWORD_POLICY_UPDATE_FAILURE = 'PASSWORD_POLICY_UPDATE_FAILURE'
export const PASSWORD_POLICY_UPDATE_RESET = 'PASSWORD_POLICY_UPDATE_RESET'

export const KIT_UPDATE_REQUEST = 'KIT_UPDATE_REQUEST'
export const KIT_UPDATE_SUCCESS = 'KIT_UPDATE_SUCCESS'
export const KIT_UPDATE_FAIL = 'KIT_UPDATE_FAIL'
export const KIT_UPDATE_RESET = 'KIT_UPDATE_RESET'

export const LOGO_CREATE_REQUEST = 'LOGO_CREATE_REQUEST'
export const LOGO_CREATE_SUCCESS = 'LOGO_CREATE_SUCCESS'
export const LOGO_CREATE_FAIL = 'LOGO_CREATE_FAIL'
export const LOGO_CREATE_RESET = 'LOGO_CREATE_RESET'

export const LOGO_DELETE_REQUEST = 'LOGO_DELETE_REQUEST'
export const LOGO_DELETE_SUCCESS = 'LOGO_DELETE_SUCCESS'
export const LOGO_DELETE_FAIL = 'LOGO_DELETE_FAIL'
export const LOGO_DELETE_RESET = 'LOGO_DELETE_RESET'
