import axios from 'axios'
import {
  FORM_GET_REQUEST,
  FORM_GET_SUCCESS,
  FORM_GET_FAIL,
  QFORM_GET_REQUEST,
  QFORM_GET_SUCCESS,
  QFORM_GET_FAIL,
  REPORT_GET_REQUEST,
  REPORT_GET_SUCCESS,
  REPORT_GET_FAIL,
  QFORMS_GET_REQUEST,
  QFORMS_GET_SUCCESS,
  QFORMS_GET_FAIL,
  REPORTS_IDS_GET_REQUEST,
  REPORTS_IDS_GET_SUCCESS,
  REPORTS_IDS_GET_FAIL,
  STATES_GET_REQUEST,
  STATES_GET_SUCCESS,
  STATES_GET_FAIL,
  COUNTRIES_GET_REQUEST,
  COUNTRIES_GET_SUCCESS,
  COUNTRIES_GET_FAIL,
  WEBFORM_GET_REQUEST,
  WEBFORM_GET_SUCCESS,
  WEBFORM_GET_FAIL,
  WEBFORM_REFRESH_SUCCESS,
  WEBFORM_REFRESH_REQUEST,
} from '../constants/formConstants'
import { getAPIGATEWAY } from '../utils/apiGateway'

export const getFormById =
  (formid, account = 0, lang = 'EN') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FORM_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/select/form/${formid}?lang=${lang}`,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      localStorage.setItem('forms', JSON.stringify(data))
      dispatch({ type: FORM_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: FORM_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getQuickOrderFormById =
  (formid, lang = 'EN') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: QFORM_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/select/quickorderform/${formid}?lang=${lang}`,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      localStorage.setItem('forms', JSON.stringify(data))
      dispatch({ type: QFORM_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: QFORM_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getQuickOrderForms = () => async (dispatch, getState) => {
  try {
    dispatch({ type: QFORMS_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/quickorderforms`,

      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    localStorage.setItem('forms', JSON.stringify(data))
    dispatch({ type: QFORMS_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: QFORMS_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getFormsByIds =
  (formids, account = 0, lang = 'EN') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FORM_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/select/forms/${formids.join()}?lang=${lang}`,

        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      localStorage.setItem('forms', JSON.stringify(data))
      dispatch({ type: FORM_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: FORM_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getWebstoreForms = (action) => async (dispatch, getState) => {
  try {
    dispatch({ type: action ? WEBFORM_REFRESH_REQUEST : WEBFORM_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: action
        ? `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/forms/payment`
        : `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/forms`,

      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({
      type: action ? WEBFORM_REFRESH_SUCCESS : WEBFORM_GET_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: WEBFORM_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getReportById =
  (reportid, select) => async (dispatch, getState) => {
    try {
      dispatch({ type: REPORT_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const url = Array.isArray(reportid)
        ? `${getAPIGATEWAY(
            userInfo.dbname
          )}/api/v1/select/reports/${reportid.join(',')}?select=${select}`
        : reportid === '8B1C3FD7-71DD-4DC1-A681-9B15DD79E218' ||
          reportid === '59F3304F-B09F-41C1-956D-BF33706C05B5'
        ? `${getAPIGATEWAY(
            userInfo.dbname
          )}/api/v1/fulfillment/report/solidworksusage/${reportid}?select=${select}`
        : `${getAPIGATEWAY(
            userInfo.dbname
          )}/api/v1/select/report/${reportid}?select=${select}`
      const { data } = await axios({
        method: 'get',
        url,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      data.select = select
      dispatch({ type: REPORT_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: REPORT_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getReportsByIds =
  (reportids, select) => async (dispatch, getState) => {
    try {
      dispatch({ type: REPORTS_IDS_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/select/reports/${reportids.join(',')}?select=${select}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      data.select = select
      dispatch({ type: REPORTS_IDS_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: REPORTS_IDS_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getStates = () => async (dispatch, getState) => {
  try {
    dispatch({ type: STATES_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/states`,

      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: STATES_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: STATES_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCountries = () => async (dispatch, getState) => {
  try {
    dispatch({ type: COUNTRIES_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/countries`,

      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: COUNTRIES_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: COUNTRIES_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
