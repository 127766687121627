import React, { useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useDispatch } from 'react-redux'

const Message = ({ variant, dismissible, type, children }) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(true)
  const closeHandler = () => {
    if (type) {
      dispatch({ type })
    }
    setShow(false)
  }
  return (
    <>
      {show && (
        <Alert
          variant={variant}
          dismissible={dismissible}
          onClose={(e) => closeHandler()}
        >
          {children}
        </Alert>
      )}
    </>
  )
}

Message.defaultProps = {
  variant: 'info',
  dismissible: false,
}

export default Message
