import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import {
  userLoginReducer,
  userPasswordResetRequestReducer,
  userSwitchReducer,
  userToken2Reducer,
  userUpdatePasswordReducer,
  helpdeskSubmitReducer,
  captchaGetReducer,
} from './reducers/userReducer'
import { pppTableStateReducer } from './reducers/webstoreReducer'
import {
  formByIdReducer,
  reportByIdReducer,
  reportsByIdsReducer,
  statesSelectReducer,
  countriesSelectReducer,
  webstoreFormsReducer,
} from './reducers/formReducer'
import {
  getEBookSSOReducer,
  getWSEBookUserReducer,
  getSWLoginReducer,
} from './reducers/solidWorksReducer'

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userSwitch: userSwitchReducer,
  formById: formByIdReducer,
  webstoreForms: webstoreFormsReducer,
  reportById: reportByIdReducer,
  reportsByIds: reportsByIdsReducer,
  userToken2: userToken2Reducer,
  pppTableState: pppTableStateReducer,
  statesSelect: statesSelectReducer,
  countriesSelect: countriesSelectReducer,
  helpdeskSubmit: helpdeskSubmitReducer,
  getWSEBookUser: getWSEBookUserReducer,
  getEBookSSO: getEBookSSOReducer,
  swLogin: getSWLoginReducer,
  captchaGet: captchaGetReducer,
  userResetRequest: userPasswordResetRequestReducer,
  userUpdatePassword: userUpdatePasswordReducer,
})

let userInfoFromStorage = localStorage.getItem('userInfo')
  ? JSON.parse(localStorage.getItem('userInfo'))
  : null

if (userInfoFromStorage && userInfoFromStorage.start) {
  // not to use userInfo if the session is more than 20 hours
  if (Date.now() - userInfoFromStorage.start > 20 * 60 * 60 * 1000) {
    userInfoFromStorage = null
  }
}

const initialState = {
  userLogin: {
    userInfo: userInfoFromStorage,
  },
}

const middleware = [thunk]

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
)

export default store
