import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import {
  Row,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
  Button,
} from 'react-bootstrap'
import sortArray from 'sort-array'
import ReactPaginate from 'react-paginate'
import '../screens/form.css'
import Moment from 'moment'
import format from 'format-number'
import DeleteButton from './DeleteButton'
import ErrorButton from '../components/ErrorButton'
import { PPPTABLE_EDITMODE_RESET } from '../constants/webstoreConstants'

const basename = (path) => {
  return path.replace(/.*\//, '')
}

const PPPTable = ({
  history,
  report,
  rowsPerPage,
  maxGroupBy,
  dateFormat,
  sumByGroup,
  showTitle,
  onLink,
  onDelete,
  onDeleteState,
  onDeleteReset,
  onCreateState,
  onCreateReset,
  AddNewRow,
  EditRow,
  onUpdateState,
  onUpdateReset,
  onEditModeState,
  onFormSubmit,
  editClass,
  editCaption,
  onCopy,
  onPassword,
  copyClass,
  copyCaption,
  passwordClass,
  passwordCaption,
}) => {
  const [rows, setRows] = useState()
  const [cols, setCols] = useState()
  const [sortField, setSortField] = useState()
  const [sortIndex, setSortIndex] = useState()
  const [editIndex, setEditIndex] = useState()
  const [sort, setSort] = useState(false)
  const [ungroup, setUngroup] = useState(false)
  const [search, setSearch] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [itemOffset, setItemOffset] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(rowsPerPage)
  const [result, setResult] = useState() // search result
  const [resultChange, setResultChange] = useState()
  const [beginPage, setBeginPage] = useState(1)
  const [groupBy, setGroupBy] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [modal, setModal] = useState()
  const [deleteRow, setDeleteRow] = useState()
  const [editMode, setEditMode] = useState(false)
  const bottom = useRef()
  const squareColors = [
    'crimson',
    'navy',
    'darkgreen',
    'darkmagenta',
    'goldenrod',
  ]
  const dispatch = useDispatch()
  const { loading, error, success, _id } = onDeleteState
  const { success: successCreate, newRow } = onCreateState
  const { success: successUpdate, newRow: updatedRow } = onUpdateState
  const { onEditMode } = onEditModeState
  useEffect(() => {
    if (groupBy === 0 && AddNewRow && !editMode && editMode !== onEditMode) {
      scrollToBottom()
    }
    if (report && !result) {
      setResult(report.rows)
      let groupby = 0
      report.columns.map((c) => {
        if (c.groupby && c.groupby > 0) {
          groupby++
        }
        return <></>
      })
      setGroupBy(groupby)
    }
    if (editMode !== onEditMode) {
      setEditMode(onEditMode)
      if (!onEditMode) {
        setEditIndex(0)
      }
    }
    if (result && !rows) {
      //console.log('step 1', rows)
      //setRows(report.rows)
      // when first loaded
      setPageCount(Math.ceil(result.length / itemsPerPage))
      const endOffset = itemOffset + itemsPerPage
      setRows(result.slice(itemOffset, endOffset))
    }
    if (result && rows && itemOffset >= 0) {
      //console.log(rows[0], report.rows[itemOffset * itemsPerPage])
      // when page change
      //console.log('step 2 editMode', editMode, onEditMode, itemOffset)
      //setItemOffset(0)
      const endOffset = itemOffset + itemsPerPage
      setRows(result.slice(itemOffset, endOffset))
      setBeginPage(itemOffset + 1)
      setCurrentPage(itemOffset)
      setItemOffset()
    }
    if (!cols) {
      setCols(report.columns)
    }
    if (resultChange) {
      //console.log('step 3', !editMode, !onEditMode, !editIndex, itemOffset)
      if (!editMode || !onEditMode || !editIndex) {
        setPageCount(Math.ceil(result.length / itemsPerPage))
        setItemOffset(currentPage)
      }
      // const endOffset = itemOffset + itemsPerPage
      // setRows(result.slice(itemOffset, endOffset))
      setResultChange(false)
    }
    if (search) {
      let words = search
        .toLowerCase()
        .split(/\+/)
        .filter((i) => i)
      words = words.filter((c, index) => {
        return words.indexOf(c) === index
      })

      var regex = new RegExp('\\b\\w*(?:' + words.join('|') + ')\\w*\\b', 'gi')

      const searchResult = report.rows.filter((c) =>
        JSON.stringify(Object.values(c)).toLowerCase().match(regex)
      )

      setResult(searchResult)
      /*
      setResult(
        report.rows.filter((c) =>
          JSON.stringify(Object.values(c)).toLowerCase().match(regex)
        )
      )*/
      setSearch()
      setCurrentPage(0)
      setResultChange(true)
    }
    if (sort) {
      setResult(
        sortArray(result, {
          by: sortField,
          order: cols[sortIndex].sort,
        })
      )
      setSort(false)
      setResultChange(true)
    }
    if (ungroup) {
      setResultChange(true)
      setUngroup(false)
    }
    if (success) {
      setResult(
        result.filter((x) => x._id !== (deleteRow ? deleteRow._id : _id))
      )
      setResultChange(true)
      setDeleteRow()
      dispatch({ type: onDeleteReset })
    }
    if (successCreate) {
      setResult([newRow, ...result])
      setResultChange(true)
      dispatch({ type: onCreateReset })
    }
    if (successUpdate) {
      setResult(
        result.map((r) => {
          return r._id === updatedRow._id ? updatedRow : r
        })
      )

      // 5/27 removed, not sure what will affect
      //if (editIndex) {

      dispatch({ type: onUpdateReset })
      //}

      setResultChange(true)
      setEditIndex()
    }
  }, [
    rows,
    report,
    sort,
    sortField,
    cols,
    sortIndex,
    search,
    itemOffset,
    itemsPerPage,
    result,
    resultChange,
    ungroup,
    dispatch,
    deleteRow,
    onDeleteReset,
    success,
    groupBy,
    successCreate,
    newRow,
    onCreateReset,
    AddNewRow,
    onUpdateReset,
    successUpdate,
    updatedRow,
    editIndex,
    editMode,
    onEditMode,
    currentPage,
    _id,
  ])
  const sortColumn = (e, field, index) => {
    //console.log(field, index, cols[index].sort)
    setSortField(field)
    setSortIndex(index)
    setCols(
      cols.map((c, i) => {
        return i !== index
          ? { ...c, sort: 'none' }
          : { ...c, sort: c.sort === 'asc' ? 'desc' : 'asc' }
      })
    )
    setRows()
    setSort(true)
  }
  const unGroup = (e, field, index) => {
    //console.log(field, index, cols[index].groupby)
    //setSortField(field)
    //setSortIndex(index)
    const currGroupBy = cols[index].groupby
    setCols(
      cols.map((c, i) => {
        return i === index
          ? { ...c, groupby: 0 }
          : c.groupby > currGroupBy
            ? { ...c, groupby: c.groupby - 1 }
            : c
      })
    )
    setRows()
    setUngroup(true)
    if (groupBy > 0) {
      setGroupBy(groupBy - 1)
    }
  }

  const unGroupAll = (e, bEdit) => {
    e.preventDefault()
    setCols(
      cols.map((c, i) => {
        return { ...c, groupby: 0 }
      })
    )
    if (!bEdit) {
      setRows()
    }
    setUngroup(true)
    setGroupBy(0)
  }

  const group = (e, field, index) => {
    //console.log(field, index, cols[index].groupby)
    //setSortField(field)
    //setSortIndex(index)
    setCols(
      cols.map((c, i) => {
        return i !== index ? c : { ...c, groupby: groupBy + 1 }
      })
    )
    setRows()
    setUngroup(true)
    if (groupBy <= maxGroupBy) {
      setGroupBy(groupBy + 1)
    }
    setEditIndex()
  }
  const submitSearch = (e) => {
    e.preventDefault()

    if (e.target.keyword === undefined || e.target.keyword.value === '') {
      setResult(report.rows)
      setRows(report.rows)
      setItemOffset(0)
    } else {
      //setItemsPerPage(result.length)
      setSearch(e.target.keyword.value)
      e.target.keyword.value = ''
    }
    setRows()
  }
  const setFieldType = (fieldType, value) => {
    let newValue
    switch ((fieldType + '').substring(0, 1)) {
      case 'N':
        newValue = value.toLocaleString()
        break
      case 'M':
        //newValue = value.toLocaleString(undefined, { maximumFractionDigits: 2 })
        newValue = value ? format({ padRight: 2 })(value.toFixed(2)) : 0
        break
      case 'D':
        newValue = Moment(value).isValid()
          ? Moment(value).format(dateFormat)
          : 'N/A'
        break
      case 'I':
        newValue = <img src={value} alt={basename(value)}></img>
        break
      case 'B':
        newValue = value ? 'Yes' : 'No'
        break
      default:
        newValue = value
        break
    }
    return newValue
  }
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % result.length
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    )
    setItemOffset(newOffset)
  }
  const changeItemsPerPage = (e) => {
    if (e.target.value === 'all rows') {
      setItemsPerPage(result.length)
    } else {
      setItemsPerPage(Number(e.target.value))
    }
    setItemOffset(0)
    setRows()
  }
  const cellClick = (e, link, row) => {
    let newLink = link
    for (var i = 0; i < row.length; i++) {
      newLink = newLink.replace(
        `%VALUE${i}%`,
        ('' + row[i]).toString().replace(/"/g, "'")
      )
    }

    if (newLink.toLowerCase().substring(0, 4) === 'http') {
      window.open(newLink, '_blank')
    } else if (newLink.toLowerCase().substring(0, 6) === 'track:') {
      try {
        const track = JSON.parse(
          newLink.substring(6).split('\r\n').join('\\n').split('\t').join(' ')
        )

        newLink = track.url.replace('%trackingnumber%', track.trackingnumber)

        window.open(newLink, '_blank')
      } catch (error) {
        console.log(error)
      }
    } else if (newLink.toLowerCase().substring(0, 6) === 'modal:') {
      try {
        const modal = JSON.parse(
          newLink.substring(6).split('\r\n').join('\\n').split('\t').join(' ')
        )

        setModal(modal)
        setShowModal(true)
      } catch (error) {
        console.log(error)
      }
    } else {
      if (onLink) {
        if (Array.isArray(onLink)) {
          // link format: 1|%VALUE1%|%VALUE4%
          // first value is the function number in an array passed onto onLink
          const linkFunc = newLink.split(/\|/)
          const args = linkFunc.splice(1)

          onLink[Number(linkFunc[0])](args)
        } else {
          onLink(newLink)
        }
      } else {
        history.push(newLink)
      }
    }
  }
  const GetSum = ({ r, c }) => {
    if (cols[c].fieldtype === 'N' || cols[c].fieldtype === 'M') {
      let selectRows
      if (r === -1) {
        selectRows = rows
      } else {
        selectRows = rows.filter(
          (x) =>
            cols.filter((y) => y.groupby > 0 && rows[r][y.field] === x[y.field])
              .length === cols.filter((y) => y.groupby > 0).length
        )
      }
      const field = selectRows.map((r) => {
        return r[cols[c].field]
      })
      return (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-release`}>
              <strong>
                {(r === -1 ? 'Grand Total of ' : 'Sum of ') + cols[c].label}
              </strong>
            </Tooltip>
          }
        >
          <span>
            {setFieldType(
              cols[c].fieldtype,
              field.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                //        previousValue[cols[c].field] + currentValue[cols[c].field],
                0
              )
            )}
          </span>
        </OverlayTrigger>
      )
    }
    return <></>
  }
  const isGroupByChange = (i) => {
    const fields = cols.filter(
      (c) =>
        c.groupby > 0 &&
        (i === rows.length - 1 || rows[i][c.field] !== rows[i + 1][c.field])
    )
    if (fields.length > 0) {
      return true
    }
    return false
  }
  const handleClose = () => setShowModal(false)
  const deleteClick = (r) => {
    setDeleteRow(r)
    onDelete(r)
  }
  const copyClick = (r) => {
    onCopy(r)
  }
  const passwordClick = (r) => {
    onPassword(r)
  }
  const scrollToBottom = () => {
    //bottom.current.scrollIntoView({ block: 'end' })
  }
  return (
    <>
      <Row>
        <Col>{showTitle && report.ReportName}</Col>
      </Row>
      <Row>
        <Col md={6}>
          <select
            id='itemsPerPage'
            className='form-control form-control-sm'
            defaultValue={rowsPerPage}
            onChange={(e) => changeItemsPerPage(e)}
            style={{ width: '100px', float: 'left' }}
          >
            <option>10</option>
            <option>25</option>
            <option>50</option>
            <option>100</option>
            <option>250</option>
            <option>all rows</option>
          </select>
        </Col>
        <Col className='text-right'>
          <Form onSubmit={(e) => submitSearch(e)}>
          <Button size='sm' variant='info' onClick={e => submitSearch(e)}><i className="fas fa-undo"></i></Button>
            <Form.Control
              type='text'
              id='keyword'
              size='sm'
              placeholder='search, use + for OR operator'
            ></Form.Control>
          </Form>
        </Col>
      </Row>
      <Form onSubmit={(e) => onFormSubmit(e)}>
        <div className='table-responsive div-ppptable'>
          <table className='table-sm table table-striped table-bordered table-hover'>
            <thead>
              <tr>
                {cols &&
                  cols.map((c, i) => {
                    return (
                      !c.groupby &&
                      !c.hidden && (
                        <th key={i}>
                          <div className='ppptable-row'>
                            <div className='ppptable-col'>{c.label}</div>
                            {groupBy < maxGroupBy && (
                              <div
                                className='ppptable-col text-right'
                                onClick={(e) => group(e, c.field, i)}
                              >
                                <i hidden className='far fa-object-group'></i>
                              </div>
                            )}
                            <div
                              className='ppptable-col text-right'
                              onClick={(e) => sortColumn(e, c.field, i)}
                            >
                              <i
                                hidden
                                className={`fas ${c.sort === 'none'
                                  ? 'fa-sort'
                                  : c.sort === 'desc'
                                    ? 'fa-sort-up'
                                    : 'fa-sort-down'
                                  }`}
                              ></i>
                            </div>
                          </div>
                        </th>
                      )
                    )
                  })}
              </tr>
            </thead>
            <tbody>
              {rows &&
                rows.map((r, i) => (
                  <React.Fragment key={i}>
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9]
                      .slice(0, maxGroupBy)
                      .map((g) => {
                        return cols.map((c, j) => {
                          return (
                            c.groupby === g &&
                            (i === 0 ||
                              r[c.field] !== rows[i - 1][c.field]) && (
                              <tr key={i} className='groupby'>
                                <th colSpan={cols.length}>
                                  <Row>
                                    <Col sm={6} md={8} lg={10}>
                                      <span
                                        style={{
                                          color: squareColors[g % 5],
                                          paddingLeft: (g - 1) * 20 + 'px',
                                        }}
                                      >
                                        <i className='fas fa-square'></i>
                                      </span>{' '}
                                      {c.label}:{' '}
                                      {setFieldType(c.fieldtype, r[c.field])}{' '}
                                    </Col>
                                    <Col
                                      md={1}
                                      sm={1}
                                      lg={1}
                                      className='text-right'
                                      hidden
                                      onClick={(e) => unGroup(e, c.field, j)}
                                    >
                                      <i className='far fa-object-ungroup'></i>
                                    </Col>
                                    <Col
                                      md={1}
                                      sm={1}
                                      lg={1}
                                      className='text-right'
                                      onClick={(e) => sortColumn(e, c.field, j)}
                                    >
                                      <i
                                        className={`fas ${c.sort === 'none'
                                          ? 'fa-sort'
                                          : c.sort === 'desc'
                                            ? 'fa-sort-up'
                                            : 'fa-sort-down'
                                          }`}
                                      ></i>
                                    </Col>
                                  </Row>
                                </th>
                              </tr>
                            )
                          )
                        })
                      })}
                    {editIndex &&
                      editIndex === r._id &&
                      EditRow &&
                      editMode &&
                      groupBy === 0 ? (
                      <EditRow row={r}></EditRow>
                    ) : (
                      <tr>
                        {cols.map((c, j) => {
                          return (
                            !c.groupby &&
                            !c.hidden &&
                            (c.link ? (
                              <td
                                key={j}
                                className={
                                  c.fieldtype === 'N' || c.fieldtype === 'M'
                                    ? 'text-right'
                                    : 'text-left'
                                }
                              >
                                {c.link.includes('checkbox') && (
                                  <input
                                    type='checkbox'
                                    className='form-control form-control-sm'
                                    id={'checkbox-' + i}
                                    defaultValue={r._id}
                                  ></input>
                                )}
                                {c.link.includes('edit') &&
                                  EditRow &&
                                  !editIndex && (
                                    <OverlayTrigger
                                      placement='top'
                                      overlay={
                                        <Tooltip id={`tooltip-release`}>
                                          <strong>Edit</strong>
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        variant='info'
                                        size='sm'
                                        onClick={(e) => {
                                          setEditIndex(r._id)
                                          setEditMode(true)
                                          dispatch({
                                            type: 'PPPTABLE_EDITMODE_SET',
                                          })
                                          unGroupAll(e, true)
                                        }}
                                      >
                                        <i className={editClass}></i>
                                      </Button>
                                    </OverlayTrigger>
                                  )}
                                {c.link.includes('password') && onPassword && (
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id={`tooltip-release`}>
                                        <strong>{passwordCaption}</strong>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant='success'
                                      size='sm'
                                      onClick={(e) => {
                                        passwordClick(r)
                                      }}
                                    >
                                      <i className={passwordClass}></i>
                                    </Button>
                                  </OverlayTrigger>
                                )}
                                {c.link.includes('copy') && onCopy && (
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={
                                      <Tooltip id={`tooltip-release`}>
                                        <strong>{copyCaption}</strong>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant='success'
                                      size='sm'
                                      onClick={(e) => {
                                        copyClick(r)
                                      }}
                                    >
                                      <i className={copyClass}></i>
                                    </Button>
                                  </OverlayTrigger>
                                )}
                                {c.link.includes('delete') && onDelete && (
                                  <>
                                    <DeleteButton
                                      onclick={(e) => {
                                        deleteClick(r)
                                      }}
                                      size='sm'
                                    >
                                      {c.link.length > 27 &&
                                        c.link !== 'deleteedit'
                                        ? c.link.substring(7)
                                        : ''}
                                    </DeleteButton>
                                    {deleteRow && deleteRow._id === r._id && (
                                      <ErrorButton
                                        error={error}
                                        loading={loading}
                                        type={onDeleteReset}
                                      ></ErrorButton>
                                    )}
                                  </>
                                )}
                                {!c.link.includes('edit') &&
                                  !c.link.includes('delete') && (
                                    <Button
                                      variant='link'
                                      onClick={(e) =>
                                        cellClick(e, c.link, Object.values(r))
                                      }
                                    >
                                      {setFieldType(c.fieldtype, r[c.field])}
                                    </Button>
                                  )}
                              </td>
                            ) : (
                              <td
                                key={j}
                                className={
                                  c.fieldtype === 'N' || c.fieldtype === 'M'
                                    ? 'text-right'
                                    : 'text-left'
                                }
                              >
                                {setFieldType(c.fieldtype, r[c.field])}
                              </td>
                            ))
                          )
                        })}
                      </tr>
                    )}
                    {i >= 0 && isGroupByChange(i) && sumByGroup && (
                      <tr className='total'>
                        {cols.map((c, j) => {
                          return (
                            !c.groupby &&
                            !c.hidden && (
                              <td key={j} className='text-right'>
                                <GetSum r={i} c={j}></GetSum>
                              </td>
                            )
                          )
                        })}
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              {sumByGroup && cols && rows && (
                <tr className='grandtotal'>
                  {cols.map((c, j) => {
                    return (
                      !c.groupby &&
                      !c.hidden && (
                        <td key={j} className='text-right'>
                          <GetSum r={-1} c={j}></GetSum>
                        </td>
                      )
                    )
                  })}
                </tr>
              )}
            </tbody>
            {AddNewRow && (
              <tfoot ref={bottom}>
                {groupBy === 0 ? (
                  <AddNewRow></AddNewRow>
                ) : (
                  <tr>
                    <td colSpan={cols ? cols.length : 16}>
                      <Button
                        variant='success'
                        size='sm'
                        onClick={(e) => {
                          unGroupAll(e)
                          dispatch({ type: PPPTABLE_EDITMODE_RESET })
                        }}
                      >
                        <i className='fa fa-plus'></i>
                      </Button>
                    </td>
                  </tr>
                )}
              </tfoot>
            )}
          </table>
        </div>
      </Form>
      {rows && (
        <>
          <Row>
            <Col>
              Showing {beginPage} to{' '}
              {beginPage + itemsPerPage - 1 > result.length
                ? result.length
                : beginPage + itemsPerPage - 1}{' '}
              of {result ? result.length : 0}
            </Col>
            <div className='pagination'>
              <ReactPaginate
                breakLabel='...'
                nextLabel='next >'
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel='< previous'
                renderOnZeroPageCount={null}
              />
            </div>
            <Col></Col>
          </Row>
          {modal && (
            <Modal
              className='editModal'
              show={showModal}
              onHide={handleClose}
              animation={false}
            >
              <Modal.Header closeButton>{modal.modalTitle}</Modal.Header>
              <Modal.Body>
                <pre style={{ fontSize: '0.8em' }}>{modal.modalBody}</pre>
              </Modal.Body>
            </Modal>
          )}
        </>
      )}
    </>
  )
}

PPPTable.defaultProps = {
  rowsPerPage: 50,
  maxGroupBy: 3,
  dateFormat: 'MM/DD/YYYY',
  sumByGroup: true,
  showTitle: false,
  onDeleteState: {},
  onCreateState: {},
  onUpdateState: {},
  onEditModeState: { onEditMode: true },
  editClass: 'fa fa-edit',
  editCaption: 'Edi',
  copyClass: 'fa fa-copy',
  copyCaption: 'Copy',
  passwordClass: 'fas fa-key',
  passwordCaption: 'Reset Password',
  onFormSubmit: (e) => {
    e.preventDefault()
  },
}
export default PPPTable
