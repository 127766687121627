import axios from 'axios'
import {
  EBOOK_LIST_CREATE_SUCCESS,
  EBOOK_LIST_CREATE_REQUEST,
  EBOOK_LIST_CREATE_FAIL,
  EBOOK_LIST_CREATE_RESET,
  EBOOK_SSO_CREATE_SUCCESS,
  EBOOK_SSO_CREATE_REQUEST,
  EBOOK_SSO_CREATE_FAIL,
  EBOOK_SSO_CREATE_RESET,
  SW_LOGIN_SUCCESS,
  SW_LOGIN_REQUEST,
  SW_LOGIN_FAIL,
  SW_LOGIN_RESET,
} from '../constants/solidworksConstants'
import { getAPIGATEWAY } from '../utils/apiGateway'

export const getSWLogin =
  (login, password, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: SW_LOGIN_REQUEST })
      const { data } = await axios({
        method: 'post',
        data: {
          loginname: login,
          password: password,
        },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME
        )}/api/v1/sw/user/login`,
        headers: {
          'Content-Type': 'application/json',
        },
      })
      dispatch({ type: SW_LOGIN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: SW_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getWSEBookUser = () => async (dispatch, getState) => {
  try {
    dispatch({ type: EBOOK_LIST_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/sw/user/get`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: EBOOK_LIST_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EBOOK_LIST_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getEBookSSO = (docid) => async (dispatch, getState) => {
  try {
    dispatch({ type: EBOOK_SSO_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/sw/user/get/${docid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: EBOOK_SSO_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: EBOOK_SSO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
