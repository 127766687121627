import {
  USER_TOKEN_REQUEST,
  USER_TOKEN_SUCCESS,
  USER_TOKEN_FAIL,
  USER_TOKEN_RESET,
  USER_TOKEN2_REQUEST,
  USER_TOKEN2_SUCCESS,
  USER_TOKEN2_FAIL,
  USER_TOKEN2_RESET,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_SWITCH_REQUEST,
  USER_SWITCH_SUCCESS,
  USER_SWITCH_FAIL,
  USER_SWITCH_RESET,
  USER_RESETREQUEST_REQUEST,
  USER_RESETREQUEST_SUCCESS,
  USER_RESETREQUEST_RESET,
  USER_RESETREQUEST_FAIL,
  USER_UPDATEPASS_REQUEST,
  USER_UPDATEPASS_SUCCESS,
  USER_UPDATEPASS_RESET,
  USER_UPDATEPASS_FAIL,
  CAPTCHA_GET_REQUEST,
  CAPTCHA_GET_SUCCESS,
  CAPTCHA_GET_FAIL,
  CAPTCHA_GET_RESET,
  HELPDESK_SUBMIT_REQUEST,
  HELPDESK_SUBMIT_SUCCESS,
  HELPDESK_SUBMIT_FAIL,
  HELPDESK_SUBMIT_RESET,
} from '../constants/userConstants'

export const userLoginReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case USER_TOKEN_REQUEST:
      return { loading: true }
    case USER_TOKEN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_TOKEN_RESET:
      return {}
    case USER_TOKEN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userToken2Reducer = (state = {}, action) => {
  switch (action.type) {
    case USER_TOKEN2_REQUEST:
      return { loading: true }
    case USER_TOKEN2_SUCCESS:
      return { loading: false, token2: action.payload }
    case USER_TOKEN2_RESET:
      return {}
    case USER_TOKEN2_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userSwitchReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case USER_SWITCH_REQUEST:
      return { loading: true }
    case USER_SWITCH_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_SWITCH_RESET:
      return {}
    case USER_SWITCH_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userScreenLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return { loading: true }
    case USER_LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload }
    case USER_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    case USER_LOGOUT:
      return {}
    default:
      return state
  }
}

export const userPasswordResetRequestReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_RESETREQUEST_REQUEST:
      return { loading: true }
    case USER_RESETREQUEST_SUCCESS:
      return { loading: false, success: true, requestInfo: action.payload }
    case USER_RESETREQUEST_RESET:
      return { loading: false, error: action.payload }
    case USER_RESETREQUEST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userUpdatePasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_UPDATEPASS_REQUEST:
      return { loading: true }
    case USER_UPDATEPASS_SUCCESS:
      return { loading: false, success: true, updateInfo: action.payload }
    case USER_UPDATEPASS_RESET:
      return { loading: false, error: action.payload }
    case USER_UPDATEPASS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const captchaGetReducer = (state = {}, action) => {
  switch (action.type) {
    case CAPTCHA_GET_REQUEST:
      return { loading: true }
    case CAPTCHA_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        captcha: action.payload,
      }
    case CAPTCHA_GET_FAIL:
      return { loading: false, error: action.payload }
    case CAPTCHA_GET_RESET:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const helpdeskSubmitReducer = (state = {}, action) => {
  switch (action.type) {
    case HELPDESK_SUBMIT_REQUEST:
      return { loading: true, success: false }
    case HELPDESK_SUBMIT_SUCCESS:
      return {
        loading: false,
        success: true,
        ticketNumber: action.payload.request.id,
      }
    case HELPDESK_SUBMIT_FAIL:
      return { loading: false, error: action.payload }
    case HELPDESK_SUBMIT_RESET:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

