import React from 'react'
import { Container, Row } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Row
          style={{
            width: 'calc(100% - 240px)',
            float: 'right',
            position: 'relative',
          }}
        >
          Copyright&copy; eSource
        </Row>
      </Container>
    </footer>
  )
}

export default Footer
