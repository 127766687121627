import {
  FORM_GET_REQUEST,
  FORM_GET_SUCCESS,
  FORM_RESET_SUCCESS,
  FORM_GET_FAIL,
  FORM_GET_RESET,
  REPORT_GET_REQUEST,
  REPORT_GET_SUCCESS,
  REPORT_GET_FAIL,
  REPORT_GET_RESET,
  QFORM_GET_REQUEST,
  QFORM_GET_SUCCESS,
  QFORM_GET_FAIL,
  QFORM_GET_RESET,
  QFORMS_GET_REQUEST,
  QFORMS_GET_SUCCESS,
  QFORMS_GET_RESET,
  QFORMS_GET_FAIL,
  REPORTS_IDS_GET_REQUEST,
  REPORTS_IDS_GET_SUCCESS,
  REPORTS_IDS_GET_RESET,
  REPORTS_IDS_GET_FAIL,
  STATES_GET_REQUEST,
  STATES_GET_SUCCESS,
  STATES_GET_RESET,
  STATES_GET_FAIL,
  COUNTRIES_GET_REQUEST,
  COUNTRIES_GET_SUCCESS,
  COUNTRIES_GET_RESET,
  COUNTRIES_GET_FAIL,
  WEBFORM_GET_REQUEST,
  WEBFORM_GET_SUCCESS,
  WEBFORM_RESET_SUCCESS,
  WEBFORM_GET_RESET,
  WEBFORM_GET_FAIL,
  FORM_SET_LOCK,
  FORM_SET_UNLOCK,
  WEBFORM_REFRESH_SUCCESS,
  WEBFORM_REFRESH_REQUEST,
  WEBFORM_RESET_PAYMENT,
} from '../constants/formConstants'

export const formByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case FORM_GET_REQUEST:
      return { loading: true }
    case FORM_GET_SUCCESS:
      return { loading: false, form: action.payload, success: true }
    case FORM_RESET_SUCCESS:
      return { ...state, success: false }
    case FORM_GET_RESET:
      return {}
    case FORM_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const webstoreFormsReducer = (state = {}, action) => {
  switch (action.type) {
    case WEBFORM_GET_REQUEST:
      return { loading: true }
    case WEBFORM_REFRESH_REQUEST:
      return {
        loading: true,
        address: state.address,
      }
    case WEBFORM_GET_SUCCESS:
      return {
        loading: false,
        address: action.payload.address,
        payment: action.payload.payment,
        paymentIntl: action.payload.paymentIntl,
        success: true,
      }
    case WEBFORM_REFRESH_SUCCESS:
      return {
        ...state,
        payment: action.payload.payment,
        paymentIntl: action.payload.paymentIntl,
        loading: false,
        success: true,
      }
    case WEBFORM_RESET_PAYMENT:
      return {
        address: state.address,
      }
    case WEBFORM_RESET_SUCCESS:
      return { ...state, success: false }
    case WEBFORM_GET_RESET:
      return {}
    case WEBFORM_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const quickOrderFormByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case QFORM_GET_REQUEST:
      return { loading: true }
    case QFORM_GET_SUCCESS:
      return { loading: false, schema: action.payload, success: true }
    case QFORM_GET_RESET:
      return {}
    case QFORM_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const quickOrderFormsReducer = (state = {}, action) => {
  switch (action.type) {
    case QFORMS_GET_REQUEST:
      return { loading: true }
    case QFORMS_GET_SUCCESS:
      return { loading: false, schemas: action.payload, success: true }
    case QFORMS_GET_RESET:
      return {}
    case QFORMS_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const reportByIdReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORT_GET_REQUEST:
      return { loading: true }
    case REPORT_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        report: action.payload,
        select: action.payload.select,
      }
    case REPORT_GET_RESET:
      return {}
    case REPORT_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const reportsByIdsReducer = (state = {}, action) => {
  switch (action.type) {
    case REPORTS_IDS_GET_REQUEST:
      return { loading: true }
    case REPORTS_IDS_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        reports: action.payload,
        select: action.payload.select,
      }
    case REPORTS_IDS_GET_RESET:
      return {}
    case REPORTS_IDS_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const statesSelectReducer = (state = {}, action) => {
  switch (action.type) {
    case STATES_GET_REQUEST:
      return { loading: true }
    case STATES_GET_SUCCESS:
      return { loading: false, states: action.payload, success: true }
    case STATES_GET_RESET:
      return {}
    case STATES_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const countriesSelectReducer = (state = {}, action) => {
  switch (action.type) {
    case COUNTRIES_GET_REQUEST:
      return { loading: true }
    case COUNTRIES_GET_SUCCESS:
      return { loading: false, countries: action.payload, success: true }
    case COUNTRIES_GET_RESET:
      return {}
    case COUNTRIES_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const formLockReducer = (state = {}, action) => {
  switch (action.type) {
    case FORM_SET_LOCK:
      return { lock: true }
    case FORM_SET_UNLOCK:
      return { lock: false }
    default:
      return state
  }
}

