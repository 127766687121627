import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//import { validateUserToken } from '../actions/userActions'
import './form.css'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import { getUserTokenFromToken2 } from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { USER_TOKEN_RESET } from '../constants/userConstants'
import { SW_LOGIN_RESET } from '../constants/solidworksConstants'
import queryString from 'query-string'

const ConfirmScreen = ({ history, location }) => {
  const params = queryString.parse(location.search)
  const dbname = params.dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
  const dispatch = useDispatch()
  const [t, setT] = useState(false)
  const [localError, setError] = useState(false)
  const confNum = useRef()
  //userLogin and userInfo used in header to set permissions and db
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin
  //used userPreLogin to obtain user account without changing navbar
  const swLogin = useSelector((state) => state.swLogin)
  const { loading: loadingToken2, token2 } = swLogin

  useEffect(() => {
    if (userInfo && !t) {
      dispatch({ type: SW_LOGIN_RESET })
      history.push('/?token=' + userInfo.token + '&dbname=' + userInfo.dbname)
      setT(true)
    }
    if (token2 && error && !t) {
      dispatch({ type: SW_LOGIN_RESET })
      dispatch({ type: USER_TOKEN_RESET })
      history.push('/user/login')
      setT(true)
    }
  }, [dispatch, history, userInfo, error, token2, t])

  const confirmationHandler = (e) => {
    e.preventDefault()
    if (token2) {
      dispatch(
        getUserTokenFromToken2(token2.token2, confNum.current.value, dbname)
      )
    } else {
      setError(true)
      //history.push('/user/login')
    }
  }

  return (
    <Container>
      {(localError || error) && (
        <Message variant='danger' dismissible={true}>
          {'Confirmation code invalid, please try again.'}
        </Message>
      )}
      <h3>Confirm</h3>
      <hr size='1' />
      {loading || loadingToken2 ? (
        <Loader></Loader>
      ) : (
        <>
          <br />
          <Form onSubmit={(e) => confirmationHandler(e)}>
            <Row className='mb-2'>
              <Col md={4}>
                Below, please enter the confirmation number that has just been
                sent to the email linked with this account.
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={2}>
                <Form.Control
                  size='sm'
                  ref={confNum}
                  type='number'
                  required={true}
                  minLength={5}
                  style={{ width: '100%' }}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={3}>
                <Button type='submit'>Confirm</Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  )
}

export default ConfirmScreen
