export const ACCOUNT_LIST_REQUEST = 'ACCOUNT_LIST_REQUEST'
export const ACCOUNT_LIST_SUCCESS = 'ACCOUNT_LIST_SUCCESS'
export const ACCOUNT_LIST_FAIL = 'ACCOUNT_LIST_FAIL'
export const ACCOUNT_LIST_RESET = 'ACCOUNT_LIST_RESET'

export const ACCOUNT_CONTRACT_LIST_REQUEST = 'ACCOUNT_CONTRACT_LIST_REQUEST'
export const ACCOUNT_CONTRACT_LIST_SUCCESS = 'ACCOUNT_CONTRACT_LIST_SUCCESS'
export const ACCOUNT_CONTRACT_LIST_FAIL = 'ACCOUNT_CONTRACT_LIST_FAIL'
export const ACCOUNT_CONTRACT_LIST_RESET = 'ACCOUNT_CONTRACT_LIST_RESET'

export const LOCATION_LIST_REQUEST = 'LOCATION_LIST_REQUEST'
export const LOCATION_LIST_SUCCESS = 'LOCATION_LIST_SUCCESS'
export const LOCATION_LIST_FAIL = 'LOCATION_LIST_FAIL'
export const LOCATION_LIST_RESET = 'LOCATION_LIST_RESET'

export const POD_CONTRACT_LIST_REQUEST = 'POD_CONTRACT_LIST_REQUEST'
export const POD_CONTRACT_LIST_SUCCESS = 'POD_CONTRACT_LIST_SUCCESS'
export const POD_CONTRACT_LIST_FAIL = 'POD_CONTRACT_LIST_FAIL'
export const POD_CONTRACT_LIST_RESET = 'POD_CONTRACT_LIST_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const ACCINT_LIST_REQUEST = 'ACCINT_LIST_REQUEST'
export const ACCINT_LIST_SUCCESS = 'ACCINT_LIST_SUCCESS'
export const ACCINT_LIST_FAIL = 'ACCINT_LIST_FAIL'
export const ACCINT_LIST_RESET = 'ACCINT_LIST_RESET'

export const WEBSTORE_LIST_REQUEST = 'WEBSTORE_LIST_REQUEST'
export const WEBSTORE_LIST_SUCCESS = 'WEBSTORE_LIST_SUCCESS'
export const WEBSTORE_LIST_FAIL = 'WEBSTORE_LIST_FAIL'
export const WEBSTORE_LIST_RESET = 'WEBSTORE_LIST_RESET'

export const USERLOGINS_LIST_REQUEST = 'USERLOGINS_LIST_REQUEST'
export const USERLOGINS_LIST_SUCCESS = 'USERLOGINS_LIST_SUCCESS'
export const USERLOGINS_LIST_FAIL = 'USERLOGINS_LIST_FAIL'
export const USERLOGINS_LIST_RESET = 'USERLOGINS_LIST_RESET'

export const DEPARTMENT_LIST_SUCCESS = 'DEPARTMENT_LIST_SUCCESS'
export const DEPARTMENT_LIST_REQUEST = 'DEPARTMENT_LIST_REQUEST'
export const DEPARTMENT_LIST_FAIL = 'DEPARTMENT_LIST_FAIL'
export const DEPARTMENT_LIST_RESET = 'DEPARTMENT_LIST_RESET'
