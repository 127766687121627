import axios from 'axios'
import {
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_LIST_FAIL,
  ACCOUNT_CONTRACT_LIST_REQUEST,
  ACCOUNT_CONTRACT_LIST_SUCCESS,
  ACCOUNT_CONTRACT_LIST_FAIL,
  LOCATION_LIST_REQUEST,
  LOCATION_LIST_SUCCESS,
  LOCATION_LIST_FAIL,
  POD_CONTRACT_LIST_REQUEST,
  POD_CONTRACT_LIST_SUCCESS,
  POD_CONTRACT_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
  USER_LIST_FAIL,
  ACCINT_LIST_REQUEST,
  ACCINT_LIST_SUCCESS,
  ACCINT_LIST_FAIL,
  WEBSTORE_LIST_REQUEST,
  WEBSTORE_LIST_SUCCESS,
  WEBSTORE_LIST_FAIL,
  USERLOGINS_LIST_REQUEST,
  USERLOGINS_LIST_SUCCESS,
  USERLOGINS_LIST_FAIL,
  DEPARTMENT_LIST_SUCCESS,
  DEPARTMENT_LIST_FAIL,
  DEPARTMENT_LIST_REQUEST,
} from '../constants/accountConstants'
import { getAPIGATEWAY } from '../utils/apiGateway'

export const getAccountList = (select) => async (dispatch, getState) => {
  try {
    dispatch({ type: ACCOUNT_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/accounts?select=${
        select || ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ACCOUNT_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ACCOUNT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAccountAndInternalList =
  (select) => async (dispatch, getState) => {
    try {
      dispatch({ type: ACCINT_LIST_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/select/internalandaccounts?select=${select || ''}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: ACCINT_LIST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: ACCINT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getUserList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/users`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: USER_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAccountContractList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ACCOUNT_CONTRACT_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/fulpodcontract`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ACCOUNT_CONTRACT_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ACCOUNT_CONTRACT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLocationList =
  (virtual = 1) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: LOCATION_LIST_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/locations`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: LOCATION_LIST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: LOCATION_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getPODContractList =
  (podcontracts) => async (dispatch, getState) => {
    try {
      dispatch({ type: POD_CONTRACT_LIST_REQUEST })

      dispatch({ type: POD_CONTRACT_LIST_SUCCESS, payload: podcontracts })
    } catch (error) {
      dispatch({
        type: POD_CONTRACT_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getWebStoreList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: WEBSTORE_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/webstores`, // || '11111111-1111-1111-1111-111111111111'}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: WEBSTORE_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: WEBSTORE_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserLoginsList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: USERLOGINS_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/userlogins`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: USERLOGINS_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USERLOGINS_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getDepartmentList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: DEPARTMENT_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/system/maintenance/department`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEPARTMENT_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEPARTMENT_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
