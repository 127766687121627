import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import './form.css'
import { Form, Container, Row, Col, Button, Nav } from 'react-bootstrap'
import { getSWLogin } from '../actions/solidworksAction'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getCaptcha } from '../actions/userActions'
import { CAPTCHA_GET_RESET } from '../constants/userConstants'
import { SW_LOGIN_RESET } from '../constants/solidworksConstants'

const LoginScreen = ({ history, location }) => {
  const params = queryString.parse(location.search)
  const dbname = params.dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
  const dispatch = useDispatch()
  const [t, setT] = useState(false)
  const [errCount, setErrCount] = useState(1)
  const [errmsg, setErrMsg] = useState(false)
  const username = useRef()
  const password = useRef()
  const captchaResponse = useRef()
  //userLogin and userInfo used in header to set permissions and db
  const swLogin = useSelector((state) => state.swLogin)
  const { loading, error, token2 } = swLogin
  //const [captchaResponse, setCaptchaResponse] = useState('')

  const captchaGet = useSelector((state) => state.captchaGet)
  const [captcha, setCaptcha] = useState()
  const {
    captcha: remoteCaptcha,
    loading: captchaLoading,
    error: captchaError,
    success,
  } = captchaGet

  useEffect(() => {
    if (error) {
      //setCaptcha()
      //dispatch(getCaptcha())
      setErrMsg(true)
      dispatch({ type: SW_LOGIN_RESET })
    }
    if (success) {
      setCaptcha(remoteCaptcha)
      dispatch({ type: CAPTCHA_GET_RESET })
    }
    if (!captcha && !remoteCaptcha && !captchaLoading) {
      dispatch(getCaptcha())
    }
    if (token2 && !t) {
      setErrMsg(false)
      localStorage.clear()
      history.push('/user/confirm' + (params.dbname ? `?dbname=${dbname}` : ''))
      setT(true)
    }
    if (error || captchaError) {
      setErrCount(errCount + 1)
      if (errCount > 5) {
        history.push('/error/1009')
      }
    }
  }, [
    dispatch,
    history,
    token2,
    error,
    t,
    loading,
    dbname,
    params,
    captcha,
    captchaLoading,
    remoteCaptcha,
    success,
    captchaError,
    errCount,
  ])

  const loginHandler = (e) => {
    e.preventDefault()
    dispatch(
      getSWLogin(
        username.current.value,
        password.current.value,
        dbname
      )
    )
    //onsole.log(username.current.value)
  }
  return (
    <Container>
      <h3>Login</h3>
      <hr size='1' />
      {errmsg && (
        <Message variant='danger' dismissible={true} type='SW_LOGIN_RESET'>
          {'Username or Password is invalid. Please try again.'}
        </Message>
      )}
      {loading ? (
        <Loader></Loader>
      ) : (
        <>
        <p><a href="http://picpacplus.dsgraphics.com/swebook/faq.htm">Click here for FAQs</a>.</p>
          <Form onSubmit={(e) => loginHandler(e)}>
            <Row className='mb-2'>
              <Col md={1}>Email:</Col>
              <Col md={4}>
                <Form.Control
                  size='sm'
                  ref={username}
                  type='text'
                  required={true}
                  minLength={4}
                  style={{ width: '100%' }}
                ></Form.Control>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={1}>Password:</Col>
              <Col md={4}>
                <Form.Control
                  size='sm'
                  ref={password}
                  type='password'
                  required={true}
                  minLength={5}
                  style={{ width: '100%' }}
                ></Form.Control>
              </Col>
            </Row>

            <Row className='mb-2'>
              <Col md={1}></Col>
              <Col md={4}>
                <Button variant='info' type='submit'>
                  Submit
                </Button>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={1}></Col>
              <Col md={4}>
                <Nav
                  className='justify-content-center'
                  onSelect={(selectedKey) =>
                    history.push(`/user/${selectedKey}`)
                  }
                >
                  <Nav.Item>
                    <Nav.Link eventKey='forgotpassword'>
                      Forgot Password
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </Container>
  )
}

export default LoginScreen
