import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import './form.css'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import {
  getCaptcha,
  getPasswordResetConfirmation,
  updateUserPassword,
} from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  CAPTCHA_GET_RESET,
  USER_RESETREQUEST_RESET,
  USER_UPDATEPASS_RESET,
} from '../constants/userConstants'

const ForgotPasswordScreen = ({ history, location }) => {
  const dispatch = useDispatch()
  const params = queryString.parse(location.search)
  const dbname = params.dbname || 'afds2'
  const email = useRef()
  const confirm = useRef()
  const pass = useRef()
  const rePass = useRef()
  const [enterConfirm, setShowConfirm] = useState(false)
  const [errmsg, setErrMsg] = useState(false)
  const userResetRequest = useSelector((state) => state.userResetRequest)
  const {
    loading: loadingRequest,
    error: errorRequest,
    success: successRequest,
    requestInfo,
  } = userResetRequest
  const userUpdatePassword = useSelector((state) => state.userUpdatePassword)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updateInfo,
  } = userUpdatePassword
  const captchaResponse = useRef()
  const captchaGet = useSelector((state) => state.captchaGet)
  const [captcha, setCaptcha] = useState()
  const {
    captcha: remoteCaptcha,
    loading: captchaLoading,
    error: captchaError,
    success,
  } = captchaGet

  useEffect(() => {
    if (errorRequest) {
      setCaptcha()
      dispatch(getCaptcha())
      setErrMsg(true)
      dispatch({ type: USER_RESETREQUEST_RESET })
    }
    if (successRequest) {
      setErrMsg(false)
      setShowConfirm(true)
    }
    if (success) {
      setCaptcha(remoteCaptcha)
      dispatch({ type: CAPTCHA_GET_RESET })
    }
    if (!captcha && !remoteCaptcha && !captchaLoading) {
      dispatch(getCaptcha())
    }
    if (successUpdate) {
      setShowConfirm(false)
    }
    if (errorUpdate) {
    }
  }, [
    dispatch,
    history,
    loadingRequest,
    errorRequest,
    successRequest,
    requestInfo,
    loadingUpdate,
    errorUpdate,
    successUpdate,
    updateInfo,
    captcha,
    captchaLoading,
    remoteCaptcha,
    success,
  ])

  const emailHandler = (e) => {
    e.preventDefault()
    dispatch(
      getPasswordResetConfirmation(
        email.current.value,
        captcha.key,
        captchaResponse.current.value,
        dbname
      )
    )
  }

  const confirmHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateUserPassword(
        requestInfo.token2,
        confirm.current.value,
        pass.current.value,
        rePass.current.value,
        dbname
      )
    )
  }

  const doneHandler = (e) => {
    dispatch({ type: USER_RESETREQUEST_RESET })
    dispatch({ type: USER_UPDATEPASS_RESET })
    history.push('/')
  }

  return (
    <Container>
      <h3>Forgot Password</h3>
      <hr size='1' />
      {errmsg && (
        <Message
          variant='danger'
          dismissible={true}
          type='USER_RESETREQUEST_RESET'
        >
          {
            'Something went wrong. Please make sure the email entered is valid and try again.'
          }
        </Message>
      )}
      {loadingRequest ? (
        <Loader></Loader>
      ) : (
        !requestInfo && (
          <>
            <Row className='mb-2'>
              <Col md={5}>
                Please enter the email linked with your account below for a
                confirmation code to be sent to reset your password.
              </Col>
            </Row>
            <Form onSubmit={(e) => emailHandler(e)}>
              <Row className='mb-2'>
                <Col md={1}>Email:</Col>
                <Col md={4}>
                  <Form.Control
                    size='sm'
                    ref={email}
                    type='text'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Label>Captcha:</Form.Label>
                  <div
                    style={{ width: '72%', display: 'inline', float: 'right' }}
                  >
                    {captchaLoading ? (
                      <span>Loading captcha...</span>
                    ) : (
                      <>
                        {captchaError && (
                          <p>Error loading captcha. Please try again.</p>
                        )}
                        {captcha && (
                          <>
                            <img src={captcha.image} alt='Captcha' />{' '}
                            <Button
                              variant='success'
                              size='sm'
                              onClick={(e) => setCaptcha()}
                              style={{ padding: '0.4rem 0.8rem' }}
                            >
                              <i className='fa fa-sync-alt fa-2x'></i>
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Label></Form.Label>
                  <Form.Control
                    size='sm'
                    ref={captchaResponse}
                    type='number'
                    required={true}
                    minLength={6}
                    maxLength={8}
                    placeholder='enter the code shown above'
                    style={{ width: '72%' }}
                  />
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={1}>
                  <Button type='submit'>Submit</Button>
                </Col>
              </Row>
            </Form>
          </>
        )
      )}
      {errorUpdate && (
        <Message
          variant='danger'
          dismissible={true}
          type='USER_UPDATEPASS_RESET'
        >
          {errorUpdate || 'Something went wrong. Please try again.'}
        </Message>
      )}
      {loadingUpdate ? (
        <Loader></Loader>
      ) : (
        enterConfirm && (
          <>
            <Row className='mb-2'>
              <Col md={5}>
                Please enter the confirmation number that was sent to the email
                you entered along with a new password for your account.
              </Col>
            </Row>
            <Form onSubmit={(e) => confirmHandler(e)}>
              <Row className='mb-2'>
                <Col md={2}>Confirmation #:</Col>
                <Col md={3}>
                  <Form.Control
                    size='sm'
                    ref={confirm}
                    type='text'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={2}>New Password:</Col>
                <Col md={3}>
                  <Form.Control
                    size='sm'
                    ref={pass}
                    type='password'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={2}>Retype Password:</Col>
                <Col md={3}>
                  <Form.Control
                    size='sm'
                    ref={rePass}
                    type='password'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={1}>
                  <Button type='submit'>Submit</Button>
                </Col>
              </Row>
            </Form>
          </>
        )
      )}
      {updateInfo && (
        <>
          <Row className='mb-2'>
            <Col md={7}>
              <h4>
                Your password has successfully been changed! Please return to
                the login screen by clicking the button below.
              </h4>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col md={1}>
              <Button type='submit' onClick={doneHandler}>
                Done
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default ForgotPasswordScreen
