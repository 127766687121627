import React, { useEffect, useState } from 'react'
import { Collapse, Row, Col, Table, Button, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../../components/Loader'
import ErrorButton from '../../components/ErrorButton'
import { getUserList } from '../../actions/accountAction'
import { getMenuById, setMenuById } from '../../actions/webstoreAction'
import Checkbox from '../../components/Checkbox'
import '../form.css'
import {
  MENU_GET_RESET,
  MENU_SET_RESET,
} from '../../constants/webstoreConstants'
import { rights } from '../../utils/apiGateway'
import { logout } from '../../actions/userActions'
import { USER_LIST_RESET } from '../../constants/accountConstants'

const MenuScreen = ({ history }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState([])
  const [copyLogin, setCopy] = useState()
  const [users, setUsers] = useState()
  const [copyMode, setCopyMode] = useState()
  const [result, setResult] = useState([])
  const [errCount, setErrCount] = useState(1)
  const [loginId, setLoginId] = useState(0)
  const userList = useSelector((state) => state.userList)
  const { loading, error, success: successUserList, users: Users } = userList
  const menuGet = useSelector((state) => state.menuGet)
  const { loading: loadingGet, error: errorGet, menu, LoginName } = menuGet
  const menuSet = useSelector((state) => state.menuSet)
  const { loading: loadingSet, error: errorSet, success } = menuSet
  if (!rights('DBD5FCF6-D2E6-4BD5-99B1-A9E97C216F99')) {
    dispatch(logout())
  }

  useEffect(() => {
    if (!users && !loading && !successUserList) {
      dispatch(getUserList())
    }
    if (successUserList) {
      setUsers(Users)
      dispatch({ type: USER_LIST_RESET })
    }
    if (menu && LoginName) {
      if (copyMode === true) {
        setCopy(LoginName)
        setCopyMode(false)
      }
      setResult(menu)
      setIsCheck(menu.map((li) => (li.Selected ? li._id : null)))
      let init = []
      menu.map((li) => init.push(false))
      dispatch({ type: MENU_GET_RESET })
    }
    if (error) {
      setErrCount(errCount + 1)
      if (errCount > 30) {
        history.push('/error/1009')
      }
    }
  }, [
    userList,
    loading,
    dispatch,
    menu,
    error,
    errCount,
    users,
    Users,
    history,
    result,
    success,
    successUserList,
    LoginName,
    copyMode,
  ])

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll)
    setIsCheck(result.map((li) => li._id))
  }

  const handleClick = (e) => {
    const { value, checked } = e.target
    setIsCheck([...isCheck, value])
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== value))
    }
  }

  const selectUser = (e) => {
    setLoginId(e.target.value)
    dispatch(getMenuById(e.target.value))
  }

  /* const setMenu = (e) => {
    e.preventDefault()
    const ids = []
    for (let i = 0; i < result.length; i++) {
      if (e.target['_id_' + i].checked) {
        ids.push(e.target['_id_' + i].value)
      }
    }
    
    dispatch(setMenuById(ids, loginId))
  }

  const copyfrom = (e) => {
    e.preventDefault()
    if (e.target.copyfrom.value !== '11111111-1111-1111-1111-111111111111') {
      setCopyMode(true)
      dispatch(setMenuById(e.target.copyfrom.value))
    }
  } */

  const copyfrom = (e) => {
    e.preventDefault()
    const selectedUserId = e.target.copyfrom.value
    if (selectedUserId !== '11111111-1111-1111-1111-111111111111') {
      setCopyMode(true)
      dispatch(getMenuById(selectedUserId))
    }
  }

  const setMenu = (e) => {
    e.preventDefault()
    const selectedMenuIds = result
      .filter((item, index) => e.target[`_id_${index}`].checked)
      .map((item) => item._id)
    dispatch(setMenuById(selectedMenuIds, loginId))
  }

  function SetMessage({ success }) {
    const [showMessage, setShowMessage] = useState(true)

    useEffect(() => {
      const timer = setTimeout(() => {
        handleClose()
      }, 3000)

      return () => {
        clearTimeout(timer)
      }
    }, [])

    const handleClose = () => {
      setShowMessage(false)
      dispatch({ type: MENU_SET_RESET })
    }

    return (
      <>
        {showMessage && (
          <Alert
            variant='success'
            dismissible
            onClose={handleClose}
            style={{ width: '60%', fontSize: '17px' }}
          >
            Updated!
          </Alert>
        )}
      </>
    )
  }

  return (
    <>
      <h1> Menu Maintenance </h1>
      {loading && <Loader></Loader>}
      {error ? (
        <ErrorButton
          loading={loading}
          error={error}
          type='USER_LIST_RESET'
        ></ErrorButton>
      ) : (
        users && (
          <Row>
            <Col sm={2} md={4} lg={6} style={{ textAlign: 'left' }}>
              <Button
                onClick={() => {
                  setOpen(!open)
                  window.location.reload()
                }}
                aria-controls='example-collapse-text'
                aria-expanded={open}
              >
                <i className='fas fa-search'></i>
              </Button>
              <Collapse in={open}>
                <div id='example-collapse-text'>
                  <form onSubmit={(e) => copyfrom(e)}>
                    <Table className='table-sm'>
                      <tbody>
                        <tr>
                          <td>Select a User:</td>
                          <td colSpan={8}>
                            <select
                              className='form-control form-control-sm'
                              id='loginid'
                              onChange={(e) => selectUser(e)}
                            >
                              {users &&
                                users.map((u, i) => (
                                  <option key={i} value={u._id}>
                                    {u.LoginName}
                                  </option>
                                ))}
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td>Copy Rights From:</td>
                          <td colSpan={6}>
                            <select
                              className='form-control form-control-sm'
                              id='copyfrom'
                            >
                              {users &&
                                users.map((u, i) => (
                                  <option key={i} value={u._id}>
                                    {u.LoginName}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td colSpan={2}>
                            <Button
                              variant='secondary'
                              size='sm'
                              type='submit'
                              disabled={!result}
                            >
                              Copy
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </form>
                </div>
              </Collapse>
            </Col>
          </Row>
        )
      )}
      {loadingGet && <Loader></Loader>}
      {errorGet ? (
        <ErrorButton
          loading={loadingGet}
          error={errorGet}
          type='MENU_GET_RESET'
        ></ErrorButton>
      ) : (
        result &&
        !menu &&
        result.length > 0 && (
          <>
            {success && <SetMessage />}
            {copyLogin && (
              <Alert
                key={'alertCopy'}
                variant='success'
                dismissible
                style={{ width: '60%', fontSize: '17px' }}
              >
                Menu copied from {copyLogin}! Click 'SET' to submit
              </Alert>
            )}
            <Alert
              key={'alertMain'}
              variant='light'
              style={{ width: '60%', fontSize: '17px' }}
            >
              Choose the desired permissions for the selected user and set them
              at the bottom of the page.
            </Alert>
            <form onSubmit={(e) => setMenu(e)}>
              <Table
                striped
                bordered
                hover
                responsive
                className='table-sm'
                style={{ width: '60%' }}
              >
                <thead>
                  <tr>
                    <th>Menu</th>
                    <th>Script</th>
                    <th>
                      Access{' '}
                      <Checkbox
                        type='checkbox'
                        name='selectAll'
                        id='selectAll'
                        handleClick={handleSelectAll}
                        isChecked={isCheckAll}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {result.map((r, i) => (
                    <React.Fragment key={i}>
                      <tr>
                        <td>{r.MenuName}</td>
                        <td>{r.MenuItemName}</td>
                        <td>
                          <Checkbox
                            key={i}
                            type='checkbox'
                            id={'_id_' + i}
                            value={r._id}
                            handleClick={handleClick}
                            isChecked={isCheck.includes(r._id)}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={7}>
                      <Button type='submit' variant='secondary' size='sm'>
                        Set
                      </Button>
                      {loadingSet && <Loader size='30px'></Loader>}
                      {errorSet && (
                        <ErrorButton
                          size='sm'
                          variant='danger'
                          onClick={(e) => {
                            dispatch({
                              type: MENU_SET_RESET,
                            })
                            window.location.reload()
                          }}
                        >
                          <i className='fas fa-exclamation-circle'></i>
                        </ErrorButton>
                      )}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </form>
          </>
        )
      )}
    </>
  )
}
export default MenuScreen
