import {
  GET_PICK_TICKET_REQUEST,
  GET_PICK_TICKET_SUCCESS,
  GET_PICK_TICKET_RESET,
  GET_PICK_TICKET_FAIL,
  GET_ORDER_BYID_REQUEST,
  GET_ORDER_BYID_SUCCESS,
  GET_ORDER_BYID_RESET,
  GET_ORDER_BYID_FAIL,
  ORDER_ROUTING_LIST_REQUEST,
  ORDER_ROUTING_LIST_SUCCESS,
  ORDER_ROUTING_LIST_RESET,
  ORDER_ROUTING_LIST_FAIL,
  ORDER_ROUTING_ROW_REQUEST,
  ORDER_ROUTING_ROW_SUCCESS,
  ORDER_ROUTING_ROW_RESET,
  ORDER_ROUTING_ROW_FAIL,
  ORDER_ROUTING_DELETE_REQUEST,
  ORDER_ROUTING_DELETE_SUCCESS,
  ORDER_ROUTING_DELETE_RESET,
  ORDER_ROUTING_DELETE_FAIL,
  ORDER_ROUTING_CREATE_REQUEST,
  ORDER_ROUTING_CREATE_SUCCESS,
  ORDER_ROUTING_CREATE_RESET,
  ORDER_ROUTING_CREATE_FAIL,
  GET_CONTRACT_BYACC_REQUEST,
  GET_CONTRACT_BYACC_SUCCESS,
  GET_CONTRACT_BYACC_RESET,
  GET_CONTRACT_BYACC_FAIL,
  GET_CONTRACT_BYID_REQUEST,
  GET_CONTRACT_BYID_SUCCESS,
  GET_CONTRACT_BYID_RESET,
  GET_CONTRACT_BYID_FAIL,
  UPDATE_CONTRACT_REQUEST,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_RESET,
  UPDATE_CONTRACT_FAIL,
  DEL_CONTRACT_REQUEST,
  DEL_CONTRACT_SUCCESS,
  DEL_CONTRACT_RESET,
  DEL_CONTRACT_FAIL,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_RESET,
  CREATE_CONTRACT_FAIL,
  CREATE_CON_OPCODE_REQUEST,
  CREATE_CON_OPCODE_SUCCESS,
  CREATE_CON_OPCODE_RESET,
  CREATE_CON_OPCODE_FAIL,
  DEL_CON_OPCODE_REQUEST,
  DEL_CON_OPCODE_SUCCESS,
  DEL_CON_OPCODE_RESET,
  DEL_CON_OPCODE_FAIL,
  CUSTOM_LETTER_ROW_REQUEST,
  CUSTOM_LETTER_ROW_SUCCESS,
  CUSTOM_LETTER_ROW_RESET,
  CUSTOM_LETTER_ROW_FAIL,
  CUSTOM_LETTER_CREATE_REQUEST,
  CUSTOM_LETTER_CREATE_SUCCESS,
  CUSTOM_LETTER_CREATE_RESET,
  CUSTOM_LETTER_CREATE_FAIL,
  CUSTOM_LETTER_CREATE_ERROR,
  CUSTOM_LETTER_DELETE_REQUEST,
  CUSTOM_LETTER_DELETE_SUCCESS,
  CUSTOM_LETTER_DELETE_RESET,
  CUSTOM_LETTER_DELETE_FAIL,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_RESET,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_ERROR,
  IMPORT_FILTER_GET_REQUEST,
  IMPORT_FILTER_GET_SUCCESS,
  IMPORT_FILTER_GET_RESET,
  IMPORT_FILTER_GET_FAIL,
  GET_ORDFORM_BYACC_REQUEST,
  GET_ORDFORM_BYACC_SUCCESS,
  GET_ORDFORM_BYACC_RESET,
  GET_ORDFORM_BYACC_FAIL,
  GET_ORDFORM_BYID_REQUEST,
  GET_ORDFORM_BYID_SUCCESS,
  GET_ORDFORM_BYID_RESET,
  GET_ORDFORM_BYID_FAIL,
  UPDATE_ORDERFORM_REQUEST,
  UPDATE_ORDERFORM_SUCCESS,
  UPDATE_ORDERFORM_RESET,
  UPDATE_ORDERFORM_FAIL,
  CREATE_ORDFORM_FIELD_REQUEST,
  CREATE_ORDFORM_FIELD_SUCCESS,
  CREATE_ORDFORM_FIELD_RESET,
  CREATE_ORDFORM_FIELD_FAIL,
  DEL_ORDFORM_FIELD_REQUEST,
  DEL_ORDFORM_FIELD_SUCCESS,
  DEL_ORDFORM_FIELD_RESET,
  DEL_ORDFORM_FIELD_FAIL,
  CREATE_ORDFORM_PROD_REQUEST,
  CREATE_ORDFORM_PROD_SUCCESS,
  CREATE_ORDFORM_PROD_RESET,
  CREATE_ORDFORM_PROD_FAIL,
  DEL_ORDFORM_PROD_REQUEST,
  DEL_ORDFORM_PROD_SUCCESS,
  DEL_ORDFORM_PROD_RESET,
  DEL_ORDFORM_PROD_FAIL,
  CREATE_ORDFORM_LOGIN_SUCCESS,
  CREATE_ORDFORM_LOGIN_REQUEST,
  CREATE_ORDFORM_LOGIN_RESET,
  CREATE_ORDFORM_LOGIN_FAIL,
  DEL_ORDFORM_LOGIN_REQUEST,
  DEL_ORDFORM_LOGIN_SUCCESS,
  DEL_ORDFORM_LOGIN_RESET,
  DEL_ORDFORM_LOGIN_FAIL,
  CREATE_ORDERFORM_REQUEST,
  CREATE_ORDERFORM_SUCCESS,
  CREATE_ORDERFORM_RESET,
  CREATE_ORDERFORM_FAIL,
  DEL_ORDERFORM_REQUEST,
  DEL_ORDERFORM_SUCCESS,
  DEL_ORDERFORM_RESET,
  DEL_ORDERFORM_FAIL,
  UPDATE_ORDFORM_FIELD_REQUEST,
  UPDATE_ORDFORM_FIELD_SUCCESS,
  UPDATE_ORDFORM_FIELD_RESET,
  UPDATE_ORDFORM_FIELD_FAIL,
  UPDATE_IMP_FILTER_REQUEST,
  UPDATE_IMP_FILTER_SUCCESS,
  UPDATE_IMP_FILTER_RESET,
  UPDATE_IMP_FILTER_FAIL,
  BCKORD_TO_SHIPPED_REQUEST,
  BCKORD_TO_SHIPPED_SUCCESS,
  BCKORD_TO_SHIPPED_RESET,
  BCKORD_TO_SHIPPED_FAIL,
  CREATE_IMP_FILTER_REQUEST,
  CREATE_IMP_FILTER_SUCCESS,
  CREATE_IMP_FILTER_RESET,
  CREATE_IMP_FILTER_FAIL,
  DEL_IMP_FILTER_REQUEST,
  DEL_IMP_FILTER_SUCCESS,
  DEL_IMP_FILTER_RESET,
  DEL_IMP_FILTER_FAIL,
  ORDER_CONTRACT_UPDATE_REQUEST,
  ORDER_CONTRACT_UPDATE_SUCCESS,
  ORDER_CONTRACT_UPDATE_RESET,
  ORDER_CONTRACT_UPDATE_FAIL,
  CARRIER_VIA_GET_REQUEST,
  CARRIER_VIA_GET_SUCCESS,
  CARRIER_VIA_GET_RESET,
  CARRIER_VIA_GET_FAIL,
  BATCH_CARRIER_UPDATE_REQUEST,
  BATCH_CARRIER_UPDATE_SUCCESS,
  BATCH_CARRIER_UPDATE_RESET,
  BATCH_CARRIER_UPDATE_FAIL,
  BATCH_VERIFY_UPDATE_REQUEST,
  BATCH_VERIFY_UPDATE_SUCCESS,
  BATCH_VERIFY_UPDATE_RESET,
  BATCH_VERIFY_UPDATE_FAIL,
  BCKORD_TO_NEW_REQUEST,
  BCKORD_TO_NEW_SUCCESS,
  BCKORD_TO_NEW_RESET,
  BCKORD_TO_NEW_FAIL,
  GET_CUST_BYACC_REQUEST,
  GET_CUST_BYACC_SUCCESS,
  GET_CUST_BYACC_RESET,
  GET_CUST_BYACC_FAIL,
  GET_ADDR_BYCUS_REQUEST,
  GET_ADDR_BYCUS_SUCCESS,
  GET_ADDR_BYCUS_RESET,
  GET_ADDR_BYCUS_FAIL,
  UPDATE_ADDR_REQUEST,
  UPDATE_ADDR_SUCCESS,
  UPDATE_ADDR_RESET,
  UPDATE_ADDR_FAIL,
  CREATE_ADDR_REQUEST,
  CREATE_ADDR_SUCCESS,
  CREATE_ADDR_RESET,
  CREATE_ADDR_FAIL,
  DEL_ADDR_REQUEST,
  DEL_ADDR_SUCCESS,
  DEL_ADDR_RESET,
  DEL_ADDR_FAIL,
  UPDATE_CUST_REQUEST,
  UPDATE_CUST_SUCCESS,
  UPDATE_CUST_RESET,
  UPDATE_CUST_FAIL,
  CREATE_CUST_REQUEST,
  CREATE_CUST_SUCCESS,
  CREATE_CUST_RESET,
  CREATE_CUST_FAIL,
  DEL_CUST_REQUEST,
  DEL_CUST_SUCCESS,
  DEL_CUST_RESET,
  DEL_CUST_FAIL,
  GET_CUST_BYID_REQUEST,
  GET_CUST_BYID_SUCCESS,
  GET_CUST_BYID_RESET,
  GET_CUST_BYID_FAIL,
  GET_WIDGET_REQUEST,
  GET_WIDGET_SUCCESS,
  GET_WIDGET_FAIL,
  GET_WIDGET_RESET,
  BADGE_GET_REQUEST,
  BADGE_GET_SUCCESS,
  BADGE_GET_RESET,
  BADGE_GET_FAIL,
  BADGE_DELETE_REQUEST,
  BADGE_DELETE_SUCCESS,
  BADGE_DELETE_RESET,
  BADGE_DELETE_FAIL,
  BADGE_CREATE_REQUEST,
  BADGE_CREATE_SUCCESS,
  BADGE_CREATE_RESET,
  BADGE_CREATE_FAIL,
  BADGE_FILE_CREATE_REQUEST,
  BADGE_FILE_CREATE_SUCCESS,
  BADGE_FILE_CREATE_RESET,
  BADGE_FILE_CREATE_FAIL,
  LOGIN_LIST_FAIL,
  LOGIN_LIST_REQUEST,
  LOGIN_LIST_RESET,
  LOGIN_LIST_SUCCESS,
  LOGIN_CREATE_FAIL,
  LOGIN_CREATE_REQUEST,
  LOGIN_CREATE_RESET,
  LOGIN_CREATE_SUCCESS,
  LOGIN_DELETE_FAIL,
  LOGIN_DELETE_REQUEST,
  LOGIN_DELETE_RESET,
  LOGIN_DELETE_SUCCESS,
  LOGIN_UPDATE_FAIL,
  LOGIN_UPDATE_REQUEST,
  LOGIN_UPDATE_RESET,
  LOGIN_UPDATE_SUCCESS,
  MENU_GET_REQUEST,
  MENU_GET_SUCCESS,
  MENU_GET_FAIL,
  MENU_GET_RESET,
  MENU_SET_REQUEST,
  MENU_SET_SUCCESS,
  MENU_SET_FAIL,
  MENU_SET_RESET,
  PRODUCTLIST_REPORT_REQUEST,
  PRODUCTLIST_REPORT_SUCCESS,
  PRODUCTLIST_REPORT_FAIL,
  PRODUCTLIST_REPORT_RESET,
  ECO_GET_REQUEST,
  ECO_GET_SUCCESS,
  ECO_GET_FAIL,
  ECO_GET_RESET,
  ECO_CREATE_REQUEST,
  ECO_CREATE_SUCCESS,
  ECO_CREATE_FAIL,
  ECO_CREATE_RESET,
  CATE_BYID_REQUEST,
  CATE_BYID_SUCCESS,
  CATE_BYID_RESET,
  CATE_BYID_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_RESET,
  CATEGORY_CREATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_RESET,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_RESET,
  CATEGORY_DELETE_SUCCESS,
  PPPTABLE_EDITMODE_SET,
  PPPTABLE_EDITMODE_RESET,
  PARENT_CATEGORY_REQUEST,
  PARENT_CATEGORY_SUCCESS,
  PARENT_CATEGORY_RESET,
  PARENT_CATEGORY_FAIL,
  PROD_BYID_REQUEST,
  PROD_BYID_SUCCESS,
  PROD_BYID_RESET,
  PROD_BYID_FAIL,
  CATEGORY_GETLIST_REQUEST,
  CATEGORY_GETLIST_SUCCESS,
  CATEGORY_GETLIST_RESET,
  CATEGORY_GETLIST_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_RESET,
  PRODUCT_CREATE_FAIL,
  CATEGORY_CREATE_ERROR,
  PRODGROUP_BYID_REQUEST,
  PRODGROUP_BYID_SUCCESS,
  PRODGROUP_BYID_RESET,
  PRODGROUP_BYID_FAIL,
  PGLOGIN_ADD_REQUEST,
  PGLOGIN_ADD_SUCCESS,
  PGLOGIN_ADD_RESET,
  PGLOGIN_ADD_FAIL,
  PGLOGIN_REMOVE_REQUEST,
  PGLOGIN_REMOVE_SUCCESS,
  PGLOGIN_REMOVE_RESET,
  PGLOGIN_REMOVE_FAIL,
  FILE_UPLOAD_ERROR,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_FAIL,
  FILE_UPLOAD_RESET,
  FILE_UPLOAD_SUCCESS,
  PRODUCT_VARIANT_UPDATE_REQUEST,
  PRODUCT_VARIANT_UPDATE_SUCCESS,
  PRODUCT_VARIANT_UPDATE_RESET,
  PRODUCT_VARIANT_UPDATE_FAIL,
  PGCATEGORY_ADD_REQUEST,
  PGCATEGORY_ADD_SUCCESS,
  PGCATEGORY_ADD_RESET,
  PGCATEGORY_ADD_FAIL,
  PGCATEGORY_REMOVE_REQUEST,
  PGCATEGORY_REMOVE_SUCCESS,
  PGCATEGORY_REMOVE_RESET,
  PGCATEGORY_REMOVE_FAIL,
  QUICK_ORDER_CREATE_REQUEST,
  QUICK_ORDER_CREATE_SUCCESS,
  QUICK_ORDER_CREATE_RESET,
  QUICK_ORDER_CREATE_FAIL,
  ORDER_CANCEL_REQUEST,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_RESET,
  ORDER_CANCEL_FAIL,
  QUICK_ORDER_CREATE_ERROR_QUANTITY,
  QUICK_ORDER_CREATE_ERROR_REQUIRE,
  ADDRESS_BOOK_GET_REQUEST,
  ADDRESS_BOOK_GET_SUCCESS,
  ADDRESS_BOOK_GET_RESET,
  ADDRESS_BOOK_GET_FAIL,
  ADDRESS_BOOK_CREATE_REQUEST,
  ADDRESS_BOOK_CREATE_SUCCESS,
  ADDRESS_BOOK_CREATE_RESET,
  ADDRESS_BOOK_CREATE_FAIL,
  ADDRESS_BOOK_DELETE_REQUEST,
  ADDRESS_BOOK_DELETE_SUCCESS,
  ADDRESS_BOOK_DELETE_RESET,
  ADDRESS_BOOK_DELETE_FAIL,
  ADDRESS_BOOK_UPDATE_REQUEST,
  ADDRESS_BOOK_UPDATE_SUCCESS,
  ADDRESS_BOOK_UPDATE_RESET,
  ADDRESS_BOOK_UPDATE_FAIL,
  ADDRESS_BOOK_BYID_REQUEST,
  ADDRESS_BOOK_BYID_SUCCESS,
  ADDRESS_BOOK_BYID_RESET,
  ADDRESS_BOOK_BYID_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_RESET,
  ORDER_UPDATE_FAIL,
  MAILING_LIST_DELETE_REQUEST,
  MAILING_LIST_DELETE_SUCCESS,
  MAILING_LIST_DELETE_RESET,
  MAILING_LIST_DELETE_FAIL,
  MANAGER_MEMBER_BYID_REQUEST,
  MANAGER_MEMBER_BYID_SUCCESS,
  MANAGER_MEMBER_BYID_RESET,
  MANAGER_MEMBER_BYID_FAIL,
  MAILING_LIST_BYID_REQUEST,
  MAILING_LIST_BYID_SUCCESS,
  MAILING_LIST_BYID_RESET,
  MAILING_LIST_BYID_FAIL,
  MANAGER_MEMLOGIN_ADD_REQUEST,
  MANAGER_MEMLOGIN_ADD_SUCCESS,
  MANAGER_MEMLOGIN_ADD_RESET,
  MANAGER_MEMLOGIN_ADD_FAIL,
  MANAGER_MEMLOGIN_REMOVE_REQUEST,
  MANAGER_MEMLOGIN_REMOVE_SUCCESS,
  MANAGER_MEMLOGIN_REMOVE_RESET,
  MANAGER_MEMLOGIN_REMOVE_FAIL,
  MAILINGLIST_CSV_UPLOAD_REQUEST,
  MAILINGLIST_CSV_UPLOAD_SUCCESS,
  MAILINGLIST_CSV_UPLOAD_RESET,
  MAILINGLIST_CSV_UPLOAD_FAIL,
  PRODGROUP_UPDATE_REQUEST,
  PRODGROUP_UPDATE_SUCCESS,
  PRODGROUP_UPDATE_RESET,
  PRODGROUP_UPDATE_FAIL,
  ORDERS_CSV_UPLOAD_REQUEST,
  ORDERS_CSV_UPLOAD_SUCCESS,
  ORDERS_CSV_UPLOAD_RESET,
  ORDERS_CSV_UPLOAD_FAIL,
  PRODGROUP_CREATE_REQUEST,
  PRODGROUP_CREATE_SUCCESS,
  PRODGROUP_CREATE_RESET,
  PRODGROUP_CREATE_FAIL,
  PRODGROUP_CREATE_ERROR,
  PRODGROUP_UPDATE_ERROR,
  PRICEGROUP_MEMBER_BYID_REQUEST,
  PRICEGROUP_MEMBER_BYID_SUCCESS,
  PRICEGROUP_MEMBER_BYID_RESET,
  PRICEGROUP_MEMBER_BYID_FAIL,
  PRICEGROUP_MEMLOGIN_ADD_REQUEST,
  PRICEGROUP_MEMLOGIN_ADD_SUCCESS,
  PRICEGROUP_MEMLOGIN_ADD_RESET,
  PRICEGROUP_MEMLOGIN_ADD_FAIL,
  PRICEGROUP_MEMLOGIN_REMOVE_REQUEST,
  PRICEGROUP_MEMLOGIN_REMOVE_SUCCESS,
  PRICEGROUP_MEMLOGIN_REMOVE_RESET,
  PRICEGROUP_MEMLOGIN_REMOVE_FAIL,
  PRICEGROUP_UPDATE_REQUEST,
  PRICEGROUP_UPDATE_SUCCESS,
  PRICEGROUP_UPDATE_RESET,
  PRICEGROUP_UPDATE_ERROR,
  PRICEGROUP_UPDATE_FAIL,
  PRICEGROUP_CREATE_REQUEST,
  PRICEGROUP_CREATE_SUCCESS,
  PRICEGROUP_CREATE_ERROR,
  PRICEGROUP_CREATE_RESET,
  PRICEGROUP_CREATE_FAIL,
  USERGROUP_BYID_REQUEST,
  USERGROUP_BYID_SUCCESS,
  USERGROUP_BYID_RESET,
  USERGROUP_BYID_FAIL,
  USERGROUP_USER_ADD_REQUEST,
  USERGROUP_USER_ADD_SUCCESS,
  USERGROUP_USER_ADD_RESET,
  USERGROUP_USER_ADD_FAIL,
  USERGROUP_USER_REMOVE_REQUEST,
  USERGROUP_USER_REMOVE_SUCCESS,
  USERGROUP_USER_REMOVE_RESET,
  USERGROUP_USER_REMOVE_FAIL,
  USERGROUP_UPDATE_REQUEST,
  USERGROUP_UPDATE_SUCCESS,
  USERGROUP_UPDATE_ERROR,
  USERGROUP_UPDATE_RESET,
  USERGROUP_UPDATE_FAIL,
  USERGROUP_CREATE_REQUEST,
  USERGROUP_CREATE_SUCCESS,
  USERGROUP_CREATE_ERROR,
  USERGROUP_CREATE_RESET,
  USERGROUP_CREATE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_DELETE_RESET,
  IMAGE_DELETE_FAIL,
  IMAGE_USER_ADD_REQUEST,
  IMAGE_USER_ADD_SUCCESS,
  IMAGE_USER_ADD_RESET,
  IMAGE_USER_ADD_FAIL,
  IMAGE_USER_ADD_ERROR,
  CATEGORY_TREE_GET_REQUEST,
  CATEGORY_TREE_GET_SUCCESS,
  CATEGORY_TREE_GET_RESET,
  CATEGORY_TREE_GET_FAIL,
  PRODUCT_PRICING_BYID_REQUEST,
  PRODUCT_PRICING_BYID_SUCCESS,
  PRODUCT_PRICING_BYID_RESET,
  PRODUCT_PRICING_BYID_FAIL,
  PRODUCT_PRICING_UPDATE_REQUEST,
  PRODUCT_PRICING_UPDATE_SUCCESS,
  PRODUCT_PRICING_UPDATE_RESET,
  PRODUCT_PRICING_UPDATE_FAIL,
  CART_GET_REQUEST,
  CART_GET_SUCCESS,
  CART_GET_RESET,
  CART_GET_FAIL,
  CART_PRODUCT_ADD_SUCCESS,
  CART_PRODUCT_ADD_REQUEST,
  CART_PRODUCT_ADD_RESET,
  CART_PRODUCT_ADD_FAIL,
  CART_PRODUCT_REMOVE_REQUEST,
  CART_PRODUCT_REMOVE_SUCCESS,
  CART_PRODUCT_REMOVE_RESET,
  CART_PRODUCT_REMOVE_FAIL,
  //  CART_GET_LOCK,
  CHECKOUT_STEP_INIT,
  CHECKOUT_STEP_BILLTO,
  CHECKOUT_STEP_PAYMENT,
  CHECKOUT_STEP_SHIPTO,
  CHECKOUT_STEP_PLACE_ORDER,
  STEP_INIT,
  STEP_SHIPTO,
  STEP_BILLTO,
  STEP_PAYMENT,
  STEP_PLACE_ORDER,
  STEP_ORDER_COMPLETE,
  CHECKOUT_STEP_ORDER_COMPLETE,
  CHECKOUT_ORDER_CREATE_REQUEST,
  CHECKOUT_ORDER_CREATE_SUCCESS,
  CHECKOUT_ORDER_CREATE_RESET,
  CHECKOUT_ORDER_CREATE_ERROR,
  CHECKOUT_ORDER_CREATE_FAIL,
  ORDER_FORM_GET_REQUEST,
  ORDER_FORM_GET_SUCCESS,
  ORDER_FORM_GET_RESET,
  ORDER_FORM_GET_FAIL,
  CART_PRODUCT_UPDATE_REQUEST,
  CART_PRODUCT_UPDATE_SUCCESS,
  CART_PRODUCT_UPDATE_RESET,
  CART_PRODUCT_UPDATE_FAIL,
  CART_PRODLIST_ADD_REQUEST,
  CART_PRODLIST_ADD_SUCCESS,
  CART_PRODLIST_ADD_RESET,
  CART_PRODLIST_ADD_FAIL,
  CURRENT_SESSION_SET_REQUEST,
  CURRENT_SESSION_SET_SUCCESS,
  CURRENT_SESSION_SET_RESET,
  CURRENT_SESSION_SET_FAIL,
  PRODUCT_VARIANT_DELETE_REQUEST,
  PRODUCT_VARIANT_DELETE_SUCCESS,
  PRODUCT_VARIANT_DELETE_RESET,
  PRODUCT_VARIANT_DELETE_FAIL,
  MASTER_VARIANT_CREATE_REQUEST,
  MASTER_VARIANT_CREATE_SUCCESS,
  MASTER_VARIANT_CREATE_RESET,
  MASTER_VARIANT_CREATE_FAIL,
  UPDATE_VARIANCE_CREATE_REQUEST,
  UPDATE_VARIANCE_CREATE_RESET,
  UPDATE_VARIANCE_CREATE_SUCCESS,
  UPDATE_VARIANCE_CREATE_FAIL,
  VARIANCE_UPDATE_REQUEST,
  VARIANCE_UPDATE_SUCCESS,
  VARIANCE_UPDATE_RESET,
  VARIANCE_UPDATE_FAIL,
  VARIANCE_DELETE_REQUEST,
  VARIANCE_DELETE_SUCCESS,
  VARIANCE_DELETE_RESET,
  VARIANCE_DELETE_FAIL,
  FORMS_BYPRODUCTIDS_GET_REQUEST,
  FORMS_BYPRODUCTIDS_GET_SUCCESS,
  FORMS_BYPRODUCTIDS_GET_RESET,
  FORMS_BYPRODUCTIDS_GET_FAIL,
  PRICEGROUP_PROD_ADD_REQUEST,
  PRICEGROUP_PROD_ADD_SUCCESS,
  PRICEGROUP_PROD_ADD_RESET,
  PRICEGROUP_PROD_ADD_FAIL,
  PRICEGROUP_PROD_UPDATE_REQUEST,
  PRICEGROUP_PROD_UPDATE_SUCCESS,
  PRICEGROUP_PROD_UPDATE_RESET,
  PRICEGROUP_PROD_UPDATE_FAIL,
  PRICEGROUP_PROD_DELETE_REQUEST,
  PRICEGROUP_PROD_DELETE_SUCCESS,
  PRICEGROUP_PROD_DELETE_RESET,
  PRICEGROUP_PROD_DELETE_FAIL,
  PRODUCT_QUOTA_UPDATE_REQUEST,
  PRODUCT_QUOTA_UPDATE_SUCCESS,
  PRODUCT_QUOTA_UPDATE_RESET,
  PRODUCT_QUOTA_UPDATE_FAIL,
  PRODUCT_QUOTA_GET_REQUEST,
  PRODUCT_QUOTA_GET_SUCCESS,
  PRODUCT_QUOTA_GET_RESET,
  PRODUCT_QUOTA_GET_FAIL,
  CAPTURE_CUSTJSON_GET_REQUEST,
  CAPTURE_CUSTJSON_GET_SUCCESS,
  CAPTURE_CUSTJSON_GET_RESET,
  CAPTURE_CUSTJSON_GET_FAIL,
  CHECK_ADDRESS_USPS_REQUEST,
  CHECK_ADDRESS_USPS_SUCCESS,
  CHECK_ADDRESS_USPS_RESET,
  CHECK_ADDRESS_USPS_FAIL,
  MULTI_ORDERS_CREATE_REQUEST,
  MULTI_ORDERS_CREATE_SUCCESS,
  MULTI_ORDERS_CREATE_RESET,
  MULTI_ORDERS_CREATE_FAIL,
  OFFORM_DETAIL_UPDATE_FAIL,
  OFFORM_DETAIL_UPDATE_RESET,
  OFFORM_DETAIL_UPDATE_SUCCESS,
  OFFORM_DETAIL_UPDATE_REQUEST,
  OFFORM_DETAIL_GET_FAIL,
  OFFORM_DETAIL_GET_RESETT,
  OFFORM_DETAIL_GET_SUCCESS,
  OFFORM_DETAIL_GET_REQUEST,
  MMBOOK_TEXT_SIZE_ERROR,
  MMBOOK_COVER_SIZE_ERROR,
  MMBOOK_COVER_CREATE_REQUEST,
  MMBOOK_COVER_CREATE_SUCCESS,
  MMBOOK_COVER_CREATE_RESET,
  MMBOOK_COVER_CREATE_FAIL,
  MMBOOK_COVER_RESET,
  MMBOOK_TEXT_RESET,
  MMBOOK_CREATE_REQUEST,
  MMBOOK_CREATE_SUCCESS,
  MMBOOK_CREATE_RESET,
  MMBOOK_CREATE_FAIL,
  MMBOOK_ERROR_RESET,
  MMBOOK_ISBN_EXIST_ERROR,
  MMBOOK_ISBN_VALIDATE_ERROR,
  CUSTOM_BOOK_UPDATE_REQUEST,
  CUSTOM_BOOK_UPDATE_SUCCESS,
  CUSTOM_BOOK_UPDATE_RESET,
  CUSTOM_BOOK_UPDATE_FAIL,
  MMBOOK_UPLOAD_REQUEST,
  MMBOOK_UPLOAD_SUCCESS,
  MMBOOK_UPLOAD_FAIL,
  TOGGLE_FAVORITE_REQUEST,
  TOGGLE_FAVORITE_SUCCESS,
  TOGGLE_FAVORITE_FAIL,
  TOGGLE_FAVORITE_RESET,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_RESET,
  ORDER_DETAIL_FAIL,
  PASSWORD_POLICY_UPDATE_REQUEST,
  PASSWORD_POLICY_UPDATE_SUCCESS,
  PASSWORD_POLICY_UPDATE_RESET,
  PASSWORD_POLICY_UPDATE_FAILURE,
  PASSWORD_POLICY_GET_REQUEST,
  PASSWORD_POLICY_GET_SUCCESS,
  PASSWORD_POLICY_GET_RESET,
  PASSWORD_POLICY_GET_FAILURE,
  MMBOOK_RESET_REQUEST,
  ILINK_CATEGORY_TREE_GET_REQUEST,
  ILINK_CATEGORY_TREE_GET_SUCCESS,
  ILINK_CATEGORY_TREE_GET_RESET,
  ILINK_CATEGORY_TREE_GET_FAIL,
  KIT_UPDATE_REQUEST,
  KIT_UPDATE_SUCCESS,
  KIT_UPDATE_RESET,
  KIT_UPDATE_FAIL,
  LOGO_DELETE_RESET,
  LOGO_DELETE_SUCCESS,
  LOGO_DELETE_FAIL,
  LOGO_DELETE_REQUEST,
} from '../constants/webstoreConstants'

export const getPickTicketReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PICK_TICKET_REQUEST:
      return { loading: true }
    case GET_PICK_TICKET_SUCCESS:
      return {
        loading: false,
        ticket: action.payload,
        shipping: action.payload.shipping,
        billing: action.payload.billing,
        details: action.payload.details,
      }
    case GET_PICK_TICKET_RESET:
      return {}
    case GET_PICK_TICKET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getOrderbyIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDER_BYID_REQUEST:
      return { loading: true }
    case GET_ORDER_BYID_SUCCESS:
      return { loading: false, order: action.payload, success: true }
    case GET_ORDER_BYID_RESET:
      return {}
    case GET_ORDER_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderRoutingListReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ROUTING_LIST_REQUEST:
      return { loading: true }
    case ORDER_ROUTING_LIST_SUCCESS:
      return { loading: false, list: action.payload }
    case ORDER_ROUTING_LIST_RESET:
      return {}
    case ORDER_ROUTING_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderRoutingRowReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ROUTING_ROW_REQUEST:
      return { loading: true }
    case ORDER_ROUTING_ROW_SUCCESS:
      return { loading: false, newrow: action.payload }
    case ORDER_ROUTING_ROW_RESET:
      return {}
    case ORDER_ROUTING_ROW_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderRoutingCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ROUTING_CREATE_REQUEST:
      return { loading: true }
    case ORDER_ROUTING_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case ORDER_ROUTING_CREATE_RESET:
      return {}
    case ORDER_ROUTING_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderRoutingDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_ROUTING_DELETE_REQUEST:
      return { loading: true }
    case ORDER_ROUTING_DELETE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_ROUTING_DELETE_RESET:
      return {}
    case ORDER_ROUTING_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const contractByAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CONTRACT_BYACC_REQUEST:
      return { loading: true }
    case GET_CONTRACT_BYACC_SUCCESS:
      return {
        loading: false,
        contract: action.payload.contracts,
        operationCodes: action.payload.operationCodes,
        success: true,
      }
    case GET_CONTRACT_BYACC_RESET:
      return {}
    case GET_CONTRACT_BYACC_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const contractByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CONTRACT_BYID_REQUEST:
      return { loading: true }
    case GET_CONTRACT_BYID_SUCCESS:
      return {
        loading: false,
        contract: action.payload,
        operationCodes: action.payload['operationCodes'],
        success: true,
      }
    case GET_CONTRACT_BYID_RESET:
      return {}
    case GET_CONTRACT_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const contractUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CONTRACT_REQUEST:
      return { loading: true }
    case UPDATE_CONTRACT_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_CONTRACT_RESET:
      return {}
    case UPDATE_CONTRACT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const contractDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_CONTRACT_REQUEST:
      return { loading: true }
    case DEL_CONTRACT_SUCCESS:
      return { loading: false, success: true }
    case DEL_CONTRACT_RESET:
      return {}
    case DEL_CONTRACT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const contractCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CONTRACT_REQUEST:
      return { loading: true }
    case CREATE_CONTRACT_SUCCESS:
      return { loading: false, success: true }
    case CREATE_CONTRACT_RESET:
      return {}
    case CREATE_CONTRACT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createContractOpCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CON_OPCODE_REQUEST:
      return { loading: true }
    case CREATE_CON_OPCODE_SUCCESS:
      return { loading: false, success: true }
    case CREATE_CON_OPCODE_RESET:
      return {}
    case CREATE_CON_OPCODE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteContractOpCodeReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_CON_OPCODE_REQUEST:
      return { loading: true }
    case DEL_CON_OPCODE_SUCCESS:
      return { loading: false, success: true }
    case DEL_CON_OPCODE_RESET:
      return {}
    case DEL_CON_OPCODE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customLetterRowReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_LETTER_ROW_REQUEST:
      return { loading: true }
    case CUSTOM_LETTER_ROW_SUCCESS:
      return { loading: false, newrow: action.payload }
    case CUSTOM_LETTER_ROW_RESET:
      return {}
    case CUSTOM_LETTER_ROW_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customLetterCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_LETTER_CREATE_REQUEST:
      return { loading: true }
    case CUSTOM_LETTER_CREATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload }
    case CUSTOM_LETTER_CREATE_RESET:
      return {}
    case CUSTOM_LETTER_CREATE_ERROR:
      return { error: 'one of required fields is empty' }
    case CUSTOM_LETTER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customLetterDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_LETTER_DELETE_REQUEST:
      return { loading: true }
    case CUSTOM_LETTER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CUSTOM_LETTER_DELETE_RESET:
      return {}
    case CUSTOM_LETTER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const importFilterByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case IMPORT_FILTER_GET_REQUEST:
      return { loading: true }
    case IMPORT_FILTER_GET_SUCCESS:
      return { loading: false, filter: action.payload }
    case IMPORT_FILTER_GET_RESET:
      return {}
    case IMPORT_FILTER_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateImportFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_IMP_FILTER_REQUEST:
      return { loading: true }
    case UPDATE_IMP_FILTER_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_IMP_FILTER_RESET:
      return {}
    case UPDATE_IMP_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createImportFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_IMP_FILTER_REQUEST:
      return { loading: true }
    case CREATE_IMP_FILTER_SUCCESS:
      return { loading: false, success: true }
    case CREATE_IMP_FILTER_RESET:
      return {}
    case CREATE_IMP_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteImportFilterReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_IMP_FILTER_REQUEST:
      return { loading: true }
    case DEL_IMP_FILTER_SUCCESS:
      return { loading: false, success: true }
    case DEL_IMP_FILTER_RESET:
      return {}
    case DEL_IMP_FILTER_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderformByAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDFORM_BYACC_REQUEST:
      return { loading: true }
    case GET_ORDFORM_BYACC_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      }
    case GET_ORDFORM_BYACC_RESET:
      return {}
    case GET_ORDFORM_BYACC_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderformByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ORDFORM_BYID_REQUEST:
      return { loading: true }
    case GET_ORDFORM_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        order: action.payload,
      }
    case GET_ORDFORM_BYID_RESET:
      return {}
    case GET_ORDFORM_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateOrderFormReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDERFORM_REQUEST:
      return { loading: true }
    case UPDATE_ORDERFORM_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_ORDERFORM_RESET:
      return {}
    case UPDATE_ORDERFORM_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createOrderFormReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDERFORM_REQUEST:
      return { loading: true }
    case CREATE_ORDERFORM_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case CREATE_ORDERFORM_RESET:
      return {}
    case CREATE_ORDERFORM_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteOrderFormReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_ORDERFORM_REQUEST:
      return { loading: true }
    case DEL_ORDERFORM_SUCCESS:
      return { loading: false, success: true }
    case DEL_ORDERFORM_RESET:
      return {}
    case DEL_ORDERFORM_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateOrderFormFieldReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ORDFORM_FIELD_REQUEST:
      return { loading: true }
    case UPDATE_ORDFORM_FIELD_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_ORDFORM_FIELD_RESET:
      return {}
    case UPDATE_ORDFORM_FIELD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createOrderFormFieldReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDFORM_FIELD_REQUEST:
      return { loading: true }
    case CREATE_ORDFORM_FIELD_SUCCESS:
      return { loading: false, success: true }
    case CREATE_ORDFORM_FIELD_RESET:
      return {}
    case CREATE_ORDFORM_FIELD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteOrderFormFieldReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_ORDFORM_FIELD_REQUEST:
      return { loading: true }
    case DEL_ORDFORM_FIELD_SUCCESS:
      return { loading: false, success: true }
    case DEL_ORDFORM_FIELD_RESET:
      return {}
    case DEL_ORDFORM_FIELD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createOrderFormProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDFORM_PROD_REQUEST:
      return { loading: true }
    case CREATE_ORDFORM_PROD_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case CREATE_ORDFORM_PROD_RESET:
      return {}
    case CREATE_ORDFORM_PROD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteOrderFormProductReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_ORDFORM_PROD_REQUEST:
      return { loading: true }
    case DEL_ORDFORM_PROD_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case DEL_ORDFORM_PROD_RESET:
      return {}
    case DEL_ORDFORM_PROD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createOrderFormLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ORDFORM_LOGIN_REQUEST:
      return { loading: true }
    case CREATE_ORDFORM_LOGIN_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case CREATE_ORDFORM_LOGIN_RESET:
      return {}
    case CREATE_ORDFORM_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteOrderFormLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_ORDFORM_LOGIN_REQUEST:
      return { loading: true }
    case DEL_ORDFORM_LOGIN_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case DEL_ORDFORM_LOGIN_RESET:
      return {}
    case DEL_ORDFORM_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const bckord2ShippedReducer = (state = {}, action) => {
  switch (action.type) {
    case BCKORD_TO_SHIPPED_REQUEST:
      return { loading: true }
    case BCKORD_TO_SHIPPED_SUCCESS:
      return { loading: false, success: true }
    case BCKORD_TO_SHIPPED_RESET:
      return {}
    case BCKORD_TO_SHIPPED_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderContractUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CONTRACT_UPDATE_REQUEST:
      return { loading: true }
    case ORDER_CONTRACT_UPDATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case ORDER_CONTRACT_UPDATE_RESET:
      return {}
    case ORDER_CONTRACT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const carrierViaGetReducer = (state = {}, action) => {
  switch (action.type) {
    case CARRIER_VIA_GET_REQUEST:
      return { loading: true }
    case CARRIER_VIA_GET_SUCCESS:
      return { loading: false, success: true, service: action.payload }
    case CARRIER_VIA_GET_RESET:
      return {}
    case CARRIER_VIA_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchCarrierUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_CARRIER_UPDATE_REQUEST:
      return { loading: true }
    case BATCH_CARRIER_UPDATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case BATCH_CARRIER_UPDATE_RESET:
      return {}
    case BATCH_CARRIER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const batchVerifyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case BATCH_VERIFY_UPDATE_REQUEST:
      return { loading: true }
    case BATCH_VERIFY_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case BATCH_VERIFY_UPDATE_RESET:
      return {}
    case BATCH_VERIFY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const bckord2NewReducer = (state = {}, action) => {
  switch (action.type) {
    case BCKORD_TO_NEW_REQUEST:
      return { loading: true }
    case BCKORD_TO_NEW_SUCCESS:
      return { loading: false, success: true }
    case BCKORD_TO_NEW_RESET:
      return {}
    case BCKORD_TO_NEW_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customerByAccountReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CUST_BYACC_REQUEST:
      return { loading: true }
    case GET_CUST_BYACC_SUCCESS:
      return {
        loading: false,
        success: true,
        customers: action.payload,
      }
    case GET_CUST_BYACC_RESET:
      return {}
    case GET_CUST_BYACC_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customerByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CUST_BYID_REQUEST:
      return { loading: true }
    case GET_CUST_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        customer: action.payload.customer,
      }
    case GET_CUST_BYID_RESET:
      return {}
    case GET_CUST_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addressByCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ADDR_BYCUS_REQUEST:
      return { loading: true }
    case GET_ADDR_BYCUS_SUCCESS:
      return {
        loading: false,
        success: true,
        addresses: action.payload,
      }
    case GET_ADDR_BYCUS_RESET:
      return {}
    case GET_ADDR_BYCUS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_ADDR_REQUEST:
      return { loading: true }
    case UPDATE_ADDR_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_ADDR_RESET:
      return {}
    case UPDATE_ADDR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_ADDR_REQUEST:
      return { loading: true }
    case CREATE_ADDR_SUCCESS:
      return { loading: false, success: true }
    case CREATE_ADDR_RESET:
      return {}
    case CREATE_ADDR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteAddressReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_ADDR_REQUEST:
      return { loading: true }
    case DEL_ADDR_SUCCESS:
      return { loading: false, success: true }
    case DEL_ADDR_RESET:
      return {}
    case DEL_ADDR_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CUST_REQUEST:
      return { loading: true }
    case UPDATE_CUST_SUCCESS:
      return { loading: false, success: true }
    case UPDATE_CUST_RESET:
      return {}
    case UPDATE_CUST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_CUST_REQUEST:
      return { loading: true }
    case CREATE_CUST_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case CREATE_CUST_RESET:
      return {}
    case CREATE_CUST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const deleteCustomerReducer = (state = {}, action) => {
  switch (action.type) {
    case DEL_CUST_REQUEST:
      return { loading: true }
    case DEL_CUST_SUCCESS:
      return { loading: false, success: true }
    case DEL_CUST_RESET:
      return {}
    case DEL_CUST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const widgetGetReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_WIDGET_REQUEST:
      return { loading: true }
    case GET_WIDGET_SUCCESS:
      return { loading: false, widget: action.payload }
    case GET_WIDGET_RESET:
      return {}
    case GET_WIDGET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const badgeGetReducer = (state = {}, action) => {
  switch (action.type) {
    case BADGE_GET_REQUEST:
      return { loading: true }
    case BADGE_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        report: action.payload,
      }
    case BADGE_GET_RESET:
      return {}
    case BADGE_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const badgeDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case BADGE_DELETE_REQUEST:
      return { loading: true }
    case BADGE_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        report: action.payload,
      }
    case BADGE_DELETE_RESET:
      return {}
    case BADGE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const badgeCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BADGE_CREATE_REQUEST:
      return { loading: true }
    case BADGE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case BADGE_CREATE_RESET:
      return {}
    case BADGE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const badgeFileCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case BADGE_FILE_CREATE_REQUEST:
      return { loading: true }
    case BADGE_FILE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case BADGE_FILE_CREATE_RESET:
      return {}
    case BADGE_FILE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const LoginListReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_LIST_REQUEST:
      return { loading: true }
    case LOGIN_LIST_SUCCESS:
      return {
        loading: false,
        success: true,
        logins: action.payload.logins,
        accounts: action.payload.accounts,
        departments: action.payload.departments,
      }
    case LOGIN_LIST_RESET:
      return {}
    case LOGIN_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const LoginCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_CREATE_REQUEST:
      return { loading: true }
    case LOGIN_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case LOGIN_CREATE_RESET:
      return {}
    case LOGIN_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const LoginDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_DELETE_REQUEST:
      return { loading: true }
    case LOGIN_DELETE_SUCCESS:
      return {
        loading: false,
        success: true,
        _id: action.payload._id,
      }
    case LOGIN_DELETE_RESET:
      return {}
    case LOGIN_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const LoginUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGIN_UPDATE_REQUEST:
      return { loading: true }
    case LOGIN_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case LOGIN_UPDATE_RESET:
      return {}
    case LOGIN_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const menuGetReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_GET_REQUEST:
      return { loading: true }
    case MENU_GET_SUCCESS:
      return {
        loading: false,
        menu: action.payload.data,
        LoginName: action.payload.LoginName,
      }
    case MENU_GET_RESET:
      return {}
    case MENU_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const menuSetReducer = (state = {}, action) => {
  switch (action.type) {
    case MENU_SET_REQUEST:
      return { loading: true }
    case MENU_SET_SUCCESS:
      return { loading: false, success: true }
    case MENU_SET_RESET:
      return {}
    case MENU_SET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productListReportReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCTLIST_REPORT_REQUEST:
      return { loading: true }
    case PRODUCTLIST_REPORT_SUCCESS:
      return {
        loading: false,
        success: true,
        report: action.payload.data,
        next: action.payload.next,
        prev: action.payload.prev,
        select: action.payload.select,
        prodcosttype: action.payload.prodcosttype,
      }
    case PRODUCTLIST_REPORT_RESET:
      return {}
    case PRODUCTLIST_REPORT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const ecoGetReducer = (state = {}, action) => {
  switch (action.type) {
    case ECO_GET_REQUEST:
      return { loading: true }
    case ECO_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        kitlist: action.payload.kitlist,
        products: action.payload.products,
        bom: action.payload.bom,
        notes: action.payload.notes,
      }
    case ECO_GET_RESET:
      return {}
    case ECO_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const ecoCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ECO_CREATE_REQUEST:
      return { loading: true }
    case ECO_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case ECO_CREATE_RESET:
      return {}
    case ECO_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryListReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_GETLIST_REQUEST:
      return { loading: true }
    case CATEGORY_GETLIST_SUCCESS:
      return {
        loading: false,
        success: true,
        categories: action.payload,
      }
    case CATEGORY_GETLIST_RESET:
      return {}
    case CATEGORY_GETLIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case CATE_BYID_REQUEST:
      return { loading: true }
    case CATE_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        category: action.payload.customer,
      }
    case CATE_BYID_RESET:
      return {}
    case CATE_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const createCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_CREATE_REQUEST:
      return { loading: true }
    case CATEGORY_CREATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case CATEGORY_CREATE_RESET:
      return {}
    case CATEGORY_CREATE_ERROR:
      return { error: 'one of required fields is empty' }
    case CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_UPDATE_REQUEST:
      return { loading: true }
    case CATEGORY_UPDATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case CATEGORY_UPDATE_RESET:
      return {}
    case CATEGORY_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_DELETE_REQUEST:
      return { loading: true }
    case CATEGORY_DELETE_SUCCESS:
      return { loading: false, success: true }
    case CATEGORY_DELETE_RESET:
      return {}
    case CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pppTableStateReducer = (state = { onEditMode: true }, action) => {
  switch (action.type) {
    case PPPTABLE_EDITMODE_RESET:
      return { onEditMode: false }
    case PPPTABLE_EDITMODE_SET:
      return { onEditMode: true }
    default:
      return state
  }
}

export const parentCategoryReducer = (state = {}, action) => {
  switch (action.type) {
    case PARENT_CATEGORY_REQUEST:
      return { loading: true }
    case PARENT_CATEGORY_SUCCESS:
      return {
        loading: false,
        success: true,
        parents: action.payload,
      }
    case PARENT_CATEGORY_RESET:
      return {}
    case PARENT_CATEGORY_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case PROD_BYID_REQUEST:
      return { loading: true }
    case PROD_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        product: action.payload,
      }
    case PROD_BYID_RESET:
      return {}
    case PROD_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_CREATE_REQUEST:
      return { loading: true }
    case PRODUCT_CREATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case PRODUCT_CREATE_RESET:
      return {}
    case PRODUCT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case PRODUCT_UPDATE_ERROR:
      return { error: 'One of required field is missing' }
    case PRODUCT_UPDATE_RESET:
      return {}
    case PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODGROUP_BYID_REQUEST:
      return { loading: true }
    case PRODGROUP_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        prodGroup: action.payload,
      }
    case PRODGROUP_BYID_RESET:
      return {}
    case PRODGROUP_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODGROUP_UPDATE_REQUEST:
      return { loading: true }
    case PRODGROUP_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case PRODGROUP_UPDATE_ERROR:
      return { error: 'Something went wrong. Please try again.' }
    case PRODGROUP_UPDATE_RESET:
      return {}
    case PRODGROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODGROUP_CREATE_REQUEST:
      return { loading: true }
    case PRODGROUP_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case PRODGROUP_CREATE_ERROR:
      return { error: 'Description field is missing.' }
    case PRODGROUP_CREATE_RESET:
      return {}
    case PRODGROUP_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupLoginAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PGLOGIN_ADD_REQUEST:
      return { loading: true }
    case PGLOGIN_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case PGLOGIN_ADD_RESET:
      return {}
    case PGLOGIN_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupLoginRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case PGLOGIN_REMOVE_REQUEST:
      return { loading: true }
    case PGLOGIN_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case PGLOGIN_REMOVE_RESET:
      return {}
    case PGLOGIN_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupCategoryAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PGCATEGORY_ADD_REQUEST:
      return { loading: true }
    case PGCATEGORY_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case PGCATEGORY_ADD_RESET:
      return {}
    case PGCATEGORY_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productGroupCategoryRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case PGCATEGORY_REMOVE_REQUEST:
      return { loading: true }
    case PGCATEGORY_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case PGCATEGORY_REMOVE_RESET:
      return {}
    case PGCATEGORY_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const fileUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case FILE_UPLOAD_REQUEST:
      return { loading: true }
    case FILE_UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case FILE_UPLOAD_ERROR:
      return { error: 'one of required field is missing' }
    case FILE_UPLOAD_RESET:
      return {}
    case FILE_UPLOAD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productVariantUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_VARIANT_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_VARIANT_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_VARIANT_UPDATE_RESET:
      return {}
    case PRODUCT_VARIANT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productVariantDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_VARIANT_DELETE_REQUEST:
      return { loading: true }
    case PRODUCT_VARIANT_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRODUCT_VARIANT_DELETE_RESET:
      return {}
    case PRODUCT_VARIANT_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const masterVariantCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MASTER_VARIANT_CREATE_REQUEST:
      return { loading: true }
    case MASTER_VARIANT_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        master: action.payload,
      }
    case MASTER_VARIANT_CREATE_RESET:
      return {}
    case MASTER_VARIANT_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addUpdateVarianceReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_VARIANCE_CREATE_REQUEST:
      return { loading: true }
    case UPDATE_VARIANCE_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        variance: action.payload,
      }
    case UPDATE_VARIANCE_CREATE_RESET:
      return {}
    case UPDATE_VARIANCE_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const varianceUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANCE_UPDATE_REQUEST:
      return { loading: true }
    case VARIANCE_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case VARIANCE_UPDATE_RESET:
      return {}
    case VARIANCE_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const varianceDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case VARIANCE_DELETE_REQUEST:
      return { loading: true }
    case VARIANCE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case VARIANCE_DELETE_RESET:
      return {}
    case VARIANCE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const quickOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case QUICK_ORDER_CREATE_REQUEST:
      return { loading: true }
    case QUICK_ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, submission: action.payload }
    case QUICK_ORDER_CREATE_RESET:
      return {}
    case QUICK_ORDER_CREATE_ERROR_REQUIRE:
      return { error: 'One or more of the required fields are not filled.' }
    case QUICK_ORDER_CREATE_ERROR_QUANTITY:
      return {
        error:
          'Quantities exceed the max quantity for this order or no quantity selected. Please try again.',
      }
    case QUICK_ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const multiOrdersCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MULTI_ORDERS_CREATE_REQUEST:
      return { loading: true }
    case MULTI_ORDERS_CREATE_SUCCESS:
      return { loading: false, success: true, submission: action.payload }
    case MULTI_ORDERS_CREATE_RESET:
      return {}
    /*    case MULTI_ORDERS_CREATE_ERROR_REQUIRE:
      return { error: "One or more of the required fields are not filled." }
    case MULTI_ORDERS_CREATE_ERROR_QUANTITY:
      return {
        error:
          "Quantities exceed the max quantity for this order or no quantity selected. Please try again.",
      }*/
    case MULTI_ORDERS_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderCancelReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CANCEL_REQUEST:
      return { loading: true }
    case ORDER_CANCEL_SUCCESS:
      return { loading: false, success: true, submission: action.payload }
    case ORDER_CANCEL_RESET:
      return {}
    case ORDER_CANCEL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addressBookReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_BOOK_GET_REQUEST:
      return { loading: true }
    case ADDRESS_BOOK_GET_SUCCESS:
      return { loading: false, success: true, addresses: action.payload }
    case ADDRESS_BOOK_GET_RESET:
      return {}
    case ADDRESS_BOOK_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addressBookCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_BOOK_CREATE_REQUEST:
      return { loading: true }
    case ADDRESS_BOOK_CREATE_SUCCESS:
      return { loading: false, success: true, newRow: action.payload.newRow }
    case ADDRESS_BOOK_CREATE_RESET:
      return {}
    case ADDRESS_BOOK_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addressBookDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_BOOK_DELETE_REQUEST:
      return { loading: true }
    case ADDRESS_BOOK_DELETE_SUCCESS:
      return { loading: false, success: true }
    case ADDRESS_BOOK_DELETE_RESET:
      return {}
    case ADDRESS_BOOK_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addressBookUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_BOOK_UPDATE_REQUEST:
      return { loading: true }
    case ADDRESS_BOOK_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case ADDRESS_BOOK_UPDATE_RESET:
      return {}
    case ADDRESS_BOOK_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const addressBookByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case ADDRESS_BOOK_BYID_REQUEST:
      return { loading: true }
    case ADDRESS_BOOK_BYID_SUCCESS:
      return { loading: false, address: action.payload, success: true }
    case ADDRESS_BOOK_BYID_RESET:
      return {}
    case ADDRESS_BOOK_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_UPDATE_REQUEST:
      return { loading: true }
    case ORDER_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_UPDATE_RESET:
      return {}
    case ORDER_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const mailingListDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MAILING_LIST_DELETE_REQUEST:
      return { loading: true }
    case MAILING_LIST_DELETE_SUCCESS:
      return { loading: false, success: true }
    case MAILING_LIST_DELETE_RESET:
      return {}
    case MAILING_LIST_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const mailingListByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case MAILING_LIST_BYID_REQUEST:
      return { loading: true }
    case MAILING_LIST_BYID_SUCCESS:
      return { loading: false, mail: action.payload, success: true }
    case MAILING_LIST_BYID_RESET:
      return {}
    case MAILING_LIST_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const managerMemberByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case MANAGER_MEMBER_BYID_REQUEST:
      return { loading: true }
    case MANAGER_MEMBER_BYID_SUCCESS:
      return { loading: false, manager: action.payload, success: true }
    case MANAGER_MEMBER_BYID_RESET:
      return {}
    case MANAGER_MEMBER_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const managerMemberLoginAddReducer = (state = {}, action) => {
  switch (action.type) {
    case MANAGER_MEMLOGIN_ADD_REQUEST:
      return { loading: true }
    case MANAGER_MEMLOGIN_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case MANAGER_MEMLOGIN_ADD_RESET:
      return {}
    case MANAGER_MEMLOGIN_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const managerMemberLoginRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case MANAGER_MEMLOGIN_REMOVE_REQUEST:
      return { loading: true }
    case MANAGER_MEMLOGIN_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case MANAGER_MEMLOGIN_REMOVE_RESET:
      return {}
    case MANAGER_MEMLOGIN_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const mailingListCSVUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case MAILINGLIST_CSV_UPLOAD_REQUEST:
      return { loading: true }
    case MAILINGLIST_CSV_UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case MAILINGLIST_CSV_UPLOAD_RESET:
      return {}
    case MAILINGLIST_CSV_UPLOAD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const ordersCSVUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDERS_CSV_UPLOAD_REQUEST:
      return { loading: true }
    case ORDERS_CSV_UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        referenceno2: action.payload.referenceno2,
        rejects: action.payload.rejects,
      }
    case ORDERS_CSV_UPLOAD_RESET:
      return {}
    case ORDERS_CSV_UPLOAD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const priceGroupByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_MEMBER_BYID_REQUEST:
      return { loading: true }
    case PRICEGROUP_MEMBER_BYID_SUCCESS:
      return { loading: false, priceGroup: action.payload, success: true }
    case PRICEGROUP_MEMBER_BYID_RESET:
      return {}
    case PRICEGROUP_MEMBER_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const priceGroupLoginAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_MEMLOGIN_ADD_REQUEST:
      return { loading: true }
    case PRICEGROUP_MEMLOGIN_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case PRICEGROUP_MEMLOGIN_ADD_RESET:
      return {}
    case PRICEGROUP_MEMLOGIN_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const priceGroupLoginRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_MEMLOGIN_REMOVE_REQUEST:
      return { loading: true }
    case PRICEGROUP_MEMLOGIN_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case PRICEGROUP_MEMLOGIN_REMOVE_RESET:
      return {}
    case PRICEGROUP_MEMLOGIN_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const priceGroupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_UPDATE_REQUEST:
      return { loading: true }
    case PRICEGROUP_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case PRICEGROUP_UPDATE_ERROR:
      return { error: 'Something went wrong. Please try again.' }
    case PRICEGROUP_UPDATE_RESET:
      return {}
    case PRICEGROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const priceGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_CREATE_REQUEST:
      return { loading: true }
    case PRICEGROUP_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case PRICEGROUP_CREATE_ERROR:
      return { error: 'Description field is missing.' }
    case PRICEGROUP_CREATE_RESET:
      return {}
    case PRICEGROUP_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userGroupByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case USERGROUP_BYID_REQUEST:
      return { loading: true }
    case USERGROUP_BYID_SUCCESS:
      return { loading: false, userGroup: action.payload, success: true }
    case USERGROUP_BYID_RESET:
      return {}
    case USERGROUP_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userGroupUserAddReducer = (state = {}, action) => {
  switch (action.type) {
    case USERGROUP_USER_ADD_REQUEST:
      return { loading: true }
    case USERGROUP_USER_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        addElement: action.payload.addElement,
      }
    case USERGROUP_USER_ADD_RESET:
      return {}
    case USERGROUP_USER_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userGroupUserRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case USERGROUP_USER_REMOVE_REQUEST:
      return { loading: true }
    case USERGROUP_USER_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
        removeElement: action.payload.removeElement,
      }
    case USERGROUP_USER_REMOVE_RESET:
      return {}
    case USERGROUP_USER_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userGroupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case USERGROUP_UPDATE_REQUEST:
      return { loading: true }
    case USERGROUP_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case USERGROUP_UPDATE_ERROR:
      return { error: 'Something went wrong. Please try again.' }
    case USERGROUP_UPDATE_RESET:
      return {}
    case USERGROUP_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const userGroupCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case USERGROUP_CREATE_REQUEST:
      return { loading: true }
    case USERGROUP_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case USERGROUP_CREATE_ERROR:
      return { error: 'Description field is missing.' }
    case USERGROUP_CREATE_RESET:
      return {}
    case USERGROUP_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const imageDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_DELETE_REQUEST:
      return { loading: true }
    case IMAGE_DELETE_SUCCESS:
      return { loading: false, success: true }
    case IMAGE_DELETE_RESET:
      return {}
    case IMAGE_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const imageUserAddReducer = (state = {}, action) => {
  switch (action.type) {
    case IMAGE_USER_ADD_REQUEST:
      return { loading: true }
    case IMAGE_USER_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case IMAGE_USER_ADD_ERROR:
      return { error: 'Please select a user to add.' }
    case IMAGE_USER_ADD_RESET:
      return {}
    case IMAGE_USER_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const categoryTreeReducer = (state = {}, action) => {
  switch (action.type) {
    case CATEGORY_TREE_GET_REQUEST:
      return { loading: true }
    case CATEGORY_TREE_GET_SUCCESS:
      return {
        loading: false,
        tree: action.payload.tree,
        products: action.payload.products,
        productCount: action.payload.productCount,
        variantView: action.payload.variantView,
        success: true,
      }
    case CATEGORY_TREE_GET_RESET:
      return {}
    case CATEGORY_TREE_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const iLinkCategoryTreeReducer = (state = {}, action) => {
  switch (action.type) {
    case ILINK_CATEGORY_TREE_GET_REQUEST:
      return { loading: true }
    case ILINK_CATEGORY_TREE_GET_SUCCESS:
      return {
        loading: false,
        tree: action.payload.tree,
        products: action.payload.products,
        success: true,
      }
    case ILINK_CATEGORY_TREE_GET_RESET:
      return {}
    case ILINK_CATEGORY_TREE_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productPricingByIDReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PRICING_BYID_REQUEST:
      return { loading: true }
    case PRODUCT_PRICING_BYID_SUCCESS:
      return {
        loading: false,
        success: true,
        pricingInfo: action.payload,
      }
    case PRODUCT_PRICING_BYID_RESET:
      return {}
    case PRODUCT_PRICING_BYID_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productPricingUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_PRICING_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_PRICING_UPDATE_SUCCESS:
      return { loading: false, success: true, pricingInfo: action.payload }
    case PRODUCT_PRICING_UPDATE_RESET:
      return {}
    case PRODUCT_PRICING_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const cartGetReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_GET_REQUEST:
      return { loading: true }
    case CART_GET_SUCCESS:
      return {
        loading: false,
        cart: action.payload,
        success: true,
        //lock: action.payload.ShipTo ? true : false,
      }
    // case CART_GET_LOCK:
    //   return { lock: true }
    case CART_GET_RESET:
      return {}
    case CART_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const checkoutStepReducer = (state = { step: STEP_INIT }, action) => {
  switch (action.type) {
    case CHECKOUT_STEP_INIT:
      return { ...state, step: STEP_INIT }
    case CHECKOUT_STEP_SHIPTO:
      return {
        step: STEP_SHIPTO,
        shipCountry: action.payload.shipCountry,
      }
    case CHECKOUT_STEP_BILLTO:
      return {
        ...state,
        step: STEP_BILLTO,
        billCountry: action.payload.billCountry,
      }
    case CHECKOUT_STEP_PAYMENT:
      return { ...state, step: STEP_PAYMENT }
    case CHECKOUT_STEP_PLACE_ORDER:
      return { ...state, step: STEP_PLACE_ORDER }
    case CHECKOUT_STEP_ORDER_COMPLETE:
      return { ...state, step: STEP_ORDER_COMPLETE }
    default:
      return state
  }
}

export const cartProductAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_PRODUCT_ADD_REQUEST:
      return { loading: true }
    case CART_PRODUCT_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CART_PRODUCT_ADD_RESET:
      return {}
    case CART_PRODUCT_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const cartProductRemoveReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_PRODUCT_REMOVE_REQUEST:
      return { loading: true }
    case CART_PRODUCT_REMOVE_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CART_PRODUCT_REMOVE_RESET:
      return {}
    case CART_PRODUCT_REMOVE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const favoritesReducer = (state = [], action) => {
  switch (action.type) {
    case TOGGLE_FAVORITE_REQUEST:
      return { loading: true }
    case TOGGLE_FAVORITE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        result: {
          productId: action.payload.productId,
          favorite: action.payload.command,
        },
      }
    case TOGGLE_FAVORITE_FAIL:
      return { ...state, loading: false, error: action.payload }
    case TOGGLE_FAVORITE_RESET:
      return {}
    default:
      return state
  }
}

export const createCheckoutOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECKOUT_ORDER_CREATE_REQUEST:
      return { loading: true }
    case CHECKOUT_ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, complete: action.payload }
    case CHECKOUT_ORDER_CREATE_RESET:
      return {}
    case CHECKOUT_ORDER_CREATE_ERROR:
      return { error: 'Could not complete checkout.' }
    case CHECKOUT_ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderFormGetReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_FORM_GET_REQUEST:
      return { loading: true }
    case ORDER_FORM_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        form: action.payload,
      }
    case ORDER_FORM_GET_RESET:
      return {}
    case ORDER_FORM_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateCartProductReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_PRODUCT_UPDATE_REQUEST:
      return { loading: true }
    case CART_PRODUCT_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case CART_PRODUCT_UPDATE_RESET:
      return {}
    case CART_PRODUCT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const cartProductListAddReducer = (state = {}, action) => {
  switch (action.type) {
    case CART_PRODLIST_ADD_REQUEST:
      return { loading: true }
    case CART_PRODLIST_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CART_PRODLIST_ADD_RESET:
      return {}
    case CART_PRODLIST_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const currentSessionSetReducer = (state = {}, action) => {
  switch (action.type) {
    case CURRENT_SESSION_SET_REQUEST:
      return { loading: true }
    case CURRENT_SESSION_SET_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case CURRENT_SESSION_SET_RESET:
      return {}
    case CURRENT_SESSION_SET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const formsByProductIdsGetReducer = (state = {}, action) => {
  switch (action.type) {
    case FORMS_BYPRODUCTIDS_GET_REQUEST:
      return { loading: true }
    case FORMS_BYPRODUCTIDS_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        forms: action.payload,
      }
    case FORMS_BYPRODUCTIDS_GET_RESET:
      return {}
    case FORMS_BYPRODUCTIDS_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productToPricingGroupAddReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_PROD_ADD_REQUEST:
      return { loading: true }
    case PRICEGROUP_PROD_ADD_SUCCESS:
      return {
        loading: false,
        success: true,
      }
    case PRICEGROUP_PROD_ADD_RESET:
      return {}
    case PRICEGROUP_PROD_ADD_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productToPricingGroupUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_PROD_UPDATE_REQUEST:
      return { loading: true }
    case PRICEGROUP_PROD_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case PRICEGROUP_PROD_UPDATE_RESET:
      return {}
    case PRICEGROUP_PROD_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productFromPricingGroupDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case PRICEGROUP_PROD_DELETE_REQUEST:
      return { loading: true }
    case PRICEGROUP_PROD_DELETE_SUCCESS:
      return { loading: false, success: true }
    case PRICEGROUP_PROD_DELETE_RESET:
      return {}
    case PRICEGROUP_PROD_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productQuotaUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_QUOTA_UPDATE_REQUEST:
      return { loading: true }
    case PRODUCT_QUOTA_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        newRow: action.payload.newRow,
      }
    case PRODUCT_QUOTA_UPDATE_RESET:
      return {}
    case PRODUCT_QUOTA_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const productQuotaGetReducer = (state = {}, action) => {
  switch (action.type) {
    case PRODUCT_QUOTA_GET_REQUEST:
      return { loading: true }
    case PRODUCT_QUOTA_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        quota: action.payload,
      }
    case PRODUCT_QUOTA_GET_RESET:
      return {}
    case PRODUCT_QUOTA_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
export const passwordPolicyUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_POLICY_UPDATE_REQUEST:
      return { loading: true }
    case PASSWORD_POLICY_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        policy: action.payload,
      }
    case PASSWORD_POLICY_UPDATE_RESET:
      return {}
    case PASSWORD_POLICY_UPDATE_FAILURE:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const passwordPolicyGetRedcuer = (state = {}, action) => {
  switch (action.type) {
    case PASSWORD_POLICY_GET_REQUEST:
      return { loading: true }
    case PASSWORD_POLICY_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        policy: action.payload,
      }
    case PASSWORD_POLICY_GET_RESET:
      return {}
    case PASSWORD_POLICY_GET_FAILURE:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const captureCustJSONReducer = (state = {}, action) => {
  switch (action.type) {
    case CAPTURE_CUSTJSON_GET_REQUEST:
      return { loading: true }
    case CAPTURE_CUSTJSON_GET_SUCCESS:
      return {
        loading: false,
        success: true,
        preview: action.payload,
      }
    case CAPTURE_CUSTJSON_GET_RESET:
      return {}
    case CAPTURE_CUSTJSON_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const checkAddressUSPSReducer = (state = {}, action) => {
  switch (action.type) {
    case CHECK_ADDRESS_USPS_REQUEST:
      return { loading: true }
    case CHECK_ADDRESS_USPS_SUCCESS:
      return { loading: false, success: true, check: action.payload }
    case CHECK_ADDRESS_USPS_RESET:
      return {}
    case CHECK_ADDRESS_USPS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getOFFormDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFORM_DETAIL_GET_REQUEST:
      return { loading: true }
    case OFFORM_DETAIL_GET_SUCCESS:
      return { loading: false, success: true, formDetail: action.payload }
    case OFFORM_DETAIL_GET_RESETT:
      return {}
    case OFFORM_DETAIL_GET_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const updateOFFormDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case OFFORM_DETAIL_UPDATE_REQUEST:
      return { loading: true }
    case OFFORM_DETAIL_UPDATE_SUCCESS:
      return { loading: false, success: true }
    case OFFORM_DETAIL_UPDATE_RESET:
      return {}
    case OFFORM_DETAIL_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getOrderDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DETAIL_REQUEST:
      return { loading: true }
    case ORDER_DETAIL_SUCCESS:
      return { loading: false, success: true, order: action.payload.order }
    case ORDER_DETAIL_RESET:
      return {}
    case ORDER_DETAIL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const MMBookCoverCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MMBOOK_COVER_CREATE_REQUEST:
      return { loading: true }
    case MMBOOK_COVER_CREATE_SUCCESS:
      return { loading: false, success: true, data: action.payload.data }
    case MMBOOK_COVER_CREATE_RESET:
      return {}
    case MMBOOK_COVER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const MMBookOrderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MMBOOK_CREATE_REQUEST:
      return { loading: true }
    case MMBOOK_CREATE_SUCCESS:
      return { loading: false, success: true, data: action.payload }
    case MMBOOK_CREATE_RESET:
      return {}
    case MMBOOK_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const customBookUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case CUSTOM_BOOK_UPDATE_REQUEST:
      return { loading: true }
    case CUSTOM_BOOK_UPDATE_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
        newRow: action.payload.newRow,
      }
    case CUSTOM_BOOK_UPDATE_RESET:
      return {}
    case CUSTOM_BOOK_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const MMBookUploadReducer = (state = {}, action) => {
  switch (action.type) {
    case MMBOOK_UPLOAD_REQUEST:
      return {
        loading: true,
      }
    case MMBOOK_UPLOAD_SUCCESS:
      return {
        loading: false,
        success: true,
        data: action.payload,
      }
    case MMBOOK_UPLOAD_FAIL:
      return {
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export const macMillianBookReducer = (state = {}, action) => {
  switch (action.type) {
    case MMBOOK_ISBN_EXIST_ERROR:
      return {
        error: 'ISBN number exists',
      }
    case MMBOOK_ISBN_VALIDATE_ERROR:
      return {
        error: 'ISBN number might be too short',
      }
    case MMBOOK_COVER_SIZE_ERROR:
      return {
        ...state,
        error0:
          'cover pdf does not meet one of book specification(5.375x8.25, 6.125x9.25 or 6x9)',
      }
    case MMBOOK_COVER_RESET:
      return {
        ...state,
        error0: null,
      }
    case MMBOOK_TEXT_SIZE_ERROR:
      return {
        ...state,
        error1:
          'text pdf does not meet one of book specification(5.375x8.25, 6.125x9.25 or 6x9)',
      }
    case MMBOOK_TEXT_RESET:
      return {
        ...state,
        error1: null,
      }
    case MMBOOK_ERROR_RESET:
      return {}
    case MMBOOK_RESET_REQUEST:
      return { request: true }
    default:
      return state
  }
}

export const kitUpdateReducer = (state = {}, action) => {
  switch (action.type) {
    case KIT_UPDATE_REQUEST:
      return { loading: true }
    case KIT_UPDATE_SUCCESS:
      return { loading: false, success: true, pricingInfo: action.payload }
    case KIT_UPDATE_RESET:
      return {}
    case KIT_UPDATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const logoDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case LOGO_DELETE_REQUEST:
      return { loading: true }
    case LOGO_DELETE_SUCCESS:
      return { loading: false, success: true, pricingInfo: action.payload }
    case LOGO_DELETE_RESET:
      return {}
    case LOGO_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
