export const FORM_GET_REQUEST = 'FORM_GET_REQUEST'
export const FORM_GET_SUCCESS = 'FORM_GET_SUCCESS'
export const FORM_GET_RESET = 'FORM_GET_RESET'
export const FORM_GET_FAIL = 'FORM_GET_FAIL'
export const FORM_RESET_SUCCESS = 'FORM_RESET_SUCCESS'

export const WEBFORM_GET_REQUEST = 'WEBFORM_GET_REQUEST'
export const WEBFORM_GET_SUCCESS = 'WEBFORM_GET_SUCCESS'
export const WEBFORM_GET_RESET = 'WEBFORM_GET_RESET'
export const WEBFORM_GET_FAIL = 'WEBFORM_GET_FAIL'
export const WEBFORM_RESET_SUCCESS = 'WEBFORM_RESET_SUCCESS'
export const WEBFORM_REFRESH_SUCCESS = 'WEBFORM_REFRESH_SUCCESS'
export const WEBFORM_REFRESH_REQUEST = 'WEBFORM_REFRESH_REQUEST'
export const WEBFORM_RESET_PAYMENT = 'WEBFORM_RESET_PAYMENT'

export const REPORT_GET_REQUEST = 'REPORT_GET_REQUEST'
export const REPORT_GET_SUCCESS = 'REPORT_GET_SUCCESS'
export const REPORT_GET_RESET = 'REPORT_GET_RESET'
export const REPORT_GET_FAIL = 'REPORT_GET_FAIL'

export const REPORTS_IDS_GET_REQUEST = 'REPORTS_IDS_GET_REQUEST'
export const REPORTS_IDS_GET_SUCCESS = 'REPORTS_IDS_GET_SUCCESS'
export const REPORTS_IDS_GET_RESET = 'REPORTS_IDS_GET_RESET'
export const REPORTS_IDS_GET_FAIL = 'REPORTS_IDS_GET_FAIL'

export const QFORM_GET_REQUEST = 'QFORM_GET_REQUEST'
export const QFORM_GET_SUCCESS = 'QFORM_GET_SUCCESS'
export const QFORM_GET_RESET = 'QFORM_GET_RESET'
export const QFORM_GET_FAIL = 'QFORM_GET_FAIL'

export const QFORMS_GET_REQUEST = 'QFORMS_GET_REQUEST'
export const QFORMS_GET_SUCCESS = 'QFORMS_GET_SUCCESS'
export const QFORMS_GET_RESET = 'QFORMS_GET_RESET'
export const QFORMS_GET_FAIL = 'QFORMS_GET_FAIL'
export const QFORMS_GET_ERROR_QUANTITY = 'QFORMS_GET_ERROR_QUANTITY'
export const QFORMS_GET_ERROR_REQUIRE = 'QFORMS_GET_ERROR_REQUIRE'

export const STATES_GET_REQUEST = 'STATES_GET_REQUEST'
export const STATES_GET_SUCCESS = 'STATES_GET_SUCCESS'
export const STATES_GET_RESET = 'STATES_GET_RESET'
export const STATES_GET_FAIL = 'STATES_GET_FAIL'

export const COUNTRIES_GET_REQUEST = 'COUNTRIES_GET_REQUEST'
export const COUNTRIES_GET_SUCCESS = 'COUNTRIES_GET_SUCCESS'
export const COUNTRIES_GET_RESET = 'COUNTRIES_GET_RESET'
export const COUNTRIES_GET_FAIL = 'COUNTRIES_GET_FAIL'

export const FORM_SET_LOCK = 'FORM_SET_LOCK'
export const FORM_SET_UNLOCK = 'FORM_SET_UNLOCK'
