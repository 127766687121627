import React from 'react'

const Checkbox = ({ id, type, name, handleClick, value, isChecked }) => {
  return (
    <input
      id={id}
      name={name}
      type={type}
      defaultValue={value}
      onChange={handleClick}
      checked={isChecked}
    />
  )
}

export default Checkbox
