import axios from 'axios'
import {
  GET_PICK_TICKET_REQUEST,
  GET_PICK_TICKET_SUCCESS,
  GET_PICK_TICKET_FAIL,
  GET_ORDER_BYID_REQUEST,
  GET_ORDER_BYID_SUCCESS,
  GET_ORDER_BYID_FAIL,
  ORDER_ROUTING_LIST_REQUEST,
  ORDER_ROUTING_LIST_SUCCESS,
  ORDER_ROUTING_LIST_FAIL,
  ORDER_ROUTING_ROW_REQUEST,
  ORDER_ROUTING_ROW_SUCCESS,
  ORDER_ROUTING_ROW_FAIL,
  ORDER_ROUTING_DELETE_REQUEST,
  ORDER_ROUTING_DELETE_SUCCESS,
  ORDER_ROUTING_DELETE_FAIL,
  ORDER_ROUTING_CREATE_REQUEST,
  ORDER_ROUTING_CREATE_SUCCESS,
  ORDER_ROUTING_CREATE_FAIL,
  GET_CONTRACT_BYACC_REQUEST,
  GET_CONTRACT_BYACC_SUCCESS,
  GET_CONTRACT_BYACC_FAIL,
  GET_CONTRACT_BYID_REQUEST,
  GET_CONTRACT_BYID_SUCCESS,
  GET_CONTRACT_BYID_FAIL,
  DEL_CONTRACT_REQUEST,
  DEL_CONTRACT_SUCCESS,
  DEL_CONTRACT_FAIL,
  UPDATE_CONTRACT_REQUEST,
  UPDATE_CONTRACT_SUCCESS,
  UPDATE_CONTRACT_FAIL,
  CREATE_CONTRACT_REQUEST,
  CREATE_CONTRACT_SUCCESS,
  CREATE_CONTRACT_FAIL,
  CREATE_CON_OPCODE_REQUEST,
  CREATE_CON_OPCODE_SUCCESS,
  CREATE_CON_OPCODE_FAIL,
  DEL_CON_OPCODE_REQUEST,
  DEL_CON_OPCODE_SUCCESS,
  DEL_CON_OPCODE_FAIL,
  CUSTOM_LETTER_ROW_REQUEST,
  CUSTOM_LETTER_ROW_SUCCESS,
  CUSTOM_LETTER_ROW_FAIL,
  CUSTOM_LETTER_CREATE_REQUEST,
  CUSTOM_LETTER_CREATE_SUCCESS,
  CUSTOM_LETTER_CREATE_FAIL,
  CUSTOM_LETTER_DELETE_REQUEST,
  CUSTOM_LETTER_DELETE_SUCCESS,
  CUSTOM_LETTER_DELETE_FAIL,
  CUSTOM_LETTER_GET_REQUEST,
  CUSTOM_LETTER_GET_SUCCESS,
  CUSTOM_LETTER_GET_FAIL,
  IMPORT_FILTER_GET_REQUEST,
  IMPORT_FILTER_GET_SUCCESS,
  IMPORT_FILTER_GET_FAIL,
  GET_ORDFORM_BYACC_REQUEST,
  GET_ORDFORM_BYACC_SUCCESS,
  GET_ORDFORM_BYACC_FAIL,
  GET_ORDFORM_BYID_REQUEST,
  GET_ORDFORM_BYID_SUCCESS,
  GET_ORDFORM_BYID_FAIL,
  UPDATE_ORDERFORM_REQUEST,
  UPDATE_ORDERFORM_FAIL,
  UPDATE_ORDERFORM_SUCCESS,
  CREATE_ORDFORM_FIELD_REQUEST,
  CREATE_ORDFORM_FIELD_SUCCESS,
  CREATE_ORDFORM_FIELD_FAIL,
  DEL_ORDFORM_FIELD_REQUEST,
  DEL_ORDFORM_FIELD_SUCCESS,
  DEL_ORDFORM_FIELD_FAIL,
  CREATE_ORDFORM_PROD_REQUEST,
  CREATE_ORDFORM_PROD_SUCCESS,
  CREATE_ORDFORM_PROD_FAIL,
  DEL_ORDFORM_PROD_REQUEST,
  DEL_ORDFORM_PROD_SUCCESS,
  DEL_ORDFORM_PROD_FAIL,
  CREATE_ORDFORM_LOGIN_REQUEST,
  CREATE_ORDFORM_LOGIN_SUCCESS,
  CREATE_ORDFORM_LOGIN_FAIL,
  DEL_ORDFORM_LOGIN_REQUEST,
  DEL_ORDFORM_LOGIN_SUCCESS,
  DEL_ORDFORM_LOGIN_FAIL,
  UPDATE_ORDFORM_FIELD_REQUEST,
  UPDATE_ORDFORM_FIELD_SUCCESS,
  UPDATE_ORDFORM_FIELD_FAIL,
  CREATE_ORDERFORM_REQUEST,
  CREATE_ORDERFORM_SUCCESS,
  CREATE_ORDERFORM_FAIL,
  DEL_ORDERFORM_REQUEST,
  DEL_ORDERFORM_SUCCESS,
  DEL_ORDERFORM_FAIL,
  UPDATE_IMP_FILTER_REQUEST,
  UPDATE_IMP_FILTER_SUCCESS,
  UPDATE_IMP_FILTER_FAIL,
  BCKORD_TO_SHIPPED_SUCCESS,
  BCKORD_TO_SHIPPED_REQUEST,
  BCKORD_TO_SHIPPED_FAIL,
  CREATE_IMP_FILTER_REQUEST,
  CREATE_IMP_FILTER_SUCCESS,
  CREATE_IMP_FILTER_FAIL,
  DEL_IMP_FILTER_REQUEST,
  DEL_IMP_FILTER_SUCCESS,
  DEL_IMP_FILTER_FAIL,
  ORDER_CONTRACT_UPDATE_SUCCESS,
  ORDER_CONTRACT_UPDATE_REQUEST,
  ORDER_CONTRACT_UPDATE_FAIL,
  CARRIER_VIA_GET_SUCCESS,
  CARRIER_VIA_GET_REQUEST,
  CARRIER_VIA_GET_FAIL,
  BATCH_CARRIER_UPDATE_SUCCESS,
  BATCH_CARRIER_UPDATE_REQUEST,
  BATCH_CARRIER_UPDATE_FAIL,
  BATCH_VERIFY_UPDATE_SUCCESS,
  BATCH_VERIFY_UPDATE_REQUEST,
  BATCH_VERIFY_UPDATE_FAIL,
  BCKORD_TO_NEW_SUCCESS,
  BCKORD_TO_NEW_REQUEST,
  BCKORD_TO_NEW_FAIL,
  GET_CUST_BYACC_REQUEST,
  GET_CUST_BYACC_SUCCESS,
  GET_CUST_BYACC_FAIL,
  GET_ADDR_BYCUS_REQUEST,
  GET_ADDR_BYCUS_SUCCESS,
  GET_ADDR_BYCUS_FAIL,
  UPDATE_ADDR_REQUEST,
  UPDATE_ADDR_SUCCESS,
  UPDATE_ADDR_FAIL,
  CREATE_ADDR_REQUEST,
  CREATE_ADDR_SUCCESS,
  CREATE_ADDR_FAIL,
  DEL_CUST_SUCCESS,
  DEL_CUST_FAIL,
  DEL_CUST_REQUEST,
  DEL_ADDR_REQUEST,
  DEL_ADDR_SUCCESS,
  DEL_ADDR_FAIL,
  UPDATE_CUST_REQUEST,
  UPDATE_CUST_FAIL,
  CREATE_CUST_REQUEST,
  CREATE_CUST_SUCCESS,
  CREATE_CUST_FAIL,
  UPDATE_CUST_SUCCESS,
  GET_CUST_BYID_REQUEST,
  GET_CUST_BYID_SUCCESS,
  GET_CUST_BYID_FAIL,
  GET_WIDGET_REQUEST,
  GET_WIDGET_SUCCESS,
  GET_WIDGET_FAIL,
  BADGE_GET_REQUEST,
  BADGE_GET_SUCCESS,
  BADGE_GET_FAIL,
  BADGE_DELETE_REQUEST,
  BADGE_DELETE_SUCCESS,
  BADGE_DELETE_FAIL,
  BADGE_CREATE_REQUEST,
  BADGE_CREATE_SUCCESS,
  BADGE_CREATE_FAIL,
  BADGE_FILE_CREATE_REQUEST,
  BADGE_FILE_CREATE_SUCCESS,
  BADGE_FILE_CREATE_FAIL,
  LOGIN_LIST_REQUEST,
  LOGIN_LIST_SUCCESS,
  LOGIN_LIST_FAIL,
  LOGIN_UPDATE_REQUEST,
  LOGIN_UPDATE_SUCCESS,
  LOGIN_UPDATE_FAIL,
  LOGIN_CREATE_REQUEST,
  LOGIN_CREATE_SUCCESS,
  LOGIN_CREATE_FAIL,
  LOGIN_DELETE_REQUEST,
  LOGIN_DELETE_SUCCESS,
  LOGIN_DELETE_FAIL,
  MENU_GET_REQUEST,
  MENU_GET_SUCCESS,
  MENU_GET_FAIL,
  MENU_SET_REQUEST,
  MENU_SET_SUCCESS,
  MENU_SET_FAIL,
  PRODUCTLIST_REPORT_SUCCESS,
  PRODUCTLIST_REPORT_FAIL,
  PRODUCTLIST_REPORT_REQUEST,
  ECO_GET_REQUEST,
  ECO_GET_SUCCESS,
  ECO_GET_FAIL,
  ECO_CREATE_REQUEST,
  ECO_CREATE_SUCCESS,
  ECO_CREATE_FAIL,
  CATE_BYID_REQUEST,
  CATE_BYID_SUCCESS,
  CATE_BYID_FAIL,
  CATEGORY_CREATE_REQUEST,
  CATEGORY_CREATE_SUCCESS,
  CATEGORY_CREATE_FAIL,
  CATEGORY_UPDATE_REQUEST,
  CATEGORY_UPDATE_SUCCESS,
  CATEGORY_UPDATE_FAIL,
  CATEGORY_DELETE_FAIL,
  CATEGORY_DELETE_REQUEST,
  CATEGORY_DELETE_SUCCESS,
  PARENT_CATEGORY_REQUEST,
  PARENT_CATEGORY_SUCCESS,
  PARENT_CATEGORY_FAIL,
  PROD_BYID_REQUEST,
  PROD_BYID_SUCCESS,
  PROD_BYID_FAIL,
  CATEGORY_GETLIST_REQUEST,
  CATEGORY_GETLIST_SUCCESS,
  CATEGORY_GETLIST_FAIL,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAIL,
  PRODUCT_UPDATE_REQUEST,
  PRODUCT_UPDATE_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODGROUP_BYID_REQUEST,
  PRODGROUP_BYID_SUCCESS,
  PRODGROUP_BYID_FAIL,
  PGLOGIN_ADD_REQUEST,
  PGLOGIN_ADD_SUCCESS,
  PGLOGIN_ADD_FAIL,
  PGLOGIN_REMOVE_REQUEST,
  PGLOGIN_REMOVE_SUCCESS,
  PGLOGIN_REMOVE_FAIL,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_FAIL,
  FILE_UPLOAD_REQUEST,
  PRODUCT_VARIANT_UPDATE_REQUEST,
  PRODUCT_VARIANT_UPDATE_SUCCESS,
  PRODUCT_VARIANT_UPDATE_FAIL,
  PGCATEGORY_ADD_REQUEST,
  PGCATEGORY_ADD_SUCCESS,
  PGCATEGORY_ADD_FAIL,
  PGCATEGORY_REMOVE_REQUEST,
  PGCATEGORY_REMOVE_SUCCESS,
  PGCATEGORY_REMOVE_FAIL,
  QUICK_ORDER_CREATE_REQUEST,
  QUICK_ORDER_CREATE_SUCCESS,
  QUICK_ORDER_CREATE_FAIL,
  ORDER_CANCEL_SUCCESS,
  ORDER_CANCEL_FAIL,
  ORDER_CANCEL_REQUEST,
  ADDRESS_BOOK_GET_REQUEST,
  ADDRESS_BOOK_GET_SUCCESS,
  ADDRESS_BOOK_GET_FAIL,
  ADDRESS_BOOK_CREATE_REQUEST,
  ADDRESS_BOOK_CREATE_SUCCESS,
  ADDRESS_BOOK_CREATE_FAIL,
  ADDRESS_BOOK_UPDATE_REQUEST,
  ADDRESS_BOOK_UPDATE_SUCCESS,
  ADDRESS_BOOK_UPDATE_FAIL,
  ADDRESS_BOOK_DELETE_REQUEST,
  ADDRESS_BOOK_DELETE_SUCCESS,
  ADDRESS_BOOK_DELETE_FAIL,
  ADDRESS_BOOK_BYID_REQUEST,
  ADDRESS_BOOK_BYID_SUCCESS,
  ADDRESS_BOOK_BYID_FAIL,
  ORDER_UPDATE_REQUEST,
  ORDER_UPDATE_SUCCESS,
  ORDER_UPDATE_FAIL,
  MAILING_LIST_DELETE_REQUEST,
  MAILING_LIST_DELETE_SUCCESS,
  MAILING_LIST_DELETE_FAIL,
  MANAGER_MEMBER_BYID_REQUEST,
  MANAGER_MEMBER_BYID_SUCCESS,
  MANAGER_MEMBER_BYID_FAIL,
  MAILING_LIST_BYID_REQUEST,
  MAILING_LIST_BYID_SUCCESS,
  MAILING_LIST_BYID_FAIL,
  MANAGER_MEMLOGIN_ADD_REQUEST,
  MANAGER_MEMLOGIN_ADD_SUCCESS,
  MANAGER_MEMLOGIN_ADD_FAIL,
  MANAGER_MEMLOGIN_REMOVE_REQUEST,
  MANAGER_MEMLOGIN_REMOVE_SUCCESS,
  MANAGER_MEMLOGIN_REMOVE_FAIL,
  MAILINGLIST_CSV_UPLOAD_REQUEST,
  MAILINGLIST_CSV_UPLOAD_SUCCESS,
  MAILINGLIST_CSV_UPLOAD_FAIL,
  PRODGROUP_UPDATE_REQUEST,
  PRODGROUP_UPDATE_SUCCESS,
  PRODGROUP_UPDATE_FAIL,
  ORDERS_CSV_UPLOAD_REQUEST,
  ORDERS_CSV_UPLOAD_SUCCESS,
  ORDERS_CSV_UPLOAD_FAIL,
  PRODGROUP_CREATE_REQUEST,
  PRODGROUP_CREATE_SUCCESS,
  PRODGROUP_CREATE_FAIL,
  PRICEGROUP_MEMBER_BYID_REQUEST,
  PRICEGROUP_MEMBER_BYID_SUCCESS,
  PRICEGROUP_MEMBER_BYID_FAIL,
  PRICEGROUP_MEMLOGIN_ADD_REQUEST,
  PRICEGROUP_MEMLOGIN_ADD_SUCCESS,
  PRICEGROUP_MEMLOGIN_ADD_FAIL,
  PRICEGROUP_MEMLOGIN_REMOVE_REQUEST,
  PRICEGROUP_MEMLOGIN_REMOVE_SUCCESS,
  PRICEGROUP_MEMLOGIN_REMOVE_FAIL,
  PRICEGROUP_UPDATE_REQUEST,
  PRICEGROUP_UPDATE_SUCCESS,
  PRICEGROUP_UPDATE_FAIL,
  PRICEGROUP_CREATE_REQUEST,
  PRICEGROUP_CREATE_SUCCESS,
  PRICEGROUP_CREATE_FAIL,
  USERGROUP_BYID_SUCCESS,
  USERGROUP_BYID_REQUEST,
  USERGROUP_BYID_FAIL,
  USERGROUP_USER_ADD_REQUEST,
  USERGROUP_USER_ADD_SUCCESS,
  USERGROUP_USER_ADD_FAIL,
  USERGROUP_USER_REMOVE_REQUEST,
  USERGROUP_USER_REMOVE_SUCCESS,
  USERGROUP_USER_REMOVE_FAIL,
  USERGROUP_UPDATE_REQUEST,
  USERGROUP_UPDATE_SUCCESS,
  USERGROUP_UPDATE_FAIL,
  USERGROUP_CREATE_REQUEST,
  USERGROUP_CREATE_SUCCESS,
  USERGROUP_CREATE_FAIL,
  IMAGE_DELETE_REQUEST,
  IMAGE_DELETE_SUCCESS,
  IMAGE_DELETE_FAIL,
  IMAGE_USER_ADD_REQUEST,
  IMAGE_USER_ADD_SUCCESS,
  IMAGE_USER_ADD_FAIL,
  CATEGORY_TREE_GET_REQUEST,
  CATEGORY_TREE_GET_SUCCESS,
  CATEGORY_TREE_GET_FAIL,
  PRODUCT_PRICING_BYID_REQUEST,
  PRODUCT_PRICING_BYID_SUCCESS,
  PRODUCT_PRICING_BYID_FAIL,
  PRODUCT_PRICING_UPDATE_REQUEST,
  PRODUCT_PRICING_UPDATE_SUCCESS,
  PRODUCT_PRICING_UPDATE_FAIL,
  CART_GET_REQUEST,
  CART_GET_SUCCESS,
  CART_GET_FAIL,
  CART_PRODUCT_ADD_REQUEST,
  CART_PRODUCT_ADD_SUCCESS,
  CART_PRODUCT_ADD_FAIL,
  CART_PRODUCT_REMOVE_REQUEST,
  CART_PRODUCT_REMOVE_SUCCESS,
  CART_PRODUCT_REMOVE_FAIL,
  CHECKOUT_ORDER_CREATE_REQUEST,
  CHECKOUT_ORDER_CREATE_SUCCESS,
  CHECKOUT_ORDER_CREATE_FAIL,
  CHECKOUT_STEP_SHIPTO,
  CHECKOUT_STEP_BILLTO,
  ORDER_FORM_GET_REQUEST,
  ORDER_FORM_GET_SUCCESS,
  ORDER_FORM_GET_FAIL,
  CART_PRODUCT_UPDATE_REQUEST,
  CART_PRODUCT_UPDATE_SUCCESS,
  CART_PRODUCT_UPDATE_FAIL,
  CART_PRODLIST_ADD_REQUEST,
  CART_PRODLIST_ADD_SUCCESS,
  CART_PRODLIST_ADD_FAIL,
  CURRENT_SESSION_SET_SUCCESS,
  CURRENT_SESSION_SET_REQUEST,
  CURRENT_SESSION_SET_FAIL,
  PRODUCT_VARIANT_DELETE_REQUEST,
  PRODUCT_VARIANT_DELETE_SUCCESS,
  PRODUCT_VARIANT_DELETE_FAIL,
  MASTER_VARIANT_CREATE_REQUEST,
  MASTER_VARIANT_CREATE_SUCCESS,
  MASTER_VARIANT_CREATE_FAIL,
  UPDATE_VARIANCE_CREATE_REQUEST,
  UPDATE_VARIANCE_CREATE_SUCCESS,
  UPDATE_VARIANCE_CREATE_FAIL,
  VARIANCE_DELETE_REQUEST,
  VARIANCE_DELETE_SUCCESS,
  VARIANCE_DELETE_FAIL,
  VARIANCE_UPDATE_REQUEST,
  VARIANCE_UPDATE_SUCCESS,
  VARIANCE_UPDATE_FAIL,
  FORMS_BYPRODUCTIDS_GET_REQUEST,
  FORMS_BYPRODUCTIDS_GET_FAIL,
  FORMS_BYPRODUCTIDS_GET_SUCCESS,
  PRICEGROUP_PROD_UPDATE_REQUEST,
  PRICEGROUP_PROD_UPDATE_SUCCESS,
  PRICEGROUP_PROD_UPDATE_FAIL,
  PRICEGROUP_PROD_ADD_REQUEST,
  PRICEGROUP_PROD_ADD_SUCCESS,
  PRICEGROUP_PROD_ADD_FAIL,
  PRICEGROUP_PROD_DELETE_REQUEST,
  PRICEGROUP_PROD_DELETE_SUCCESS,
  PRICEGROUP_PROD_DELETE_FAIL,
  PRODUCT_QUOTA_UPDATE_REQUEST,
  PRODUCT_QUOTA_UPDATE_SUCCESS,
  PRODUCT_QUOTA_UPDATE_FAIL,
  PRODUCT_QUOTA_GET_REQUEST,
  PRODUCT_QUOTA_GET_SUCCESS,
  PRODUCT_QUOTA_GET_FAIL,
  CAPTURE_CUSTJSON_GET_REQUEST,
  CAPTURE_CUSTJSON_GET_SUCCESS,
  CAPTURE_CUSTJSON_GET_FAIL,
  CHECK_ADDRESS_USPS_REQUEST,
  CHECK_ADDRESS_USPS_SUCCESS,
  CHECK_ADDRESS_USPS_FAIL,
  CHECKOUT_STEP_INIT,
  MULTI_ORDERS_CREATE_FAIL,
  MULTI_ORDERS_CREATE_SUCCESS,
  MULTI_ORDERS_CREATE_REQUEST,
  OFFORM_DETAIL_UPDATE_FAIL,
  OFFORM_DETAIL_UPDATE_SUCCESS,
  OFFORM_DETAIL_UPDATE_REQUEST,
  OFFORM_DETAIL_GET_SUCCESS,
  OFFORM_DETAIL_GET_FAIL,
  OFFORM_DETAIL_GET_REQUEST,
  MMBOOK_COVER_CREATE_FAIL,
  MMBOOK_COVER_CREATE_SUCCESS,
  MMBOOK_COVER_CREATE_REQUEST,
  MMBOOK_CREATE_REQUEST,
  MMBOOK_CREATE_SUCCESS,
  MMBOOK_CREATE_FAIL,
  CUSTOM_BOOK_UPDATE_FAIL,
  CUSTOM_BOOK_UPDATE_REQUEST,
  CUSTOM_BOOK_UPDATE_SUCCESS,
  MMBOOK_UPLOAD_REQUEST,
  MMBOOK_UPLOAD_SUCCESS,
  MMBOOK_UPLOAD_FAIL,
  TOGGLE_FAVORITE_SUCCESS,
  TOGGLE_FAVORITE_FAIL,
  TOGGLE_FAVORITE_REQUEST,
  ORDER_DETAIL_REQUEST,
  ORDER_DETAIL_SUCCESS,
  ORDER_DETAIL_FAIL,
  PASSWORD_POLICY_GET_REQUEST,
  PASSWORD_POLICY_GET_FAILURE,
  PASSWORD_POLICY_GET_SUCCESS,
  PASSWORD_POLICY_UPDATE_REQUEST,
  PASSWORD_POLICY_UPDATE_SUCCESS,
  PASSWORD_POLICY_UPDATE_FAILURE,
  ILINK_CATEGORY_TREE_GET_REQUEST,
  ILINK_CATEGORY_TREE_GET_SUCCESS,
  ILINK_CATEGORY_TREE_GET_FAIL,
  KIT_UPDATE_REQUEST,
  KIT_UPDATE_SUCCESS,
  KIT_UPDATE_FAIL,
  LOGO_CREATE_REQUEST,
  LOGO_CREATE_SUCCESS,
  LOGO_CREATE_FAIL,
  LOGO_DELETE_REQUEST,
  LOGO_DELETE_SUCCESS,
  LOGO_DELETE_FAIL,
} from '../constants/webstoreConstants'
import { getAPIGATEWAY } from '../utils/apiGateway'

export const createLogo = (filedata) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGO_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: filedata,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/logo`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: LOGO_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: LOGO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteLogoByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGO_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/logo/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: LOGO_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: LOGO_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getPickTicketByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_PICK_TICKET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/report/pickticket/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_PICK_TICKET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_PICK_TICKET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ORDER_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/report/orders/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_ORDER_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_ORDER_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderRoutingByAccount = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_ROUTING_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/orderrouting/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDER_ROUTING_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_ROUTING_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOrderRoutingRowByAccount =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORDER_ROUTING_ROW_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/orderrouting/${id}/row`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: ORDER_ROUTING_ROW_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: ORDER_ROUTING_ROW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteOrderRoutingById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_ROUTING_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/orderrouting/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDER_ROUTING_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_ROUTING_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createOrderRouting = (route) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_ROUTING_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: route,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/orderrouting`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDER_ROUTING_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_ROUTING_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getContractByAccount = (account) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CONTRACT_BYACC_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/contract/${account}/account`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_CONTRACT_BYACC_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_CONTRACT_BYACC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getContractByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CONTRACT_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/contract/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_CONTRACT_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_CONTRACT_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteContractByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEL_CONTRACT_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/contract/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEL_CONTRACT_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEL_CONTRACT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateContractByID =
  (conInfo, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_CONTRACT_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: conInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/contract/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: UPDATE_CONTRACT_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: UPDATE_CONTRACT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createContract = (contractInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_CONTRACT_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: contractInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/contract`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CREATE_CONTRACT_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CREATE_CONTRACT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createContractOpCode =
  (opcodeInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_CON_OPCODE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: opcodeInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/contractopcode`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CREATE_CON_OPCODE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CREATE_CON_OPCODE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteContractOpCode = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEL_CON_OPCODE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/contractopcode/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEL_CON_OPCODE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEL_CON_OPCODE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCustomLetterRowByAccount =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CUSTOM_LETTER_ROW_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/customletter/${id}/row`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CUSTOM_LETTER_ROW_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CUSTOM_LETTER_ROW_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createCustomLetter = (letter) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOM_LETTER_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: letter,
      url: `${process.env.REACT_APP_REPORT_KEEPER_API}/customerletter`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.dbname} ${userInfo.token}`,
      },
      // url: `${getAPIGATEWAY(
      //   userInfo.dbname
      // )}/api/v1/fulfillment/maintenance/customletter`,
      // headers: {
      //   "Content-Type": "application/json",
      //   Authorization: `PPP ${userInfo.token}`
      // }
    })

    dispatch({ type: CUSTOM_LETTER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CUSTOM_LETTER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCustomLetterById =
  (letterid) => async (dispatch, getState) => {
    try {
      dispatch({ type: CUSTOM_LETTER_DELETE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/customletter/${letterid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CUSTOM_LETTER_DELETE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CUSTOM_LETTER_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

// open the PDF file in the new window
export const getCustomLetterById = (letterid) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOM_LETTER_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    await axios({
      method: 'get',
      responseType: 'blob',
      url: `${process.env.REACT_APP_REPORT_KEEPER_API}/customerletter/${letterid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${userInfo.dbname} ${userInfo.token}`,
      },
      // url: `${getAPIGATEWAY(
      //   userInfo.dbname
      // )}/api/v1/fulfillment/maintenance/customletter/${letterid}`,
      // headers: {
      //   'Content-Type': 'application/pdf',
      //   Authorization: `PPP ${userInfo.token}`,
      // },
    }).then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: 'application/pdf' }) //Build a URL from the file
      const fileURL = URL.createObjectURL(file) //Open the URL on new Window
      window.open(fileURL)
    })

    dispatch({ type: CUSTOM_LETTER_GET_SUCCESS })
  } catch (error) {
    dispatch({
      type: CUSTOM_LETTER_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getImportFilterById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: IMPORT_FILTER_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/importfilter/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: IMPORT_FILTER_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: IMPORT_FILTER_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateImportFilterByID =
  (formInfo, filterid) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_IMP_FILTER_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: formInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/importfilter/${filterid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: UPDATE_IMP_FILTER_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: UPDATE_IMP_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createNewImportFilter =
  (formInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_IMP_FILTER_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: formInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/importfilter`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CREATE_IMP_FILTER_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CREATE_IMP_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteImportFilterByID =
  (filterid) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEL_IMP_FILTER_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/importfilter/${filterid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: DEL_IMP_FILTER_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: DEL_IMP_FILTER_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getOrderFormByAccount =
  (account) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ORDFORM_BYACC_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/${account}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: GET_ORDFORM_BYACC_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: GET_ORDFORM_BYACC_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getOrderFormByID = (account, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_ORDFORM_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/orderform/${account}/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_ORDFORM_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_ORDFORM_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateOrderFormByUID =
  (formInfo, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_ORDERFORM_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: formInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: UPDATE_ORDERFORM_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: UPDATE_ORDERFORM_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createOrdForm = (formInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_ORDERFORM_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: formInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/orderform`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CREATE_ORDERFORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CREATE_ORDERFORM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteOrdForm = (uid) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEL_ORDERFORM_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/orderform/${uid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEL_ORDERFORM_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEL_ORDERFORM_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateOrdFormField =
  (formInfo, fieldid) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_ORDFORM_FIELD_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: formInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/${fieldid}/field`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: UPDATE_ORDFORM_FIELD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: UPDATE_ORDFORM_FIELD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createOrdFormField = (formInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_ORDFORM_FIELD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: formInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/orderform/field`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CREATE_ORDFORM_FIELD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CREATE_ORDFORM_FIELD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteOrdFormField = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEL_ORDFORM_FIELD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/orderform/${id}/field`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEL_ORDFORM_FIELD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEL_ORDFORM_FIELD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createOrdFormProduct =
  (formid, products) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ORDFORM_PROD_REQUEST })
      const ids = products.map((p) => {
        return p[Object.keys(p)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/product/${formid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.addElement = products
      dispatch({ type: CREATE_ORDFORM_PROD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CREATE_ORDFORM_PROD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteOrdFormProduct =
  (formid, products) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEL_ORDFORM_PROD_REQUEST })
      const ids = products.map((p) => {
        return p[Object.keys(p)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/product/${formid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.removeElement = products
      dispatch({ type: DEL_ORDFORM_PROD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: DEL_ORDFORM_PROD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createOrdFormLogin =
  (formid, logins) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_ORDFORM_LOGIN_REQUEST })
      const ids = logins.map((l) => {
        return l[Object.keys(l)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/login/${formid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.addElement = logins
      dispatch({ type: CREATE_ORDFORM_LOGIN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CREATE_ORDFORM_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteOrdFormLogin =
  (formid, logins) => async (dispatch, getState) => {
    try {
      dispatch({ type: DEL_ORDFORM_LOGIN_REQUEST })
      const ids = logins.map((l) => {
        return l[Object.keys(l)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/orderform/login/${formid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.removeElement = logins
      dispatch({ type: DEL_ORDFORM_LOGIN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: DEL_ORDFORM_LOGIN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateBackOrderToShipped = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BCKORD_TO_SHIPPED_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/process/order/${id}/shipped`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: BCKORD_TO_SHIPPED_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BCKORD_TO_SHIPPED_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateOrderContract = (contract) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_CONTRACT_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: contract,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/order/${contract._id}/contract`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDER_CONTRACT_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_CONTRACT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCarrierListByVia = (service) => async (dispatch, getState) => {
  try {
    dispatch({ type: CARRIER_VIA_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/carrier/${service.replace(
        /\//,
        '|'
      )}/via`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CARRIER_VIA_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CARRIER_VIA_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateOrderBatchCarrier =
  (carrier) => async (dispatch, getState) => {
    try {
      dispatch({ type: BATCH_CARRIER_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: carrier,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/orderbatch/${carrier._id}/carrier`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: BATCH_CARRIER_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: BATCH_CARRIER_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateOrderBatchPickVerify =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: BATCH_VERIFY_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/orderbatch/${id}/pickverify`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: BATCH_VERIFY_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: BATCH_VERIFY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateBackOrderToNew = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BCKORD_TO_NEW_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/process/order/${id}/new`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: BCKORD_TO_NEW_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BCKORD_TO_NEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCustomerByAccount = (account) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CUST_BYACC_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/customer/${account}/account`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_CUST_BYACC_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_CUST_BYACC_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCustomerByID = (custid) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_CUST_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/customer/${custid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_CUST_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_CUST_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAddressByCustomer =
  (customer) => async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ADDR_BYCUS_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/address/${customer}/customer`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: GET_ADDR_BYCUS_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: GET_ADDR_BYCUS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateAddressByID =
  (info, addressid) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_ADDR_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: info,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/address/${addressid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: UPDATE_ADDR_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: UPDATE_ADDR_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createNewAddress = (formInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_ADDR_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: formInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/address`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CREATE_ADDR_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CREATE_ADDR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteAddressByID = (addressid) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEL_ADDR_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/address/${addressid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEL_ADDR_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEL_ADDR_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCustomerByID =
  (info, custid) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_CUST_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: info,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/fulfillment/maintenance/customer/${custid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: UPDATE_CUST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: UPDATE_CUST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createNewCustomer = (custInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CREATE_CUST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: custInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/customer`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CREATE_CUST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CREATE_CUST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCustomerByID = (custid) => async (dispatch, getState) => {
  try {
    dispatch({ type: DEL_CUST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/fulfillment/maintenance/customer/${custid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: DEL_CUST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: DEL_CUST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getWidgets = (module) => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_WIDGET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/fulfillment/widget/${
        module || 'sunlife'
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: GET_WIDGET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: GET_WIDGET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const postBadge = (forminfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: BADGE_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: forminfo,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/badge`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: BADGE_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BADGE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getBadges = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BADGE_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/badge`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: BADGE_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BADGE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createBadgeFile = () => async (dispatch, getState) => {
  try {
    dispatch({ type: BADGE_FILE_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'put',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/badge`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: BADGE_FILE_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BADGE_FILE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteBadgeData = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: BADGE_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/select/badge/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: BADGE_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: BADGE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getLoginList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_LIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/system/maintenance/login`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: LOGIN_LIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: LOGIN_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteLogin = (_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/system/maintenance/login/${_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data._id = _id
    dispatch({ type: LOGIN_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: LOGIN_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateLogin = (login) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: login,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/system/maintenance/login/${
        login._id
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.newRow = {
      ...login,
      FullName: login.FullName || login.Fullname,
      Email: login.Email || login.email,
    }
    dispatch({ type: LOGIN_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: LOGIN_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createLogin = (login) => async (dispatch, getState) => {
  try {
    dispatch({ type: LOGIN_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: login,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/system/maintenance/login`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.newRow = { ...login, FullName: login.Fullname, Email: login.email }
    dispatch({ type: LOGIN_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: LOGIN_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getMenuById = (_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MENU_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/system/maintenance/menu/${_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: MENU_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MENU_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const setMenuById = (menu, _id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MENU_SET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: menu,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/system/maintenance/menu/${_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: MENU_SET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MENU_SET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductListReport =
  (select, next, offset) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCTLIST_REPORT_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/bom/report/productlist?offset=${offset || 0}&next=${
          next || 50
        }&select=${select}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      data.select = select
      dispatch({ type: PRODUCTLIST_REPORT_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRODUCTLIST_REPORT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getECOById = (KitID, _id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ECO_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/bom/process/eco/${KitID}/${_id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: ECO_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ECO_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createECO = (eco) => async (dispatch, getState) => {
  try {
    dispatch({ type: ECO_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: eco,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/bom/process/eco`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: ECO_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ECO_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCategoryList = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_GETLIST_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/category`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CATEGORY_GETLIST_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORY_GETLIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCategoryById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATE_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/category/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: CATE_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATE_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createCategory = (catInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: catInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/category`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: CATEGORY_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORY_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCategory = (category) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: category,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/category/${category.CategoryID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.newRow = category
    dispatch({ type: CATEGORY_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORY_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteCategoryByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/category/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CATEGORY_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORY_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getParentCategories = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PARENT_CATEGORY_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/parentcategory`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PARENT_CATEGORY_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PARENT_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROD_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/product/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: PROD_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PROD_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getILinkProductById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PROD_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/product/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: PROD_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PROD_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createNewProduct = (prodInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: prodInfo,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/product`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    data.newRow = {
      ProductID: prodInfo.ProductID,
      CategoryID: prodInfo.CategoryID,
      ProductDesc: prodInfo.ProductDesc,
      Inactive: false,
      Price: prodInfo.Price,
      Weight: prodInfo.Weight,
      LineType: prodInfo.LineType,
      Action: 'Deactivate',
      _id: prodInfo.ProductID,
    }
    dispatch({ type: PRODUCT_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProductByID =
  (prodInfo, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: prodInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/product/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: PRODUCT_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRODUCT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getProductGroupById =
  (productgroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODGROUP_BYID_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productgroup/${productgroupid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      dispatch({ type: PRODGROUP_BYID_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRODGROUP_BYID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateProductGroup = (prodGroup) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODGROUP_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: prodGroup,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productgroup/${
        prodGroup.ProductGroupID
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.newRow = prodGroup
    dispatch({ type: PRODGROUP_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODGROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createProductGroup = (prodGroup) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODGROUP_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: prodGroup,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productgroup`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PRODGROUP_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODGROUP_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addProdGroupLogin =
  (Login, productgroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PGLOGIN_ADD_REQUEST })
      const ids = Login.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productgroup/login/${productgroupid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      data.addElement = Login
      dispatch({ type: PGLOGIN_ADD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PGLOGIN_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const removeProdGroupLogin =
  (Login, productgroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PGLOGIN_REMOVE_REQUEST })
      const ids = Login.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productgroup/login/${productgroupid}`,
        data: ids,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.removeElement = Login
      dispatch({ type: PGLOGIN_REMOVE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PGLOGIN_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addProdGroupCategory =
  (Category, productgroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PGCATEGORY_ADD_REQUEST })
      const ids = Category.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productgroup/category/${productgroupid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.addElement = Category
      dispatch({ type: PGCATEGORY_ADD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PGCATEGORY_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const removeProdGroupCategory =
  (Category, productgroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PGCATEGORY_REMOVE_REQUEST })
      const ids = Category.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productgroup/category/${productgroupid}`,
        data: ids,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.removeElement = Category
      dispatch({ type: PGCATEGORY_REMOVE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PGCATEGORY_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export const uploadPDFFile = (filedata) => async (dispatch, getState) => {
  try {
    dispatch({ type: FILE_UPLOAD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const base64 = await convertBase64(filedata.file)
    filedata.file = base64
    const { data } = await axios({
      method: 'post',
      data: filedata,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/product/savefile`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: FILE_UPLOAD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: FILE_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

// file upload to xinet server
export const uploadPDFFileToXnet = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: FILE_UPLOAD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    await axios({
      method: 'put',
      data: formData,
      responseType: 'blob',
      url: userInfo.storeVariable.WS_UPLOADSCRIPT,
      headers: {
        //'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        Referer: userInfo.storeVariable.WS_UPLOADSCRIPT,
        'Referrer-Policy': 'strict-origin-when-cross-origin',
        //Authorization: `${userInfo.dbname} ${userInfo.token}`,
      },
    }).then((response) => {
      //Create a Blob from the PDF Stream
      const file = new Blob([response.data], { type: 'application/pdf' }) //Build a URL from the file
      const fileURL = URL.createObjectURL(file) //Open the URL on new Window
      window.open(fileURL)
    })

    dispatch({ type: FILE_UPLOAD_SUCCESS })
  } catch (error) {
    dispatch({
      type: FILE_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateVariantByID = (variant) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_VARIANT_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: variant,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productvariant/${variant.VariantID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PRODUCT_VARIANT_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_VARIANT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteVariantByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_VARIANT_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productvariant/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PRODUCT_VARIANT_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_VARIANT_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createMasterVariant =
  (ProductID) => async (dispatch, getState) => {
    try {
      dispatch({ type: MASTER_VARIANT_CREATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'post',
        data: { ProductID },
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/mastervariant`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: MASTER_VARIANT_CREATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: MASTER_VARIANT_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createUpdateVariance = (info) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_VARIANCE_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: info,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/variant`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: UPDATE_VARIANCE_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: UPDATE_VARIANCE_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteVarianceByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: VARIANCE_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/variant/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: VARIANCE_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: VARIANCE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateVarianceByID = (id, info) => async (dispatch, getState) => {
  try {
    dispatch({ type: VARIANCE_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: info,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/variant/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: VARIANCE_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: VARIANCE_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createQuickOrder = (formInfo) => async (dispatch, getState) => {
  try {
    dispatch({ type: QUICK_ORDER_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: formInfo,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/quickorder`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: QUICK_ORDER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: QUICK_ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const cancelOrder = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_CANCEL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/cancel/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: ORDER_CANCEL_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_CANCEL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getILinkOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_DETAIL_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/detail/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: ORDER_DETAIL_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getAddressBook = (select) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADDRESS_BOOK_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/address?select=${select}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ADDRESS_BOOK_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ADDRESS_BOOK_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createAddressBook =
  (addressInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADDRESS_BOOK_CREATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'post',
        data: addressInfo,
        url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/address`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: ADDRESS_BOOK_CREATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: ADDRESS_BOOK_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateAddressBook =
  (addressid, addressInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADDRESS_BOOK_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: addressInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/address/${addressid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.newRow = {
        Company: addressInfo.Company,
        Contact: addressInfo.Contact,
        Address1: addressInfo.Address1,
        City: addressInfo.City,
        State: addressInfo.State,
        ZIP: addressInfo.ZIP,
        Country: addressInfo.Country,
        Edit: 'edit',
        Action: 'delete',
        _id: addressInfo.AddressID,
        Account: addressInfo.Acocunt,
      }

      dispatch({ type: ADDRESS_BOOK_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: ADDRESS_BOOK_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteAddressBookByID =
  (addressid) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADDRESS_BOOK_DELETE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/address/${addressid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: ADDRESS_BOOK_DELETE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: ADDRESS_BOOK_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getAddressById = (addressid) => async (dispatch, getState) => {
  try {
    dispatch({ type: ADDRESS_BOOK_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/address/${addressid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: ADDRESS_BOOK_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ADDRESS_BOOK_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const holdreleaseOrder = (_id, action) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/holdrelease/${_id}/${action}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDER_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteMailingListByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MAILING_LIST_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/mailinglist/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: MAILING_LIST_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MAILING_LIST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getManagerMemberById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MANAGER_MEMBER_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/member/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: MANAGER_MEMBER_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MANAGER_MEMBER_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getMailingListById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: MAILING_LIST_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/mailinglist/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: MAILING_LIST_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MAILING_LIST_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addManagerMemberLogin =
  (Login, managerid) => async (dispatch, getState) => {
    try {
      dispatch({ type: MANAGER_MEMLOGIN_ADD_REQUEST })
      const ids = Login.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/manager/add/${managerid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.addElement = Login
      dispatch({ type: MANAGER_MEMLOGIN_ADD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: MANAGER_MEMLOGIN_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const removeManagerMemberLogin =
  (Login, managerid) => async (dispatch, getState) => {
    try {
      dispatch({ type: MANAGER_MEMLOGIN_REMOVE_REQUEST })
      const ids = Login.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/manager/remove/${managerid}`,
        data: ids,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.removeElement = Login
      dispatch({ type: MANAGER_MEMLOGIN_REMOVE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: MANAGER_MEMLOGIN_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const uploadMailingListCSV =
  (fileData) => async (dispatch, getState) => {
    try {
      dispatch({ type: MAILINGLIST_CSV_UPLOAD_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      // const base64 = await convertBase64(fileData.file)
      // fileData.file = base64
      const { data } = await axios({
        method: 'post',
        data: fileData,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/mailinglist/upload`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: MAILINGLIST_CSV_UPLOAD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: MAILINGLIST_CSV_UPLOAD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const uploadOrdersCSV = (fileData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDERS_CSV_UPLOAD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    // const base64 = await convertBase64(fileData.file)
    // fileData.file = base64

    const { data } = await axios({
      method: 'post',
      data: fileData,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/orders/upload`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDERS_CSV_UPLOAD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDERS_CSV_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const uploadCompassCSV = (fileData) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDERS_CSV_UPLOAD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    // const base64 = await convertBase64(fileData.file)
    // fileData.file = base64

    const { data } = await axios({
      method: 'post',
      data: fileData,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/compass/upload`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDERS_CSV_UPLOAD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDERS_CSV_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getPriceGroupById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRICEGROUP_MEMBER_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/pricegroup/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: PRICEGROUP_MEMBER_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRICEGROUP_MEMBER_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addPriceGroupLogin =
  (Login, pricegroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRICEGROUP_MEMLOGIN_ADD_REQUEST })
      const ids = Login.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: ids,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/pricegroup/login/${pricegroupid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.addElement = Login
      dispatch({ type: PRICEGROUP_MEMLOGIN_ADD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRICEGROUP_MEMLOGIN_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const removePriceGroupLogin =
  (Login, pricegroupid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRICEGROUP_MEMLOGIN_REMOVE_REQUEST })
      const ids = Login.map((i) => {
        return i[Object.keys(i)[0]]
      })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/pricegroup/login/${pricegroupid}`,
        data: ids,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      data.removeElement = Login
      dispatch({ type: PRICEGROUP_MEMLOGIN_REMOVE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRICEGROUP_MEMLOGIN_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updatePriceGroup = (priceGroup) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRICEGROUP_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: priceGroup,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/pricegroup/${priceGroup.PriceGroupID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.newRow = priceGroup
    dispatch({ type: PRICEGROUP_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRICEGROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createPriceGroup = (priceGroup) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRICEGROUP_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: priceGroup,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/pricegroup`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PRICEGROUP_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRICEGROUP_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserGroupById = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USERGROUP_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/usergroup/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: USERGROUP_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USERGROUP_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addUserGroupUser = (User, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USERGROUP_USER_ADD_REQUEST })
    const ids = User.map((i) => {
      return i[Object.keys(i)[0]]
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: ids,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/usergroup/${id}/add`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.addElement = User
    dispatch({ type: USERGROUP_USER_ADD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USERGROUP_USER_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeUserGroupUser = (User, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USERGROUP_USER_REMOVE_REQUEST })
    const ids = User.map((i) => {
      return i[Object.keys(i)[0]]
    })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/usergroup/${id}/delete`,
      data: ids,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.removeElement = User
    dispatch({ type: USERGROUP_USER_REMOVE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USERGROUP_USER_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateUserGroup = (userGroup) => async (dispatch, getState) => {
  try {
    dispatch({ type: USERGROUP_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: userGroup,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/usergroup/${userGroup.UserGroupID}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    data.newRow = userGroup
    dispatch({ type: USERGROUP_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USERGROUP_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createUserGroup = (userGroup) => async (dispatch, getState) => {
  try {
    dispatch({ type: USERGROUP_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: userGroup,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/usergroup`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: USERGROUP_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USERGROUP_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const deleteImageByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: IMAGE_DELETE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/image/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: IMAGE_DELETE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: IMAGE_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addImageUser = (user, id) => async (dispatch, getState) => {
  try {
    dispatch({ type: IMAGE_USER_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: [user],
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/image/${id}/adduser`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: IMAGE_USER_ADD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: IMAGE_USER_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCategoryTree = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CATEGORY_TREE_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/categorytree`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CATEGORY_TREE_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CATEGORY_TREE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getILinkCategoryTree = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ILINK_CATEGORY_TREE_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/categorytree`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ILINK_CATEGORY_TREE_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ILINK_CATEGORY_TREE_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductPricingByID = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_PRICING_BYID_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productpricing/${id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PRODUCT_PRICING_BYID_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_PRICING_BYID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateProductPricing =
  (id, pricingInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRODUCT_PRICING_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: pricingInfo,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productpricing/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: PRODUCT_PRICING_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRODUCT_PRICING_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getCart = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/cart`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CART_GET_SUCCESS, payload: data })
    localStorage.setItem('cartTotalQty', data.totalQty)
  } catch (error) {
    dispatch({
      type: CART_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const getILinkCart = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/cart`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CART_GET_SUCCESS, payload: data })
    localStorage.setItem('cartTotalQty', data.totalQty)
  } catch (error) {
    dispatch({
      type: CART_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addCartProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_PRODUCT_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: product,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/cart`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CART_PRODUCT_ADD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CART_PRODUCT_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addiLinkCartProduct = (product) => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_PRODUCT_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: product,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/cart`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CART_PRODUCT_ADD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CART_PRODUCT_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const removeCartProduct = (productid) => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_PRODUCT_REMOVE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'delete',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/cart/${productid}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CART_PRODUCT_REMOVE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CART_PRODUCT_REMOVE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
export const removeCartILinkProduct =
  (productid) => async (dispatch, getState) => {
    try {
      dispatch({ type: CART_PRODUCT_REMOVE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/ilink/order/cart/${productid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CART_PRODUCT_REMOVE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CART_PRODUCT_REMOVE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const toggleFavorite =
  (productId, command) => async (dispatch, getState) => {
    try {
      dispatch({ type: TOGGLE_FAVORITE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()
      await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/favoriteproduct/${
          command ? 'N' : 'Y'
        }/${productId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      dispatch({
        type: TOGGLE_FAVORITE_SUCCESS,
        payload: { productId, command },
      })
    } catch (error) {
      dispatch({
        type: TOGGLE_FAVORITE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const toggleILinkFavorite =
  (productId, command) => async (dispatch, getState) => {
    try {
      dispatch({ type: TOGGLE_FAVORITE_REQUEST })

      const {
        userLogin: { userInfo },
      } = getState()
      await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/ilink/order/favoriteproduct/${
          command ? 'N' : 'Y'
        }/${productId}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      dispatch({
        type: TOGGLE_FAVORITE_SUCCESS,
        payload: { productId, command },
      })
    } catch (error) {
      dispatch({
        type: TOGGLE_FAVORITE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createCheckoutOrder =
  (orderInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHECKOUT_ORDER_CREATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: orderInfo,
        url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/checkout`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      dispatch({ type: CHECKOUT_ORDER_CREATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CHECKOUT_ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createMultipleOrders =
  (orderInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: MULTI_ORDERS_CREATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: orderInfo,
        url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/orders/multi`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: MULTI_ORDERS_CREATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: MULTI_ORDERS_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const setDestCountry = (country, form) => async (dispatch, getState) => {
  if (form === 'ShipTo') {
    dispatch({
      type: CHECKOUT_STEP_SHIPTO,
      payload: { shipCountry: country },
    })
  }
  if (form === 'BillTo') {
    dispatch({
      type: CHECKOUT_STEP_BILLTO,
      payload: { billCountry: country },
    })
  }
  if (form === 'NONE') {
    dispatch({
      type: CHECKOUT_STEP_INIT,
      payload: { shipCountry: country },
    })
  }
}

export const getOrderByPartForm = () => async (dispatch, getState) => {
  try {
    dispatch({ type: ORDER_FORM_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/importorderbypart/form`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: ORDER_FORM_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: ORDER_FORM_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCartProductByID =
  (product, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: CART_PRODUCT_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: product,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/cart/${id}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CART_PRODUCT_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CART_PRODUCT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const updateILinkCartProductByID =
  (product, productid) => async (dispatch, getState) => {
    try {
      dispatch({ type: CART_PRODUCT_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: product,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/ilink/order/cart/${productid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CART_PRODUCT_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CART_PRODUCT_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const addCartProductList = (products) => async (dispatch, getState) => {
  try {
    dispatch({ type: CART_PRODLIST_ADD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'post',
      data: products,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/order/cart/list`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CART_PRODLIST_ADD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CART_PRODLIST_ADD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const addiLinkCartProductList =
  (products) => async (dispatch, getState) => {
    try {
      dispatch({ type: CART_PRODLIST_ADD_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const { data } = await axios({
        method: 'post',
        data: products,
        url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/cart/list`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CART_PRODLIST_ADD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CART_PRODLIST_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const setCurrentSession =
  (sessionnumber) => async (dispatch, getState) => {
    try {
      dispatch({ type: CURRENT_SESSION_SET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/session/${sessionnumber}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CURRENT_SESSION_SET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CURRENT_SESSION_SET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getFormByProductIds =
  (ProductIds) => async (dispatch, getState) => {
    try {
      dispatch({ type: FORMS_BYPRODUCTIDS_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/vpform/${ProductIds}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: FORMS_BYPRODUCTIDS_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: FORMS_BYPRODUCTIDS_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateProductToPriceGroup =
  (priceGroup) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRICEGROUP_PROD_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'put',
        data: priceGroup,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productpricegroup/${
          priceGroup.PriceGroupID
        }/${priceGroup.ProductID}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: PRICEGROUP_PROD_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRICEGROUP_PROD_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const createProductToPriceGroup =
  (priceGroup) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRICEGROUP_PROD_ADD_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: priceGroup,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productpricegroup`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: PRICEGROUP_PROD_ADD_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRICEGROUP_PROD_ADD_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const deleteProductFromPriceGroup =
  (pricegroupid, productid) => async (dispatch, getState) => {
    try {
      dispatch({ type: PRICEGROUP_PROD_DELETE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'delete',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/productpricegroup/${pricegroupid}/${productid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: PRICEGROUP_PROD_DELETE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: PRICEGROUP_PROD_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateProductQuota = (quota) => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_QUOTA_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: quota,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productquota`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    delete quota['MaxQty']
    data.newRow = quota
    dispatch({ type: PRODUCT_QUOTA_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_QUOTA_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getProductQuota = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PRODUCT_QUOTA_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/productquota`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PRODUCT_QUOTA_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PRODUCT_QUOTA_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const captureCustomizationForm =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: CAPTURE_CUSTJSON_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: formData,
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/order/product/preview`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: CAPTURE_CUSTJSON_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CAPTURE_CUSTJSON_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const checkAddressUSPS = (address) => async (dispatch, getState) => {
  try {
    dispatch({ type: CHECK_ADDRESS_USPS_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: address,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/webstore/checkaddress`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: CHECK_ADDRESS_USPS_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CHECK_ADDRESS_USPS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getOFFormDetailByID =
  (formid, productid) => async (dispatch, getState) => {
    try {
      dispatch({ type: OFFORM_DETAIL_GET_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/offormdetail/${formid}/${productid}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: OFFORM_DETAIL_GET_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: OFFORM_DETAIL_GET_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateOFFormDetailByID =
  (detail) => async (dispatch, getState) => {
    try {
      dispatch({ type: OFFORM_DETAIL_UPDATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'get',
        url: `${getAPIGATEWAY(
          userInfo.dbname
        )}/api/v1/webstore/administration/offormdetail/${detail.formid}/${
          detail.productid
        }`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })

      dispatch({ type: OFFORM_DETAIL_UPDATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: OFFORM_DETAIL_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const convertImage2PDF = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: MMBOOK_COVER_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: book,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/macmillan/image2cover`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: MMBOOK_COVER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MMBOOK_COVER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createMMBookCover = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: MMBOOK_COVER_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: book,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/macmillan/cover`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: MMBOOK_COVER_CREATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MMBOOK_COVER_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const saveMMFile = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: MMBOOK_UPLOAD_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    const { data } = await axios({
      method: 'put',
      data: book,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/macmillan/book/${book.command}/${book._id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: MMBOOK_UPLOAD_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: MMBOOK_UPLOAD_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updateCustomBook = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: CUSTOM_BOOK_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: book,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/custombook/${book._id}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    data.newRow = book
    dispatch({ type: CUSTOM_BOOK_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CUSTOM_BOOK_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createMMBookOrder = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: MMBOOK_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    var fdata = new FormData()

    fdata.append(
      'data',
      JSON.stringify({ ...book, coverFile: null, textFile: null })
    )
    fdata.append('coverFile', book.coverFile)
    fdata.append('textFile', book.textFile)
    const { data } = await axios({
      method: 'post',
      data: fdata,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/order/macmillan/book`,
      headers: {
        //'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    if (data.success) {
      dispatch({ type: MMBOOK_CREATE_SUCCESS, payload: data })
    } else {
      dispatch({
        type: MMBOOK_CREATE_FAIL,
        payload: data.message,
      })
    }
  } catch (error) {
    dispatch({
      type: MMBOOK_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createMMBookILinkOrder = (book) => async (dispatch, getState) => {
  try {
    dispatch({ type: MMBOOK_CREATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()

    var fdata = new FormData()

    fdata.append(
      'data',
      JSON.stringify({ ...book, coverFile: null, textFile: null })
    )
    fdata.append('coverFile', book.coverFile)
    fdata.append('textFile', book.textFile)
    const { data } = await axios({
      method: 'post',
      data: fdata,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/ilink/order/createmacmillanorder`,
      headers: {
        //'Content-Type': 'application/json',
        'Content-Type': 'multipart/form-data',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    if (data.success) {
      dispatch({ type: MMBOOK_CREATE_SUCCESS, payload: data })
    } else {
      dispatch({
        type: MMBOOK_CREATE_FAIL,
        payload: data.message,
      })
    }
  } catch (error) {
    dispatch({
      type: MMBOOK_CREATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getpasswordPolicy = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PASSWORD_POLICY_GET_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/passwordpolicy`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: PASSWORD_POLICY_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PASSWORD_POLICY_GET_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const updatePasswordPolicy = (policy) => async (dispatch, getState) => {
  try {
    dispatch({ type: PASSWORD_POLICY_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'put',
      data: policy,
      url: `${getAPIGATEWAY(
        userInfo.dbname
      )}/api/v1/webstore/administration/passwordpolicy`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })
    dispatch({ type: PASSWORD_POLICY_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: PASSWORD_POLICY_UPDATE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const createILinkCheckoutOrder =
  (orderInfo) => async (dispatch, getState) => {
    try {
      dispatch({ type: CHECKOUT_ORDER_CREATE_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()
      const { data } = await axios({
        method: 'post',
        data: orderInfo,
        url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/checkout`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `PPP ${userInfo.token}`,
        },
      })
      dispatch({ type: CHECKOUT_ORDER_CREATE_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: CHECKOUT_ORDER_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateKit = (kit) => async (dispatch, getState) => {
  try {
    dispatch({ type: KIT_UPDATE_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    const { data } = await axios({
      method: 'post',
      data: kit,
      url: `${getAPIGATEWAY(userInfo.dbname)}/api/v1/ilink/order/kit`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    dispatch({ type: KIT_UPDATE_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: KIT_UPDATE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
