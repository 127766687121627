import axios from 'axios'
import {
  USER_TOKEN_REQUEST,
  USER_TOKEN_SUCCESS,
  USER_TOKEN_FAIL,
  USER_TOKEN_RESET,
  USER_TOKEN2_REQUEST,
  USER_TOKEN2_SUCCESS,
  USER_TOKEN2_FAIL,
  USER_LOGOUT,
  USER_SWITCH_REQUEST,
  USER_SWITCH_SUCCESS,
  USER_SWITCH_FAIL,
  USER_RESETREQUEST_REQUEST,
  USER_RESETREQUEST_SUCCESS,
  USER_RESETREQUEST_FAIL,
  USER_UPDATEPASS_REQUEST,
  USER_UPDATEPASS_SUCCESS,
  USER_UPDATEPASS_FAIL,
  CAPTCHA_GET_FAIL,
  CAPTCHA_GET_REQUEST,
  CAPTCHA_GET_SUCCESS,
  HELPDESK_SUBMIT_REQUEST,
  HELPDESK_SUBMIT_SUCCESS,
  HELPDESK_SUBMIT_FAIL,
} from '../constants/userConstants'
import { getAPIGATEWAY } from '../utils/apiGateway'

//import dotenv from 'dotenv'
//dotenv.config()
export const validateUserToken =
  (token, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const clientip = await getClientIp(dbname, getState)

      const { data } = await axios({
        method: 'put',
        data: clientip,
        url: `${getAPIGATEWAY(
          dbname || userInfo.dbname
        )}/api/v1/user/${token}/validate`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
      dispatch({ type: USER_TOKEN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const validateILinkUserToken =
  (token, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN_REQUEST })
      const {
        userLogin: { userInfo },
      } = getState()

      const clientip = await getClientIp(dbname, getState)

      const { data } = await axios({
        method: 'put',
        data: clientip,
        url: `${getAPIGATEWAY(
          dbname || userInfo.dbname
        )}/api/v1/user/ilink/${token}/validate`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
      dispatch({ type: USER_TOKEN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getUserToken =
  (loginname, password, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN_REQUEST })

      const { data } = await axios({
        method: 'post',
        data: { loginname, password },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/token`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
      dispatch({ type: USER_TOKEN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
export const submitHelpdesk = (form) => async (dispatch) => {
  try {
    dispatch({ type: HELPDESK_SUBMIT_REQUEST })

    const { data } = await axios({
      method: 'post',
      data: form,
      url: `${getAPIGATEWAY(
        process.env.REACT_APP_DBNAME
      )}/api/v1/select/helpdesk`,

      headers: {
        'Content-Type': 'application/json',
      },
    })

    dispatch({ type: HELPDESK_SUBMIT_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: HELPDESK_SUBMIT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getCaptcha = () => async (dispatch) => {
  try {
    dispatch({ type: CAPTCHA_GET_REQUEST })

    const { data } = await axios({
      method: 'get',
      url: `${getAPIGATEWAY(
        process.env.REACT_APP_DBNAME
      )}/api/v1/select/captcha`,
    })

    dispatch({ type: CAPTCHA_GET_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: CAPTCHA_GET_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getUserToken2 =
  (loginname, password, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN2_REQUEST })

      const { data } = await axios({
        method: 'post',
        data: { loginname, password },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/token2`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      dispatch({ type: USER_TOKEN2_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN2_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getILinkUserToken2 =
  (loginname, password, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN2_REQUEST })

      const { data } = await axios({
        method: 'post',
        data: { loginname, password },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/ilink/token2`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      dispatch({ type: USER_TOKEN2_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN2_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getUserTokenFromToken2 =
  (token2, confirm, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN_REQUEST })

      const { data } = await axios({
        method: 'put',
        data: { confirm },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/${token2}/token2`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
      dispatch({ type: USER_TOKEN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const getILinkUserTokenFromToken2 =
  (token2, confirm, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_TOKEN_REQUEST })

      const { data } = await axios({
        method: 'put',
        data: { confirm },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/ilink/${token2}/token2`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      localStorage.setItem('userInfo', JSON.stringify(data))
      dispatch({ type: USER_TOKEN_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_TOKEN_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const switchUser = (_id) => async (dispatch, getState) => {
  try {
    dispatch({ type: USER_SWITCH_REQUEST })
    const {
      userLogin: { userInfo },
    } = getState()
    console.log(
      'switchuser',
      `${getAPIGATEWAY(
        process.env.REACT_APP_DBNAME || userInfo.dbname
      )}/api/v1/user/switch`
    )
    const { data } = await axios({
      method: 'post',
      data: { _id },
      url: `${getAPIGATEWAY(
        process.env.REACT_APP_DBNAME || userInfo.dbname
      )}/api/v1/user/switch`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `PPP ${userInfo.token}`,
      },
    })

    localStorage.setItem('userInfo', JSON.stringify(data))
    dispatch({ type: USER_SWITCH_SUCCESS, payload: data })
  } catch (error) {
    dispatch({
      type: USER_SWITCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

const getClientIp = async (dbname, getState) => {
  const {
    userLogin: { userInfo },
  } = getState()
  const { data } = await axios({
    method: 'get',
    url: `${getAPIGATEWAY(dbname || userInfo.dbname)}/api/v1/user/clientip`,

    headers: {
      'Content-Type': 'application/json',
    },
  })
  return data
}

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo')
  dispatch({ type: USER_TOKEN_RESET })
  dispatch({ type: USER_LOGOUT })
}

export const getPasswordResetConfirmation =
  (email, captcha, confirm, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_RESETREQUEST_REQUEST })
      const { data } = await axios({
        method: 'put',
        data: { captcha, confirm },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/${email}/requestpassword`,
      })

      dispatch({ type: USER_RESETREQUEST_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_RESETREQUEST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }

export const updateUserPassword =
  (token2, confirm, pass, rePass, dbname) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_UPDATEPASS_REQUEST })

      const { data } = await axios({
        method: 'put',
        data: { confirm: confirm, password: pass, password2: rePass },
        url: `${getAPIGATEWAY(
          dbname || process.env.REACT_APP_DBNAME //|| 'afds2'
        )}/api/v1/user/${token2}/password`,

        headers: {
          'Content-Type': 'application/json',
        },
      })

      dispatch({ type: USER_UPDATEPASS_SUCCESS, payload: data })
    } catch (error) {
      dispatch({
        type: USER_UPDATEPASS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
