import React, { useEffect, useState } from 'react'
import { Collapse, Row, Col, Table, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getUserList } from '../../actions/accountAction'
import { logout, switchUser } from '../../actions/userActions'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import { rights } from '../../utils/apiGateway'
import '../form.css'
import { USER_LIST_RESET } from '../../constants/accountConstants'

const SwitchUserScreen = ({ history }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(true)
  const [errCount, setErrCount] = useState(1)
  const [users, setUsers] = useState()
  const [loginId, setLoginId] = useState(0)
  const [t, setT] = useState(true)
  const userList = useSelector((state) => state.userList)
  const { loading, error, success, users: u } = userList
  const userSwitch = useSelector((state) => state.userSwitch)
  const { loading: loadingSwitch, error: errorSwitch, userInfo } = userSwitch
  if (!rights('03E52718-8918-4828-A5DB-D018779BF148')) {
    dispatch(logout())
  }
  useEffect(() => {
    if (userInfo && !t) {
      if (userInfo.ilink) {
        if (userInfo.dbname === 'afds2') {
          history.push('/?token=' + userInfo.token + '&ilink=1')
        } else {
          history.push(
            '/?token=' + userInfo.token + '&ilink=1&dbname=' + userInfo.dbname
          )
        }
      } else {
        history.push('/?token=' + userInfo.token)
      }
      setT(true)
    }
    if (success) {
      setUsers(u)
      dispatch({ type: USER_LIST_RESET })
    }
    if (!users && !loading && !success) {
      dispatch(getUserList())
    }
    if (error) {
      setErrCount(errCount + 1)
      if (errCount > 30) {
        history.push('/error/1009')
      }
    }
  }, [
    userList,
    loading,
    dispatch,
    error,
    errCount,
    users,
    history,
    t,
    userInfo,
    u,
    success,
  ])

  const selectUser = (e) => {
    setLoginId(e.target.value)
  }
  const updateUser = (e) => {
    e.preventDefault()

    dispatch(switchUser(loginId))
    setT(false)
  }

  return (
    <>
      <h2>Switch User</h2>
      {loadingSwitch && <Loader></Loader>}
      {errorSwitch ? (
        <Message variant='danger' dismissible={true}>
          {errorSwitch}
        </Message>
      ) : (
        users && (
          <Row>
            <Col sm={2} md={4} lg={6} style={{ textAlign: 'left' }}>
              <Button
                onClick={() => setOpen(!open)}
                aria-controls='example-collapse-text'
                aria-expanded={open}
                style={{ padding: '0.4rem 0.8rem' }}
              >
                <i className='fas fa-search'></i>
              </Button>
              <Collapse in={open}>
                <div id='example-collapse-text'>
                  <form onSubmit={(e) => updateUser(e)}>
                    <Table className='table-sm'>
                      <tbody>
                        <tr>
                          <td>Select a User:</td>
                          <td colSpan={8}>
                            <select
                              className='form-control form-control-sm'
                              id='loginid'
                              onChange={(e) => selectUser(e)}
                            >
                              {users &&
                                users.map((u, i) => (
                                  <option key={i} value={u._id}>
                                    {u.LoginName}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td colSpan={2}>
                            <Button variant='secondary' size='sm' type='submit'>
                              Switch
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </form>
                </div>
              </Collapse>
            </Col>
          </Row>
        )
      )}
    </>
  )
}

export default SwitchUserScreen
