export const EBOOK_LIST_CREATE_SUCCESS = 'EBOOK_LIST_CREATE_SUCCESS'
export const EBOOK_LIST_CREATE_REQUEST = 'EBOOK_LIST_CREATE_REQUEST'
export const EBOOK_LIST_CREATE_FAIL = 'EBOOK_LIST_CREATE_FAIL'
export const EBOOK_LIST_CREATE_RESET = 'EBOOK_LIST_CREATE_RESET'
export const EBOOK_SSO_CREATE_SUCCESS = 'EBOOK_SSO_CREATE_SUCCESS'
export const EBOOK_SSO_CREATE_REQUEST = 'EBOOK_SSO_CREATE_REQUEST'
export const EBOOK_SSO_CREATE_FAIL = 'EBOOK_SSO_CREATE_FAIL'
export const EBOOK_SSO_CREATE_RESET = 'EBOOK_SSO_CREATE_RESET'

export const SW_LOGIN_SUCCESS = 'SW_LOGIN_SUCCESS'
export const SW_LOGIN_REQUEST = 'SW_LOGIN_REQUEST'
export const SW_LOGIN_FAIL = 'SW_LOGIN_FAIL'
export const SW_LOGIN_RESET = 'SW_LOGIN_RESET'

export const PPPTABLE_EDITMODE_RESET = 'PPPTABLE_EDITMODE_RESET'
export const PPPTABLE_EDITMODE_SET = 'PPPTABLE_EDITMODE_SET'
