import React from 'react'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import Loader from '../components/Loader'

const ErrorButton = ({ loading, error, type }) => {
  const dispatch = useDispatch()
  const click = (e) => {
    if (Array.isArray(type)) {
      for (var i = 0; i < type.length; i++) {
        dispatch({ type: type[i] })
      }
    } else {
      dispatch({
        type,
      })
    }
  }
  return (
    <>
      {loading && <Loader size='30px'></Loader>}
      {error && (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-release`} title={error} arrow>
              <strong>Error</strong>
            </Tooltip>
          }
        >
          <Button
            size='sm'
            variant='danger'
            onClick={(e) => click(e)}
            style={{ padding: '0.4rem 0.8rem' }}
          >
            <i className='fas fa-exclamation-circle'></i>
          </Button>
        </OverlayTrigger>
      )}
    </>
  )
}

export default ErrorButton
