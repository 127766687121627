import {
  EBOOK_LIST_CREATE_SUCCESS,
  EBOOK_LIST_CREATE_REQUEST,
  EBOOK_LIST_CREATE_FAIL,
  EBOOK_LIST_CREATE_RESET,
  EBOOK_SSO_CREATE_SUCCESS,
  EBOOK_SSO_CREATE_REQUEST,
  EBOOK_SSO_CREATE_FAIL,
  EBOOK_SSO_CREATE_RESET,
  PPPTABLE_EDITMODE_RESET,
  PPPTABLE_EDITMODE_SET,
  SW_LOGIN_SUCCESS,
  SW_LOGIN_REQUEST,
  SW_LOGIN_FAIL,
  SW_LOGIN_RESET,
} from '../constants/solidworksConstants'

export const getSWLoginReducer =  (state = {}, action) => {
  switch (action.type) {
    case SW_LOGIN_REQUEST:
      return { loading: true }
    case SW_LOGIN_SUCCESS:
      return { loading: false, token2: action.payload }
    case SW_LOGIN_RESET:
      return {}
    case SW_LOGIN_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getWSEBookUserReducer = (state = {}, action) => {
  switch (action.type) {
    case EBOOK_LIST_CREATE_REQUEST:
      return { loading: true }
    case EBOOK_LIST_CREATE_SUCCESS:
      return {
        loading: false,
        ebooks: action.payload,
        success: true,
        //lock: action.payload.ShipTo ? true : false,
      }
    // case CART_GET_LOCK:
    //   return { lock: true }
    case EBOOK_LIST_CREATE_RESET:
      return {}
    case EBOOK_LIST_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getEBookSSOReducer = (state = {}, action) => {
  switch (action.type) {
    case EBOOK_SSO_CREATE_REQUEST:
      return { loading: true }
    case EBOOK_SSO_CREATE_SUCCESS:
      return {
        loading: false,
        url: action.payload,
      }
    case EBOOK_SSO_CREATE_RESET:
      return { loading: false, url: null}
    case EBOOK_SSO_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const pppTableStateReducer = (state = { onEditMode: true }, action) => {
  switch (action.type) {
    case PPPTABLE_EDITMODE_RESET:
      return { onEditMode: false }
    case PPPTABLE_EDITMODE_SET:
      return { onEditMode: true }
    default:
      return state
  }
}
