import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './form.css'
import { Button, Form, Row, Col } from 'react-bootstrap'
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from 'react-device-detect'
import { getCaptcha, submitHelpdesk } from '../actions/userActions'
import { CAPTCHA_GET_RESET } from '../constants/userConstants'
import ErrorMessage from '../components/ErrorMessage'
import SuccessMessage from '../components/SuccessMessage'

const HelpScreen = () => {
  const dispatch = useDispatch()
  const [captchaResponse, setCaptchaResponse] = useState('')

  const captchaGet = useSelector((state) => state.captchaGet)
  const [captcha, setCaptcha] = useState()
  const { captcha: remoteCaptcha, loading: captchaLoading, error: captchaError, success } = captchaGet

  const helpdeskSubmit = useSelector((state) => state.helpdeskSubmit)
  const {
    loading: loadingHelpdesk,
    success: successHelpdesk,
    error: errorHelpdesk,
    ticketNumber,
  } = helpdeskSubmit
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin || {}
  const email = userInfo?.email || ''
  const fullname = userInfo?.fullname || ''
  const isUserLoggedIn = !!userInfo

  useEffect(() => {
    if (success) {
      setCaptcha(remoteCaptcha)
      dispatch({ type: CAPTCHA_GET_RESET })
    }
    if (!captcha && !captchaLoading) {
      dispatch(getCaptcha())
    }
  }, [dispatch, captcha, remoteCaptcha, userInfo, success, captchaLoading])

  const submitHandler = async (e) => {
    e.preventDefault()

    const name = e.target.name.value
    const email = e.target.email.value
    const browser = e.target.browser.value
    const subject = e.target.subject.value
    const message = e.target.message.value
    const captchaResponse = e.target.captchaResponse.value

    const captchaKey = remoteCaptcha.key

    const request = {
      name,
      email,
      browser,
      subject,
      message,
      captchaResponse,
      captchaKey,
    }

    dispatch(submitHelpdesk(request))
    e.target.reset()
    setCaptchaResponse('')
  }

  return (
    <>
      <h2>Helpdesk Form</h2>
      <Form onSubmit={(e) => submitHandler(e)}>
        {errorHelpdesk && (
          <ErrorMessage
            message={`Error submitting Helpdesk: ${errorHelpdesk}`}
          />
        )}
        {successHelpdesk && (
          <SuccessMessage
            message={`Helpdesk submitted successfully! Ticket Number: ${ticketNumber}`}
          />
        )}
        <Row>
          <Col md={6}>
            <Form.Label>Name:</Form.Label>

            <Form.Control
              size='sm'
              name='name'
              type='text'
              required={true}
              maxLength={50}
              disabled={isUserLoggedIn}
              defaultValue={fullname}
            ></Form.Control>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>Email:</Form.Label>

            <Form.Control
              size='sm'
              name='email'
              type='email'
              required={true}
              maxLength={100}
              pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
              disabled={isUserLoggedIn}
              defaultValue={email}
            ></Form.Control>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>Browser:</Form.Label>
            <Form.Control
              size='sm'
              name='browser'
              type='text'
              required={true}
              maxLength={250}
              disabled={true}
              defaultValue={`${osName} ${osVersion} - ${browserName} ${browserVersion}`}
            ></Form.Control>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>Subject:</Form.Label>
            <Form.Control
              size='sm'
              name='subject'
              type='text'
              required={true}
              maxLength={250}
            ></Form.Control>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>Message:</Form.Label>
            <Form.Control
              name='message'
              as='textarea'
              rows={5}
              required={true}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label>Captcha:</Form.Label>
            <div style={{ width: '70%', display: 'inline', float: 'right' }}>
              {captchaLoading ? (
                <span>Loading captcha...</span>
              ) : (
                <>
                  {captchaError && (
                    <p>Error loading captcha. Please try again.</p>
                  )}
                  {captcha && (
                    <>
                      <img src={captcha.image} alt='Captcha' />{' '}
                      <Button
                        variant='success'
                        size='sm'
                        onClick={(e) => setCaptcha()}
                        style={{ padding: '0.4rem 0.8rem' }}
                      >
                        <i className='fa fa-sync-alt fa-2x'></i>
                      </Button>
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Label></Form.Label>
            <Form.Control
              size='sm'
              name='captchaResponse'
              type='text'
              required={true}
              placeholder='enter the code shown above'
              maxLength={50}
              value={captchaResponse}
              onChange={(e) => setCaptchaResponse(e.target.value)}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Button type='submit' size='sm'>
              {loadingHelpdesk ? (
                <>
                  <span className='ml-2'>Submitting...</span>
                </>
              ) : (
                'Submit'
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
export default HelpScreen
