import React, { useState } from 'react'
import { Button } from 'react-bootstrap'

const DeleteButton = ({ size, onclick, type, children, style }) => {
  const [confirm, setConfirm] = useState(false)

  return (
    <>
      {confirm ? (
        <>
          <Button
            variant='success'
            type={type}
            size={size}
            onClick={(e) => {
              onclick(e)
              setConfirm(false)
            }}
            style={style}
          >
            Yes
          </Button>
          <Button
            variant='danger'
            size={size}
            onClick={(e) => setConfirm(false)}
            style={style}
          >
            No
          </Button>
        </>
      ) : (
        <Button
          variant='danger'
          size={size}
          onClick={(e) => setConfirm(true)}
          style={style}
        >
          {children || <i className='fas fa-minus'></i>}
        </Button>
      )}
    </>
  )
}

export default DeleteButton
