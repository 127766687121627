import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import './form.css'
import { Button, Col, Container, Row } from 'react-bootstrap'

const UserProfileScreen = ({ match, history, location }) => {
  const params = queryString.parse(location.search)
  const token = params.token || match.params.token
  const dbname = params.dbname
  const [errCount, setErrCount] = useState(1)
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    if (error) {
      setErrCount(errCount + 1)
      if (errCount > 2) {
        if (!token) {
          history.push('/login')
        } else {
          history.push('/error/1009')
        }
      }
    }
  }, [dispatch, history, token, userInfo, error, loading, errCount, dbname])

  const changePasswordHandler = () => {
    history.push('/user/changepassword')
  }

  return (
    <>
      <h1>User Profile</h1>
      <hr size='1' />
      <Container>
        <Row className='mb-2'>
          <Col md={3}>
            <h4>Login Name: {userInfo.loginname}</h4>
          </Col>
        </Row>
        <hr size='1' />
        <Row className='mb-2'>
          <Col>
            <h4>Full Name: {userInfo.fullname}</h4>
          </Col>
        </Row>
        <hr size='1' />
        <Row className='mb-2'>
          <Col>
            <h4>Email: {userInfo.email}</h4>
          </Col>
        </Row>
        <hr size='1' />
        <Row className='mb-2'>
          <Col>
            <Button
              variant='outline-primary'
              size='sm'
              onClick={(e) => {
                changePasswordHandler()
              }}
            >
              Change Password
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default UserProfileScreen
