import React, { useEffect } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logout } from '../actions/userActions'

const Header = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  //const cartGet = useSelector((state) => state.cartGet)
  //const { cart, success: successGet } = cartGet
  //const [cartTotalQty, setCartTotalQty] = useState(0)
  //const updateCartProduct = useSelector((state) => state.updateCartProduct)
  //const { success: successUpdate } = updateCartProduct
  useEffect(() => {
    /*
    if (cart && cart.items && (successUpdate || successGet)) {
      setCartTotalQty(cart.totalQty)
    }
    if (!userInfo) {
      setCartTotalQty(0)
    }
    */
  }, [dispatch, history, userInfo])
  const logoutHandler = (e) => {
    dispatch(logout())
    history.push('/user/login')
  }

  return (
    <header>
      <Navbar
        bg={
          userInfo && userInfo.dbname ? userInfo.dbname.toLowerCase() : 'afds2'
        }
        variant={userInfo && userInfo.dbname === 'AFDS5' ? 'light' : 'dark'}
        expand='lg'
      >
        <Navbar.Brand href={process.env.REACT_APP_BASENAME}>
          SOLIDWORKS Online eBook Viewer
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='ml-auto d-flex align-items-center'>
            <div className='mr-3'>
              <LinkContainer to={process.env.REACT_APP_BASENAME}>
                <Nav.Link>
                  <i className='fa fa-home'></i> Home
                </Nav.Link>
              </LinkContainer>
            </div>
            <div className='mr-3'>
              <LinkContainer to='/user/help'>
                <Nav.Link>
                  <i className='fa fa-question-circle'></i> Help
                </Nav.Link>
              </LinkContainer>
            </div>
            <div>
              {userInfo ? (
                <NavDropdown
                  title={
                    userInfo.fullname === '' || !userInfo.fullname
                      ? userInfo.loginname
                      : userInfo.fullname
                  }
                  id='username'
                  //style={{ width: '650px' }}
                >
                  <LinkContainer to='/user/profile' hidden>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/user/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i>Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
