export const getAPIGATEWAY = (dbname) => {
  return process.env.REACT_APP_API_GATEWAY
}

export const rights = (_id) => {
  const userInfo = localStorage.getItem('userInfo')
    ? JSON.parse(localStorage.getItem('userInfo'))
    : null

  return userInfo && userInfo.security
    ? userInfo.security.filter((i) => i._id === _id).length === 0
      ? false
      : true
    : false
}
