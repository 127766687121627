import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import queryString from 'query-string'
import './form.css'
import { Form, Container, Row, Col, Button } from 'react-bootstrap'
import {
  getPasswordResetConfirmation,
  updateUserPassword,
} from '../actions/userActions'
import Loader from '../components/Loader'
import Message from '../components/Message'
import {
  USER_RESETREQUEST_RESET,
  USER_UPDATEPASS_RESET,
} from '../constants/userConstants'

const ChangePasswordScreen = ({ history, location }) => {
  const dispatch = useDispatch()
  const params = queryString.parse(location.search)
  const dbname = params.dbname || 'afds2'
  const confirm = useRef()
  const pass = useRef()
  const rePass = useRef()
  const [enterConfirm, setShowConfirm] = useState(false)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const userResetRequest = useSelector((state) => state.userResetRequest)
  const {
    loading: loadingRequest,
    error: errorRequest,
    success: successRequest,
    requestInfo,
  } = userResetRequest
  const userUpdatePassword = useSelector((state) => state.userUpdatePassword)
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updateInfo,
  } = userUpdatePassword

  useEffect(() => {
    return () => {
      dispatch({ type: USER_RESETREQUEST_RESET })
      dispatch({ type: USER_UPDATEPASS_RESET })
    }
  }, [dispatch])
  useEffect(() => {
    if (successRequest) {
      setShowConfirm(true)
    }
    if (successUpdate) {
      setShowConfirm(false)
    }
    if (errorUpdate) {
    }
  }, [
    dispatch,
    history,
    loadingRequest,
    errorRequest,
    successRequest,
    requestInfo,
    loadingUpdate,
    errorUpdate,
    successUpdate,
    updateInfo,
    userInfo,
  ])

  const emailHandler = (e) => {
    e.preventDefault()
    dispatch(getPasswordResetConfirmation(userInfo.email, dbname))
  }

  const confirmHandler = (e) => {
    e.preventDefault()
    dispatch(
      updateUserPassword(
        requestInfo.token2,
        confirm.current.value,
        pass.current.value,
        rePass.current.value,
        dbname
      )
    )
  }

  const doneHandler = (e) => {
    dispatch({ type: USER_RESETREQUEST_RESET })
    dispatch({ type: USER_UPDATEPASS_RESET })
    history.push('/user/profile')
  }

  const backHandler = () => {
    /* dispatch({ type: USER_RESETREQUEST_RESET })
    dispatch({ type: USER_UPDATEPASS_RESET }) */
    history.push('/user/profile')
  }
  return (
    <Container>
      <Row className='mb-2'>
        <Col md={12}>
          <Button type='button' onClick={backHandler}>
            Back
          </Button>
        </Col>
      </Row>
      <h3>Change Password</h3>

      <hr size='1' />

      {errorRequest && (
        <Message
          variant='danger'
          dismissible={true}
          type='USER_RESETREQUEST_RESET'
        >
          {
            'Something went wrong. Please make sure the email entered is valid and try again.'
          }
        </Message>
      )}
      {loadingRequest ? (
        <Loader></Loader>
      ) : (
        !requestInfo && (
          <>
            <Row className='mb-2'>
              <Col md={12} className='text-center'>
                {/* Simplified message and centered button */}
                <p>
                  If you wish to change your password, click the button below to
                  receive a confirmation code to your email associated with your
                  account.
                </p>
              </Col>
            </Row>
            <Row className='mb-2'>
              <Col md={12} className='text-center'>
                <Button type='submit' onClick={emailHandler}>
                  Send Code
                </Button>
              </Col>
            </Row>
          </>
        )
      )}
      {errorUpdate && (
        <Message
          variant='danger'
          dismissible={true}
          type='USER_UPDATEPASS_RESET'
        >
          {errorUpdate || 'Something went wrong. Please try again.'}
        </Message>
      )}
      {loadingUpdate ? (
        <Loader></Loader>
      ) : (
        enterConfirm && (
          <>
            <Row className='mb-2'>
              <Col md={5}>
                Please enter the confirmation number that was sent to the email
                associated with your account along with a new password for your
                account.
              </Col>
            </Row>
            <Form onSubmit={(e) => confirmHandler(e)}>
              <Row className='mb-2'>
                <Col md={2}>Confirmation #:</Col>
                <Col md={3}>
                  <Form.Control
                    size='sm'
                    ref={confirm}
                    type='text'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={2}>New Password:</Col>
                <Col md={3}>
                  <Form.Control
                    size='sm'
                    ref={pass}
                    type='password'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={2}>Retype Password:</Col>
                <Col md={3}>
                  <Form.Control
                    size='sm'
                    ref={rePass}
                    type='password'
                    required={true}
                    minLength={5}
                    style={{ width: '100%' }}
                  ></Form.Control>
                </Col>
              </Row>
              <Row className='mb-2'>
                <Col md={1}>
                  <Button type='submit'>Submit</Button>
                </Col>
              </Row>
            </Form>
          </>
        )
      )}
      {updateInfo && (
        <>
          <Row className='mb-2'>
            <Col md={7}>
              <h4>
                Your password has successfully been changed! Please return to
                the login screen by clicking the button below.
              </h4>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col md={1}>
              <Button type='submit' onClick={doneHandler}>
                Done
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default ChangePasswordScreen
