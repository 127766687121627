import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { FORM_GET_RESET, REPORT_GET_RESET } from '../constants/formConstants'
import { useParams } from 'react-router-dom'
import { ACCOUNT_LIST_RESET } from '../constants/accountConstants'
import { USER_TOKEN_RESET } from '../constants/userConstants'
import { Button } from 'react-bootstrap'

const ErrorScreen = ({ history }) => {
  const { errno } = useParams()

  const errNo = Number(errno)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: REPORT_GET_RESET })
    dispatch({ type: FORM_GET_RESET })
    dispatch({ type: ACCOUNT_LIST_RESET })
    dispatch({ type: USER_TOKEN_RESET })
  }, [dispatch])

  const goHome = (e) => {
    localStorage.clear()
    history.push('/')
  }
  return (
    <>
      <h1>Unkown Error</h1>
      {errNo === 1009 && (
        <>
          <h2>probably invalid token or no connection to sql server</h2>
          <h4>
            <Button onClick={(e) => goHome(e)}>Home</Button>
          </h4>
        </>
      )}
    </>
  )
}

export default ErrorScreen
