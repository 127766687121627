import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { Container } from 'react-bootstrap'
import ErrorScreen from './screens/ErrorScreen'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import LoginScreen from './screens/LoginScreen'
import ConfirmScreen from './screens/ConfirmScreen'
import ChangePasswordScreen from './screens/ChangePasswordScreen'
import UserScreen from './screens/administration/UserScreen'
import MenuScreen from './screens/administration/MenuScreen'
import SwitchUserScreen from './screens/administration/SwitchUserScreen'
import ForgotPasswordScreen from './screens/ForgotPasswordScreen'
import UserProfileScreen from './screens/UserProfileScreen'
import HelpScreen from './screens/HelpScreen'
import SolidWorksEBookScreen from './screens/report/SolidworksEBookScreen'
import DisableNumInputScroll from './components/DisableNumInputScroll'

const App = () => {
  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Header />
      <main>
        <DisableNumInputScroll />
        <Container>
          <Route path='/' component={SolidWorksEBookScreen} exact></Route>
          <Route path='/user/login' component={LoginScreen} exact></Route>
          <Route path='/user/confirm' component={ConfirmScreen} exact></Route>
          <Route
            path='/user/forgotpassword'
            component={ForgotPasswordScreen}
            exact
          ></Route>
          <Route
            path='/user/changepassword'
            component={ChangePasswordScreen}
            exact
          ></Route>
          <Route path='/error/:errno' component={ErrorScreen} exact></Route>
          <Route path='/:token' component={SolidWorksEBookScreen} exact></Route>
          <Route
            path='/user/profile'
            component={UserProfileScreen}
            exact
          ></Route>
          <Route path='/user/help' component={HelpScreen} exact></Route>
          <Route
            path='/administration/user'
            component={UserScreen}
            exact
          ></Route>
          <Route
            path='/administration/menu'
            component={MenuScreen}
            exact
          ></Route>
          <Route
            path='/administration/switchuser'
            component={SwitchUserScreen}
            exact
          ></Route>
          <Route
            path='/report/sw/ebook'
            component={SolidWorksEBookScreen}
            exact
          ></Route>
        </Container>
      </main>
      <Footer />
    </Router>
  )
}

export default App
