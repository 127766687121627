export const USER_TOKEN_REQUEST = 'USER_TOKEN_REQUEST'
export const USER_TOKEN_SUCCESS = 'USER_TOKEN_SUCCESS'
export const USER_TOKEN_RESET = 'USER_TOKEN_RESET'
export const USER_TOKEN_FAIL = 'USER_TOKEN_FAIL'

// for user login from the screen
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'
// get token2 for 2-factor login
export const USER_TOKEN2_REQUEST = 'USER_TOKEN2_REQUEST'
export const USER_TOKEN2_SUCCESS = 'USER_TOKEN2_SUCCESS'
export const USER_TOKEN2_RESET = 'USER_TOKEN2_RESET'
export const USER_TOKEN2_FAIL = 'USER_TOKEN2_FAIL'

export const USER_SWITCH_REQUEST = 'USER_SWITCH_REQUEST'
export const USER_SWITCH_SUCCESS = 'USER_SWITCH_SUCCESS'
export const USER_SWITCH_RESET = 'USER_SWITCH_RESET'
export const USER_SWITCH_FAIL = 'USER_SWITCH_FAIL'

export const USER_RESETREQUEST_REQUEST = 'USER_RESETREQUEST_REQUEST'
export const USER_RESETREQUEST_SUCCESS = 'USER_RESETREQUEST_SUCCESS'
export const USER_RESETREQUEST_RESET = 'USER_RESETREQUEST_RESET'
export const USER_RESETREQUEST_FAIL = 'USER_RESETREQUEST_FAIL'

export const USER_UPDATEPASS_REQUEST = 'USER_UPDATEPASS_REQUEST'
export const USER_UPDATEPASS_SUCCESS = 'USER_UPDATEPASS_SUCCESS'
export const USER_UPDATEPASS_RESET = 'USER_UPDATEPASS_RESET'
export const USER_UPDATEPASS_FAIL = 'USER_UPDATEPASS_FAIL'

export const CAPTCHA_GET_REQUEST = 'CAPTCHA_GET_REQUEST'
export const CAPTCHA_GET_SUCCESS = 'CAPTCHA_GET_SUCCESS'
export const CAPTCHA_GET_FAIL = 'CAPTCHA_GET_FAIL'
export const CAPTCHA_GET_RESET = 'CAPTCHA_GET_RESET'

export const HELPDESK_SUBMIT_REQUEST = 'HELPDESK_SUBMIT_REQUEST'
export const HELPDESK_SUBMIT_SUCCESS = 'HELPDESK_SUBMIT_SUCCESS'
export const HELPDESK_SUBMIT_FAIL = 'HELPDESK_SUBMIT_FAIL'
export const HELPDESK_SUBMIT_RESET = 'HELPDESK_SUBMIT_RESET'
